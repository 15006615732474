import CloseIcon from "@mui/icons-material/Close";
import { ButtonBase, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

interface DeleteNodeButtonProps {
  /** 選択中のノードID */
  selectedNode: string | null;
  /** ノードを削除する関数 */
  onDelete: () => void;
}

/**
 * ノードを削除するボタンコンポーネント
 */
export const DeleteNodeButton: React.FC<DeleteNodeButtonProps> = ({ selectedNode, onDelete }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Tooltip arrow title={t("mindmap.deleteNodeTooltip")}>
      <span>
        <ButtonBase
          disabled={!selectedNode}
          color="primary"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            onDelete();
          }}
          sx={{
            zIndex: 1000,
            py: 1,
            px: 2,
            transition: "color 0.2s ease-in-out",
            "&:hover": { color: theme.palette.error.main },
            "&.Mui-disabled": {
              opacity: 0.5,
              color: theme.palette.text.disabled,
            },
          }}
        >
          <CloseIcon sx={{ mr: 1 }} fontSize="small" />
          <Typography variant="button">{t("mindmap.deleteNode")}</Typography>
        </ButtonBase>
      </span>
    </Tooltip>
  );
};

DeleteNodeButton.propTypes = {
  selectedNode: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
};
