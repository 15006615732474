const junoVideo = {
  title: "VIDEO GENERATOR",
  menu: {
    t2v: "Text to Video",
    i2v: "Image to Video",
    v2v: "Video to Video",
    library: "Library",
  },
  ai: {
    select: "Please select an AI model",
    model: "Model",
    runway: {
      title: "RunwayML",
      description:
        "[Fast] Simplifies the video production process using AI and machine learning, catering to both professionals and beginners.",
      model: {
        GEN3_ALPHA_TURBO: "Gen3 Alpha Turbo",
      },
    },
    kling: {
      title: "KLING AI",
      description:
        "[Slow] KLING AI generates realistic and natural-motion videos using advanced physics simulation and 3D technology.",
      model: {
        V1_0_STANDARD: "Standard v1.0",
        V1_6_STANDARD: "Standard v1.6",
        V1_0_PRO: "Pro v1.0",
        V1_5_PRO: "Pro v1.5",
        V1_6_PRO: "Pro v1.6",
      },
    },
    luma: {
      title: "Luma",
      description:
        "[Fast] Luma Dream Machine is an AI model that generates high-quality and realistic videos from text or images.",
      model: {
        V1_5: "Dream MACHINE v1.5",
        RAY_2: "Dream MACHINE RAY 2",
      },
    },
    hailuo: {
      title: "Hailuo AI",
      description:
        "[Slow] Hailuo AI/Minimax excels in generating high-quality videos with accurate human motion reproduction.",
      model: {
        MINI_MAX: "MINI MAX VIDEO-01",
        MINI_MAX_LIVE: "MINI MAX VIDEO-01-LIVE",
        MINI_MAX_REF: "MINI MAX VIDEO-01-SUBJECT-REFFRENCE",
      },
    },
    haiper: {
      title: "Haiper AI",
      description: "[Slow] Haiper AI excels in naturally reproducing Japanese faces and poses.",
      model: {
        V2_0: "Haiper V2.0",
        V2_5: "Haiper V2.5",
      },
    },
    pixverse: {
      title: "PixVerse",
      description:
        "[Fast] PixVerse is a high-quality video generation AI that creates natural motion videos from text or images.",
      model: {
        V3_5: "V3.5",
        V3_5_FAST: "V3.5 Fast",
      },
      style: {
        default: "Default",
        anime: "Anime",
        "3d_animation": "3D Animation",
        clay: "Clay Art",
        comic: "Comic",
        cyberpunk: "Cyberpunk",
      },
    },
  },
  params: {
    title: "Video Settings",
    upload: "Upload Image",
    uploadFace: "Upload Image (Face)",
    duration: "Video Duration",
    aspect: "Aspect Ratio",
    loop: "Loop",
    style: "Style",
    resolution: "Resolution",
    negativePrompt: "Negative Prompt",
    input: {
      label: "Prompt",
      placeholder: "Enter video prompt (English recommended)",
      warning:
        "* Cannot generate videos containing sexual or violent content, harassment, discrimination, inappropriate expression, or other illegal content.",
    },
    submit: "Generate Video",
  },
  myVideo: "My Videos",
  processing: "Generating video...\nUsually completes within 5 minutes.",
  sampleVideo: "Sample Videos",
  error: {
    generate: "Failed to generate the video.\nPlease check for policy violations and try again later.",
    required: {
      image: "Please upload an image.",
      prompt: "Please enter a prompt.",
    },
    max: {
      prompt: "Please enter a prompt within 512 characters.",
    },
  },
  startGenerating: "Your video generation request has been added to the queue.",
  videoMenu: {
    download: "Download",
    delete: "Delete",
  },
  message: {
    deleted: "Video has been deleted.",
  },
};
export default junoVideo;
