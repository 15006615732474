const videoScript = {
  library: "Library",
  title: "Short Video Script",
  description: "Generates scripts optimized for video content on platforms such as YouTube, TikTok, Instagram, etc.",
  target: "Target Audience",
  targetPlaceholder: "e.g. Video creators in their 20s and 30s",
  desiredVideo: "Desired Video Overview",
  desiredVideoPlaceholder: "e.g. Top 5 amazing features of the new iPhone camera",
  sns: {
    title: "Target SNS",
    instagram: "Instagram",
    tiktok: "TikTok",
    youtube: "YouTube",
    facebook: "Facebook",
    x: "X (Twitter)",
    linkedin: "LinkedIn",
    pinterest: "Pinterest",
    threads: "Threads",
    other: "Other",
  },
  length: "Video Length",
  short: "30 seconds",
  long: "60 seconds",
  source: "Source",
  sourceDescription: "Please select a source.",
  tone: "Tone of the Text",
  additional: "Additional Information",
  additionalPlaceholder: "Enter any guidelines or notes to follow.",
  srcType: {
    url: "URL",
    urlPlaceholder: "https://www.example.com",
    urlDescription: "Enter the URL of a website for reference.",
    youtube: "YouTube",
    youtubePlaceholder: "https://www.youtube.com/watch?v=xxxxxxxxxxx ( We also support YouTube Shorts. )",
    youtubeDescription: "Enter the URL of a YouTube video for reference.",
    text: "Text",
    textPlaceholder: "Text (up to 30,000 characters)",
    textDescription: "Enter text for reference.",
    keyword: "Keyword",
    keywordDescription: "Tenak AI will generate text based on keywords.",
    keywordPlaceholder: "e.g. iPhone camera performance",
  },
  submit: "Generate Short Video Script",
  error: {
    createHeading: "Please generate a heading after entering keywords.",
    invalidYoutubeUrl: "Please enter a valid YouTube URL.",
    invalidUrl: "Please enter a valid URL.",
    invalidText: "Please enter valid text (up to 30,000 characters).",
    invalidContent: "Please select or enter a source.",
    invalidKeywords: "Please enter valid keywords.",
    invalidSns: "Please select a target SNS.",
  },
};
export default videoScript;
