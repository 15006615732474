import React, { createContext, useContext, useState } from "react";

// Contextの型定義
type ArticleContextType = {
  keywords: string;
  setKeywords: React.Dispatch<React.SetStateAction<string>>;
  content: string;
  setContent: React.Dispatch<React.SetStateAction<string>>;
  tone: string;
  setTone: React.Dispatch<React.SetStateAction<string>>;
  headings: string;
  setHeadings: React.Dispatch<React.SetStateAction<string>>;
  srcType: string | null;
  setSrcType: React.Dispatch<React.SetStateAction<string | null>>;
  additionalText: string;
  setAdditionalText: React.Dispatch<React.SetStateAction<string>>;
  target: string;
  setTarget: React.Dispatch<React.SetStateAction<string>>;
  manualHeadings: boolean;
  setManualHeadings: React.Dispatch<React.SetStateAction<boolean>>;
};

// Contextの作成
const ArticleContext = createContext<ArticleContextType | undefined>(undefined);

// プロバイダコンポーネント
export const ArticleProvider: React.FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
  const [keywords, setKeywords] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [tone, setTone] = useState("");
  const [headings, setHeadings] = useState<string>("");
  const [additionalText, setAdditionalText] = useState<string>("");
  const [srcType, setSrcType] = useState<string | null>("text");
  const [target, setTarget] = useState<string>("");
  const [manualHeadings, setManualHeadings] = useState<boolean>(false);

  return (
    <ArticleContext.Provider
      value={{
        keywords,
        setKeywords,
        content,
        setContent,
        tone,
        setTone,
        headings,
        setHeadings,
        additionalText,
        setAdditionalText,
        srcType,
        setSrcType,
        target,
        setTarget,
        manualHeadings,
        setManualHeadings,
      }}
    >
      {children}
    </ArticleContext.Provider>
  );
};

// コンテキストを使用するためのカスタムフック
export const useArticleContext = () => {
  const context = useContext(ArticleContext);
  if (context === undefined) {
    throw new Error("useArticle must be used within an ArticleProvider");
  }
  return context;
};

export default ArticleContext;
