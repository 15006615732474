import Crop169Icon from "@mui/icons-material/Crop169";
import Crop32Icon from "@mui/icons-material/Crop32";
import CropDinIcon from "@mui/icons-material/CropDin";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Slider from "@mui/material/Slider";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AspectRatioIconType, useVideo } from "../../../../context/juno/VideoContext";
import { ColumnBox } from "../../../../utils/styledBox";
import AspectRatioIcons from "./AspectRatioIcons";
import ImageUpload from "./ImageUpload";

const ParamsLuma = () => {
  const { t } = useTranslation();
  const { menu, updateParams, image1, setImage1, duration, aiEngine, modelVersion, setLumaLoop, lumaLoop } = useVideo();

  const aspectRatio: AspectRatioIconType[] = [
    { ratio: "1:1", icon: CropDinIcon },
    { ratio: "16:9", icon: Crop169Icon },
    { ratio: "4:3", icon: Crop32Icon },
    { ratio: "9:16", icon: Crop169Icon, rotate: "90deg" },
    { ratio: "3:4", icon: Crop32Icon, rotate: "90deg" },
  ];

  const durationMarks = [
    {
      value: 5,
      label: "5s",
    },
    {
      value: 9,
      label: "9s",
    },
  ];

  useEffect(() => {
    if (menu === "i2v" && modelVersion.LUMA === "RAY_2") {
      updateParams("LUMA", "modelVersion", "V1_5");
    }
  }, [menu, modelVersion]);

  return (
    <ColumnBox sx={{ gap: 4 }}>
      {menu === "i2v" && (
        <ColumnBox sx={{ justifyContent: "center" }}>
          <Typography variant="body2" mb={1}>
            {t("juno.video.params.upload")}
          </Typography>
          <ImageUpload image={image1} setImage={setImage1} />
        </ColumnBox>
      )}
      <ColumnBox>
        {/* Model */}
        <Typography variant="body2" mb={1}>
          {t("juno.video.ai.model")}
        </Typography>
        <Select
          value={modelVersion.LUMA}
          onChange={(e) => updateParams("LUMA", "modelVersion", e.target.value)}
          variant="standard"
          fullWidth
        >
          <MenuItem value={"V1_5"}>{t("juno.video.ai.luma.model.V1_5")}</MenuItem>
          <MenuItem value={"RAY_2"} disabled={menu === "i2v"}>
            {t("juno.video.ai.luma.model.RAY_2")}
          </MenuItem>
        </Select>
      </ColumnBox>

      {modelVersion.LUMA === "RAY_2" && (
        <ColumnBox>
          {/* Duration */}
          <Typography variant="body2" mb={1}>
            {t("juno.video.params.duration")}
          </Typography>
          <Slider
            aria-label="duration"
            marks={durationMarks}
            min={5}
            max={9}
            step={4}
            value={duration.LUMA}
            onChange={(_, value) => updateParams("LUMA", "duration", value)}
          />
        </ColumnBox>
      )}
      <ColumnBox>
        {/* aspect */}
        <Typography variant="body2" mb={1}>
          {t("juno.video.params.aspect")}
        </Typography>
        <AspectRatioIcons aspectRatioList={aspectRatio} />
      </ColumnBox>
      <ColumnBox>
        {/* loop */}
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={lumaLoop} onChange={() => setLumaLoop(!lumaLoop)} />}
            label={t("juno.video.params.loop")}
          />
        </FormGroup>{" "}
      </ColumnBox>
    </ColumnBox>
  );
};
export default ParamsLuma;
