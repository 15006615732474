const mindmap = {
  title: "マインドマップ生成",
  library: "ライブラリ",
  editor: "マインドマップエディター",
  titleToMindMap: "タイトルから作る",
  description: "Tenak AI がブレインストーミングを行い、結果をマインドマップとして生成",
  titleToMindMapDescription: "入力されたタイトルを基にマインドマップを生成します。",
  URLToMindMap: "URL",
  URLToMindMapDescription: "URLの内容をAI解析し、マインドマップを生成します。",
  YouTubeToMindMap: "YouTube",
  YouTubeToMindMapDescription: "YouTubeの内容をAI解析し、マインドマップを生成します。",
  TextToMindMap: "テキスト",
  TextToMindMapDescription: "テキストをAI解析し、マインドマップを生成します。",
  URL: "URL",
  titleInput: "タイトル",
  titlePlaceholder: "例：「〇〇のアイディア」「〇〇のタスク」「〇〇の原因」（最大100文字）",
  urlPlaceholder: "https://www.example.com",
  youtubePlaceholder: "https://www.youtube.com/watch?v=xxxxxxxxxxx ( We also support YouTube Shorts. )",
  textPlaceholder: "マインドマップにまとめたい文章を入力して下さい。",
  additional: "追加指示（オプション）",
  additionalPlaceholder: "追加の指示がある場合、入力して下さい。（最大1000文字）",
  submit: "マインドマップを生成",
  deleteNode: "ノードを削除",
  addNode: "ノードを追加",
  autoLayout: "自動レイアウト",
  createNodes: "ノードをAIで作成",
  mindmapToText: "テキスト形式で出力",
  mindmapToPDF: "PDF形式で出力",
  mindmapToImage: "PNG画像で出力",
  mindmapToSVG: "SVG画像で出力",
  selectNode: "ノードを選択してください",
  edgeTypes: {
    title: "エッジの種類",
    default: "カーブ",
    straight: "ストレート",
    step: "ステップ",
    smooth: "ステップ（スムーズ）",
  },
  animation: {
    title: "エッジのアニメーション",
    enable: "有効",
    disable: "無効",
  },
  share: {
    title: "マインドマップの共有",
    description: "マインドマップの共有リンクを生成します。共有リンクを知っている人だけに公開可能です。",
    public: "共有",
    link: "共有リンク",
  },
  alert: {
    notDeletedNode: "最低でも1つのノードが必要なため、削除できません。",
    autoLayout: "自動レイアウトを適用しました。",
    createNodes: "ノードをAIで作成しました。",
    addNode: "ノードを追加しました。",
    autoLayoutError: "自動レイアウトに失敗しました。ノードが正しく接続されているか確認してください。",
  },
  notice: {
    toText: "テキストに変換したマインドマップがテキストライブラリに新規追加されました。",
    error: "マインドマップのテキスト変換に失敗しました。",
  },
  addImage: "画像を追加",
  addLink: "リンクを追加",
  nodeColorTooltip: "選択中のノードの色を変更します",
  addLinkTooltip: "選択中のノードにリンクを追加します",
  addImageTooltip: "選択中のノードに画像を追加します",
  addNodeTooltip: "選択中のノードに新しいノードを追加します",
  deleteNodeTooltip: "選択中のノードを削除します",
  autoLayoutTooltip: "ノードを自動配置します",
  createNodesTooltip: "選択中のノードにAIで新しいノードを追加します",
};
export default mindmap;
