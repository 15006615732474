import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { Box, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { ColumnBox, RowBox } from "../../../utils/styledBox";
import ButtonAI from "./ButtonAI";

interface AI {
  title: string;
  icon?: JSX.Element;
  path: string;
  img: string;
  mainTitle?: string;
}

const AIs = ({ title, icon, path, img, mainTitle }: AI) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box sx={{ width: "100%" }}>
      <ColumnBox>
        {mainTitle && (
          <RowBox sx={{ height: 48 }}>
            <Typography
              variant={"h6"}
              component={"h3"}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {icon ? (
                React.cloneElement(icon, { sx: { color: theme.palette.secondary.main, mr: 2 } })
              ) : (
                <AutoAwesomeIcon sx={{ color: theme.palette.secondary.main, mr: 2 }} />
              )}
              {mainTitle}
            </Typography>
          </RowBox>
        )}
        <Box sx={{ width: "100%" }}>
          <ButtonAI title={title} icon={icon ? icon : <AutoAwesomeIcon />} path={path} image={img} />
        </Box>
      </ColumnBox>
    </Box>
  );
};
export default AIs;
