import { Slider } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useParamsContext } from "../../../../../context/juno/ParamsContext";

const Steps = () => {
  const { t } = useTranslation();
  const { step, setStep, aiEngine } = useParamsContext();

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mb: 1 }}>
          <Typography variant={"body2"}>{t("juno.input.step")}</Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }} gap={2}>
          <Slider
            value={step[aiEngine!]}
            onChange={(e, value) => setStep({ ...step, [aiEngine!]: value as number })}
            min={aiEngine! === "Flux1Schnell" ? 1 : 10}
            max={aiEngine! === "Flux1Schnell" ? 12 : 50}
            step={1}
          />
          <Box
            sx={{
              width: "120px",
              bgcolor: "rgba(0, 0, 0, 0.1)",
              px: 1,
              py: 0.5,
              justifyContent: "center",
              display: "flex",
              borderRadius: "5px",
            }}
          >
            <Typography variant={"body2"}>{step[aiEngine!]}</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default Steps;
