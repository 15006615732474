import HeightIcon from "@mui/icons-material/Height";
import { Box, Slider, TextField, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface PitchControlProps {
  pitch: number;
  onPitchChange: (pitch: number) => void;
}

export const PitchControl: React.FC<PitchControlProps> = ({ pitch, onPitchChange }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography variant="body2" gutterBottom>
          {t("voice.pitch")}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <HeightIcon />
        <Slider
          sx={{ mx: 2 }}
          value={pitch}
          onChange={(_, newValue) => onPitchChange(newValue as number)}
          defaultValue={0}
          min={-20}
          max={20}
        />
        <TextField
          size="small"
          variant="standard"
          value={pitch}
          sx={{ width: 50 }}
          inputProps={{ style: { textAlign: "center" } }}
        />
      </Box>
    </Box>
  );
};
