import { alpha, Divider, MenuItem, Select } from "@mui/material";
import Box from "@mui/material/Box";
import { SelectChangeEvent } from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Edge } from "reactflow";
import "reactflow/dist/style.css";
import { useMindMap } from "../../../context/MindmapContext";
import { NodeColor } from "./CustomNode/types";
import { AddNodeButton } from "./ToolbarButtons/AddNodeButton";
import { AutoLayoutButton } from "./ToolbarButtons/AutoLayoutButton";
import { ColorButtons } from "./ToolbarButtons/ColorButtons";
import { CreateNodesButton } from "./ToolbarButtons/CreateNodesButton";
import { DeleteNodeButton } from "./ToolbarButtons/DeleteNodeButton";
import { EdgeControls } from "./ToolbarButtons/EdgeControls";
import { ImageButton } from "./ToolbarButtons/ImageButton";
import { LinkButton } from "./ToolbarButtons/LinkButton";

type SelectedNode = string | null;

type ToolbarAction = {
  value: string;
  label: string;
  action: () => void;
  disabled: boolean;
};

interface ToolbarProps {
  /** リンクを追加するモーダルを開く関数 */
  onOpenLinkModal?: () => void;
  /** 画像を追加するモーダルを開く関数 */
  onOpenImageModal?: () => void;
  /** ノードの色を変更する関数 */
  onColorChange?: (color: NodeColor) => void;
}

/**
 * ツールバーコンポーネント
 */
export const Toolbar: React.FC<ToolbarProps> = ({ onOpenLinkModal, onOpenImageModal, onColorChange }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { selectedNode, autoLayout, setActionTrigger } = useMindMap() as {
    selectedNode: SelectedNode;
    autoLayout: () => void;
    setActionTrigger: (id: string, action: "delete" | "add" | "") => void;
  };
  const { edges, setEdges, edgeType, setEdgeType, animation, setAnimation } = useMindMap();

  const changeAllEdgeType = (event: SelectChangeEvent<string>) => {
    const newEdgeType = event.target.value;
    setEdgeType(newEdgeType);
    const newEdges = edges.map((edge: Edge) => ({
      ...edge,
      type: newEdgeType,
    }));
    setEdges(newEdges);
  };

  const changeAllEdgeAnimation = (event: SelectChangeEvent<string>) => {
    const newAnimation = event.target.value === "true";
    setAnimation(newAnimation);
    const newEdges = edges.map((edge: Edge) => ({
      ...edge,
      animated: newAnimation,
    }));
    setEdges(newEdges);
  };

  const handleToolbarAction = (event: SelectChangeEvent<string>) => {
    const selectedAction = toolbarActions.find((action) => action.value === event.target.value);
    if (selectedAction) {
      selectedAction.action();
    }
  };

  const toolbarActions: ToolbarAction[] = [
    {
      value: "autoLayout",
      label: t("mindmap.autoLayout"),
      action: autoLayout,
      disabled: false,
    },
    {
      value: "addNode",
      label: t("mindmap.addNode"),
      action: () => setActionTrigger(selectedNode || "", "add"),
      disabled: !selectedNode,
    },
    {
      value: "createNodes",
      label: t("mindmap.createNodes"),
      action: () => setActionTrigger(selectedNode || "", "add"),
      disabled: !selectedNode,
    },
    ...(onOpenLinkModal
      ? [
          {
            value: "addLink",
            label: t("mindmap.addLink"),
            action: onOpenLinkModal,
            disabled: !selectedNode,
          },
        ]
      : []),
    ...(onOpenImageModal
      ? [
          {
            value: "addImage",
            label: t("mindmap.addImage"),
            action: onOpenImageModal,
            disabled: !selectedNode,
          },
        ]
      : []),
    {
      value: "deleteNode",
      label: t("mindmap.deleteNode"),
      action: () => setActionTrigger(selectedNode || "", "delete"),
      disabled: !selectedNode,
    },
  ];

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          left: 10,
          top: 10,
          display: { xs: "none", sm: "flex" },
          borderRadius: 4,
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
          bgcolor: alpha(theme.palette.background.custom1, 0.2),
          backdropFilter: "blur(4px)",
          overflow: "hidden",
        }}
      >
        <AutoLayoutButton autoLayout={autoLayout} />
        <Divider orientation="vertical" flexItem />

        {onColorChange && (
          <>
            <ColorButtons selectedNode={selectedNode || ""} onColorChange={onColorChange} />
            <Divider orientation="vertical" flexItem />
          </>
        )}

        {
          <>
            <AddNodeButton selectedNode={selectedNode || ""} />
            <Divider orientation="vertical" flexItem />
          </>
        }

        {
          <>
            <CreateNodesButton selectedNode={selectedNode || ""} />
            <Divider orientation="vertical" flexItem />
          </>
        }

        {onOpenLinkModal && (
          <>
            <LinkButton selectedNode={selectedNode || ""} onOpenLinkModal={onOpenLinkModal} />
            <Divider orientation="vertical" flexItem />
          </>
        )}

        {onOpenImageModal && (
          <>
            <ImageButton selectedNode={selectedNode || ""} onOpenImageModal={onOpenImageModal} />
            <Divider orientation="vertical" flexItem />
          </>
        )}

        {
          <DeleteNodeButton
            selectedNode={selectedNode || ""}
            onDelete={() => setActionTrigger(selectedNode || "", "delete")}
          />
        }
      </Box>

      <Box
        sx={{
          position: "absolute",
          left: 10,
          top: 10,
          zIndex: 1000,
          minWidth: 200,
          display: { xs: "block", sm: "none" },
          bgcolor: alpha(theme.palette.background.custom1, 0.2),
          backdropFilter: "blur(4px)",
          borderRadius: 4,
        }}
      >
        <Select
          value=""
          onChange={handleToolbarAction}
          displayEmpty
          fullWidth
          sx={{
            color: theme.palette.text.primary,
            "& .MuiSelect-select": {
              py: 1,
            },
          }}
        >
          <MenuItem value="" disabled>
            Tools
          </MenuItem>
          {toolbarActions.map((action) => (
            <MenuItem value={action.value} key={action.value} disabled={action.disabled}>
              {action.label}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <EdgeControls
          edgeType={edgeType}
          animation={animation}
          onEdgeTypeChange={changeAllEdgeType}
          onAnimationChange={changeAllEdgeAnimation}
        />
      </Box>
    </>
  );
};

Toolbar.propTypes = {
  onOpenLinkModal: PropTypes.func,
  onOpenImageModal: PropTypes.func,
  onColorChange: PropTypes.func,
};

export default Toolbar;
