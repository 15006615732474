import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useParamsContext } from "../../../../../context/juno/ParamsContext";
import { StyleFluxJuno, StyleRecraftV3 } from "../../../../../types/junoTypes";
import { styleTranslate } from "../../../../../utils/styleTranslate";

const Style = () => {
  const { t } = useTranslation();
  const { style, setStyle, aiEngine } = useParamsContext();

  const ideogramStyles: { label: string; value: "GENERAL" | "REALISTIC" | "DESIGN" | "ANIME" | "RENDER_3D" }[] = [
    { label: "general", value: "GENERAL" },
    { label: "realistic", value: "REALISTIC" },
    { label: "design", value: "DESIGN" },
    { label: "anime", value: "ANIME" },
    { label: "render3d", value: "RENDER_3D" },
  ];

  const recraftStyles = [
    { options: [{ value: "any" }] },
    {
      options: [
        { value: "realistic_image" },
        { value: "realistic_image/b_and_w" },
        { value: "realistic_image/hard_flash" },
        { value: "realistic_image/hdr" },
        { value: "realistic_image/natural_light" },
        { value: "realistic_image/studio_portrait" },
        { value: "realistic_image/enterprise" },
        { value: "realistic_image/motion_blur" },
        { value: "realistic_image/evening_light" },
        { value: "realistic_image/faded_nostalgia" },
        { value: "realistic_image/forest_life" },
        { value: "realistic_image/mystic_naturalism" },
        { value: "realistic_image/natural_tones" },
        { value: "realistic_image/organic_calm" },
        { value: "realistic_image/real_life_glow" },
        { value: "realistic_image/retro_realism" },
        { value: "realistic_image/retro_snapshot" },
        { value: "realistic_image/urban_drama" },
        { value: "realistic_image/village_realism" },
        { value: "realistic_image/warm_folk" },
      ],
    },
    {
      options: [
        { value: "digital_illustration" },
        { value: "digital_illustration/pixel_art" },
        { value: "digital_illustration/hand_drawn" },
        { value: "digital_illustration/grain" },
        { value: "digital_illustration/grain_20" },
        { value: "digital_illustration/infantile_sketch" },
        { value: "digital_illustration/2d_art_poster" },
        { value: "digital_illustration/2d_art_poster_2" },
        { value: "digital_illustration/handmade_3d" },
        { value: "digital_illustration/hand_drawn_outline" },
        { value: "digital_illustration/engraving_color" },
        { value: "digital_illustration/antiquarian" },
        { value: "digital_illustration/bold_fantasy" },
        { value: "digital_illustration/child_book" },
        { value: "digital_illustration/child_books" },
        { value: "digital_illustration/cover" },
        { value: "digital_illustration/crosshatch" },
        { value: "digital_illustration/digital_engraving" },
        { value: "digital_illustration/expressionism" },
        { value: "digital_illustration/freehand_details" },
        { value: "digital_illustration/graphic_intensity" },
        { value: "digital_illustration/hard_comics" },
        { value: "digital_illustration/long_shadow" },
        { value: "digital_illustration/modern_folk" },
        { value: "digital_illustration/multicolor" },
        { value: "digital_illustration/neon_calm" },
        { value: "digital_illustration/noir" },
        { value: "digital_illustration/nostalgic_pastel" },
        { value: "digital_illustration/outline_details" },
        { value: "digital_illustration/pastel_gradient" },
        { value: "digital_illustration/pastel_sketch" },
        { value: "digital_illustration/pop_art" },
        { value: "digital_illustration/pop_renaissance" },
        { value: "digital_illustration/street_art" },
        { value: "digital_illustration/tablet_sketch" },
        { value: "digital_illustration/urban_glow" },
        { value: "digital_illustration/urban_sketching" },
        { value: "digital_illustration/vanilla_dreams" },
        { value: "digital_illustration/young_adult_book" },
        { value: "digital_illustration/young_adult_book_2" },
      ],
    },
    {
      options: [
        { value: "vector_illustration" },
        { value: "vector_illustration/bold_stroke" },
        { value: "vector_illustration/chemistry" },
        { value: "vector_illustration/colored_stencil" },
        { value: "vector_illustration/contour_pop_art" },
        { value: "vector_illustration/cosmics" },
        { value: "vector_illustration/cutout" },
        { value: "vector_illustration/depressive" },
        { value: "vector_illustration/editorial" },
        { value: "vector_illustration/emotional_flat" },
        { value: "vector_illustration/infographical" },
        { value: "vector_illustration/marker_outline" },
        { value: "vector_illustration/mosaic" },
        { value: "vector_illustration/naivector" },
        { value: "vector_illustration/roundish_flat" },
        { value: "vector_illustration/segmented_colors" },
        { value: "vector_illustration/sharp_contrast" },
        { value: "vector_illustration/thin" },
        { value: "vector_illustration/vector_photo" },
        { value: "vector_illustration/vivid_shapes" },
        { value: "vector_illustration/engraving" },
        { value: "vector_illustration/line_art" },
        { value: "vector_illustration/line_circuit" },
        { value: "vector_illustration/linocut" },
      ],
    },
  ];

  const proJuno = ["Flux1ProJuno", "Flux11ProUltraJuno", "Flux1ProCannyJuno", "Flux1ProDepthJuno", "Flux1ProFillJuno"];

  const RecraftMenuItem: JSX.Element[] = recraftStyles.reduce((acc: JSX.Element[], group, index) => {
    group.options.forEach((option) => {
      acc.push(
        <MenuItem key={option.value} value={option.value}>
          {t(styleTranslate(option.value))}
        </MenuItem>
      );
    });
    if (index < recraftStyles.length - 1) {
      acc.push(<Divider key={`divider-${index}`} />);
    }
    return acc;
  }, []);

  return (
    <>
      {aiEngine == "DallE3" && (
        <>
          <FormControl fullWidth>
            <InputLabel id="ai-engine-select-label">{t("juno.input.style.title")}</InputLabel>
            <Select
              size={"small"}
              value={style.DallE3}
              label={t("juno.input.style.title")}
              onChange={(event) => {
                const newValue = event.target.value;
                if (newValue === "vivid" || newValue === "natural") {
                  setStyle({ ...style, DallE3: newValue });
                }
              }}
            >
              <MenuItem value="vivid">{t("juno.input.style.dalle3.vivid")}</MenuItem>
              <MenuItem value="natural">{t("juno.input.style.dalle3.natural")}</MenuItem>
            </Select>
          </FormControl>
        </>
      )}
      {["FluxJuno", "Flux1DevCannyLoRA", "Flux1DevDepthLoRA", "Flux1DevFill", ...proJuno].includes(aiEngine!) && (
        <>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mb: 2 }} gap={1}>
            <FormControl fullWidth>
              <InputLabel id="ai-engine-select-label">{t("juno.input.style.title")}</InputLabel>
              <Select
                size={"small"}
                value={style[aiEngine!]}
                label={t("juno.input.style.title")}
                onChange={(event) => {
                  const newValue = event.target.value as StyleFluxJuno;
                  setStyle({ ...style, [aiEngine!]: newValue });
                }}
              >
                {proJuno.includes(aiEngine!) && <MenuItem value="ANIME_PRO">{t(styleTranslate("ANIME_PRO"))}</MenuItem>}
                {proJuno.includes(aiEngine!) && (
                  <MenuItem value="JAPANESE_PRO">{t(styleTranslate("JAPANESE_PRO"))}</MenuItem>
                )}

                {!proJuno.includes(aiEngine!) &&
                  ["Flux1DevCannyLoRA", "Flux1DevDepthLoRA", "Flux1DevFill"].includes(aiEngine!) && (
                    <MenuItem value="GENERAL">{t(styleTranslate("GENERAL"))}</MenuItem>
                  )}

                {!proJuno.includes(aiEngine!) && <MenuItem value="ANIME">{t(styleTranslate("ANIME"))}</MenuItem>}
                {!proJuno.includes(aiEngine!) && <MenuItem value="JAPANESE">{t(styleTranslate("JAPANESE"))}</MenuItem>}
              </Select>
            </FormControl>
          </Box>
        </>
      )}
      {["Ideogram2", "Ideogram2Turbo", "Ideogram2Edit", "Ideogram2TurboEdit"].includes(aiEngine!) && (
        <Box>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={1} mb={1}>
            <Typography variant={"body2"}>{t("juno.input.style.title")}</Typography>
          </Box>
          <Grid container spacing={2}>
            {ideogramStyles.map(({ label, value }) => (
              <Grid item key={label}>
                <Button
                  variant={style[aiEngine!] === value ? "outlined" : "text"}
                  onClick={() => setStyle({ ...style, [aiEngine!]: value })}
                >
                  <Typography variant="caption">{t(`juno.input.style.ideogram.${label}`)}</Typography>
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      {["RecraftV3"].includes(aiEngine!) && (
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mb: 2 }} gap={1}>
          <FormControl fullWidth>
            <InputLabel id="ai-engine-select-label">{t("juno.input.style.title")}</InputLabel>
            <Select
              size={"small"}
              value={style[aiEngine!]}
              label={t("juno.input.style.title")}
              onChange={(event) => {
                const newValue = event.target.value as StyleRecraftV3;
                setStyle({ ...style, [aiEngine!]: newValue });
              }}
            >
              {RecraftMenuItem}
            </Select>
          </FormControl>
        </Box>
      )}
    </>
  );
};
export default Style;
