import SpeedIcon from "@mui/icons-material/Speed";
import { Box, Slider, TextField, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface SpeedControlProps {
  speed: number;
  onSpeedChange: (speed: number) => void;
}

export const SpeedControl: React.FC<SpeedControlProps> = ({ speed, onSpeedChange }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography variant="body2" gutterBottom>
          {t("voice.speed")}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <SpeedIcon />
        <Slider
          sx={{ mx: 2 }}
          value={speed}
          onChange={(_, newValue) => onSpeedChange(newValue as number)}
          aria-labelledby="speed-slider"
          defaultValue={1}
          step={0.05}
          min={0.25}
          max={4}
        />
        <TextField
          size="small"
          variant="standard"
          value={speed}
          sx={{ width: 50 }}
          inputProps={{ style: { textAlign: "center" } }}
        />
      </Box>
    </Box>
  );
};
