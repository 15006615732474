import { Box, styled } from "@mui/material";
import { keyframes } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const Companies = () => {
  const { t } = useTranslation();

  const pngImages = [
    "anthropic",
    "aws",
    "bfl",
    // "fal",
    "google",
    "hailuo",
    "haiper",
    "ideogram",
    "pixverse",
    "kling",
    "luma",
    "openai",
    "runway",
    "stability_ai",
    "stripe",
  ];

  const scrollDuration = 50; // スクロールの持続時間（秒）

  // コンテナの幅を取得するためのRefとState
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);

  // すべての画像がロードされたかどうかを管理するためのState
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const imagesToLoad = pngImages.length * 3; // 画像の総数
  let imagesLoadedCount = 0;

  // 画像がロードされたときに呼び出される関数
  const handleImageLoad = () => {
    imagesLoadedCount += 1;
    if (imagesLoadedCount === imagesToLoad) {
      setImagesLoaded(true);
    }
  };

  // コンテナの幅を取得し、Stateにセット
  useEffect(() => {
    if (imagesLoaded && containerRef.current) {
      const width = containerRef.current.scrollWidth;
      setContainerWidth(width / 3); // 画像リスト1回分の幅
    }
  }, [imagesLoaded]);

  // アニメーションを動的に定義
  const ScrollAnimation = keyframes`
      from {
          transform: translateX(0);
      }
      to {
          transform: translateX(-${containerWidth}px);
      }
  `;

  // スクロールするコンテナをスタイル付きコンポーネントで定義
  const ScrollContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    animation: containerWidth ? `${ScrollAnimation} ${scrollDuration}s linear infinite` : "none",
    gap: 10,
  }));

  return (
    <Box
      sx={{
        overflow: "hidden",
        whiteSpace: "nowrap",
        width: "100vw",
        position: "relative",
        py: 2,
      }}
    >
      <ScrollContainer ref={containerRef}>
        {/* 画像リストを3回繰り返す */}
        {Array(3)
          .fill(0)
          .flatMap(() => pngImages)
          .map((image, index) => (
            <Box
              key={index}
              component="img"
              src={`/images/top/companies/${image}.png`}
              alt={image}
              onLoad={handleImageLoad}
              sx={{
                height: { xs: 40, md: 60 },
                width: "auto",
                flexShrink: 0,
                borderRadius: 2,
                filter: "grayscale(100%)",
                // opacity: 0.8,
                transition: "filter 0.3s, opacity 0.3s",
                "&:hover": {
                  filter: "none",
                  opacity: 1,
                },
              }}
            />
          ))}
      </ScrollContainer>
    </Box>
  );
};

export default Companies;
