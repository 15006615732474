import MuiAlert, { AlertColor, AlertProps } from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import React from "react";
import { useAlert } from "../../context/AlertContext";
import { useCustomNavigate } from "../../hooks/useCustomNavigate";

// MuiAlert コンポーネントを Snackbar で使うためのラッパー
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertMessage = () => {
  const { alertType, alertMessage, showAlert, path, closeAlert } = useAlert();
  const navigate = useCustomNavigate();

  const handleNavigate = () => {
    navigate(path);
    closeAlert();
  };

  return (
    <Snackbar
      open={showAlert}
      autoHideDuration={6000}
      onClose={closeAlert}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert
        onClose={closeAlert}
        severity={alertType as AlertColor}
        sx={{ whiteSpace: "break-spaces", color: "white" }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2, flexDirection: "column" }}>
          {alertMessage}
          {path && (
            <Button
              variant="contained"
              size="small"
              onClick={handleNavigate}
              disableElevation
              color="secondary"
              sx={{
                width: 150,
              }}
            >
              Go to File
            </Button>
          )}
        </Box>
      </Alert>
    </Snackbar>
  );
};

export default AlertMessage;
