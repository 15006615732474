import Grid3x3Icon from "@mui/icons-material/Grid3x3";
import { ButtonBase, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

interface AutoLayoutButtonProps {
  /** 自動レイアウトを実行する関数 */
  autoLayout: () => void;
}

/**
 * 自動レイアウトを実行するボタンコンポーネント
 */
export const AutoLayoutButton: React.FC<AutoLayoutButtonProps> = ({ autoLayout }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Tooltip arrow title={t("mindmap.autoLayoutTooltip")}>
      <ButtonBase
        color="primary"
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          autoLayout();
        }}
        sx={{
          zIndex: 1000,
          py: 1,
          px: 2,
          transition: "color 0.2s ease-in-out",
          "&:hover": { color: theme.palette.primary.main },
          "&.Mui-disabled": {
            opacity: 0.5,
            color: theme.palette.text.disabled,
          },
        }}
      >
        <Grid3x3Icon sx={{ mr: 1 }} fontSize="small" />
        <Typography variant="button">{t("mindmap.autoLayout")}</Typography>
      </ButtonBase>
    </Tooltip>
  );
};

AutoLayoutButton.propTypes = {
  autoLayout: PropTypes.func.isRequired,
};
