import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParamsContext } from "../../../../../context/juno/ParamsContext";

const Sample = () => {
  const { t } = useTranslation();
  const { sample, setSample, aiEngine } = useParamsContext();
  const icons = Array.from({ length: 4 }, (_, index) => index + 1);

  const handleSampleChange = (index: number) => {
    setSample({ ...sample, [aiEngine]: index + 1 });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }} gap={1}>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Typography variant={"body2"}>{t("juno.input.sample")}</Typography>
      </Box>
      <Box sx={{ display: "inline-flex", flexDirection: "row" }} gap={1}>
        <ButtonGroup variant="outlined" sx={{ width: "100%" }}>
          {icons.map((icon, index) => (
            <Button
              disableElevation
              key={index}
              onClick={() => handleSampleChange(index)}
              variant={sample[aiEngine] === index + 1 ? "contained" : "outlined"}
              sx={{ width: "100%" }}
            >
              <Typography variant={"button"}>{icon}</Typography>
            </Button>
          ))}
        </ButtonGroup>
      </Box>
    </Box>
  );
};
export default Sample;
