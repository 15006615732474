import { Box, Typography } from "@mui/material";
import React from "react";
import { RowCenteredBox } from "../../utils/styledBox";

interface NotesNebulaLogoProps {
  onClick?: () => void;
}

const NotesNebulaLogoComponent: React.FC<NotesNebulaLogoProps> = ({ onClick }) => {
  return (
    <RowCenteredBox onClick={onClick} sx={{ cursor: onClick ? "pointer" : "default" }}>
      <img src="/images/logo/NN.png" style={{ width: 35, height: 35, marginRight: 8 }} alt="Notes Nebula" />
      <Box display={{ xs: "none", md: "block" }}>
        <Typography variant="h5" component="p" sx={{ fontWeight: "bold" }}>
          Notes Nebula
        </Typography>
      </Box>
    </RowCenteredBox>
  );
};

NotesNebulaLogoComponent.displayName = "NotesNebulaLogo";

export const NotesNebulaLogo = React.memo(NotesNebulaLogoComponent);
