import EditIcon from "@mui/icons-material/Edit";
import { Container, Fade } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { DrawerHeader } from "../../components/layout/Drawer/DrawerMain";
import Meta from "../../components/common/Meta";
import { useAlert } from "../../context/AlertContext";
import { useLoginModalContext } from "../../context/LoginModalContext";
import { useLoginStatus } from "../../context/LoginStatusContext";
import { useCustomNavigate } from "../../hooks/useCustomNavigate";
import { GradationButton } from "../../utils/gradationButton";
import { GradientTypography } from "../../utils/gradientTypography";
import Latest from "./components/Latest";
import Official from "./components/Official";

const NotesNebula = () => {
  const { t } = useTranslation();
  const { isLoggedIn } = useLoginStatus();
  const navigate = useCustomNavigate();
  const { setAlert } = useAlert();
  const loginContext = useLoginModalContext();

  const handlePost = async () => {
    try {
      if (isLoggedIn) {
        const csrftoken = Cookies.get("csrftoken");
        const config = {
          headers: { "X-CSRFToken": csrftoken },
        };
        const res = await axios.post("/api/v1/text-file/", { type: 1 }, config);
        navigate(`/notes-nebula/files/${res.data.uuid}`);
      } else {
        loginContext.setActiveModal("login");
      }
    } catch (e) {
      setAlert("error", "Failed to create a new note. Please try again.");
    }
  };

  const meta = [
    { name: "robots", content: "index, follow" },
    { name: "keywords", content: t("meta.notesNebula.keywords") },
    { name: "description", content: t("meta.notesNebula.description") },
  ];

  return (
    <>
      <Meta title={t("meta.notesNebula.title")} meta={meta} />
      <Fade in={true} timeout={1500}>
        <Container maxWidth={"lg"}>
          <DrawerHeader />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              backgroundImage: "url('/images/notes-nebula/top.jpg')",
              height: { xs: 300, md: 400 },
              borderRadius: 2,
              backgroundSize: "cover",
              backgroundPosition: "center",
              position: "relative", // コンテンツが暗くならないようにする
              "&::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0,0,0,0.6)",
                borderRadius: 2,
                zIndex: 1,
              },
              mt: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                zIndex: 2,
              }}
            >
              <Box sx={{ display: "inline", fontWeight: "bold" }} component={"h1"}>
                <GradientTypography variant="h2" component="span">
                  N
                </GradientTypography>
                <Typography variant="h2" component="span" color={"#fff"}>
                  otes&nbsp;
                </Typography>
                <GradientTypography variant="h2" component="span">
                  N
                </GradientTypography>
                <Typography variant="h2" component="span" color={"#fff"}>
                  ebula
                </Typography>
                <Typography variant="h6" component="span" sx={{ ml: 2 }} color={"#fff"}>
                  Beta
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                zIndex: 2,
              }}
            >
              <Typography variant={"subtitle1"} sx={{ textAlign: "center", mb: 4, mx: 2 }} color={"#fff"}>
                {t("notesNebula.description")}
              </Typography>
              <GradationButton onClick={handlePost} sx={{ px: 2 }} startIcon={<EditIcon />}>
                {t("notesNebula.post")}
              </GradationButton>
            </Box>
          </Box>

          {/*  人気の記事  */}
          <></>

          {/*  最新記事  */}
          <Box my={4}>
            <Latest limit={20} />
          </Box>
          <Box my={4}>
            <Official limit={10} />
          </Box>
        </Container>
      </Fade>
    </>
  );
};
export default NotesNebula;
