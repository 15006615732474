import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import SaveIcon from "@mui/icons-material/Save";
import { Collapse, FormGroup } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Cookies from "js-cookie";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useAlert } from "../../../../context/AlertContext";
import { useArticleContext } from "../../../../context/ArticleContext";
import { useCheckCredit } from "../../../../hooks/useCreditCheck";
import { setCreditTrigger } from "../../../../redux/slices/triggerSlice";
import { StyledTypography } from "../../../../utils/styledTypography";

export default function Heading() {
  const { t } = useTranslation();
  const { checkCredit } = useCheckCredit();
  const [processing, setProcessing] = React.useState(false);
  const { headings, setHeadings, keywords, content, srcType, target, manualHeadings, setManualHeadings } =
    useArticleContext();

  const dispatch = useDispatch();
  const { setAlert } = useAlert();

  const createHeadings = async () => {
    if (!(await checkCredit())) return;
    if (keywords.trim().length === 0 && srcType === "") {
      setAlert("error", t("article.error.createHeading"));
      return;
    }
    try {
      setProcessing(true);
      const post_url = "/api/v1/article/headings";
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });
      const data = {
        keywords: keywords,
        content: content,
        srcType: srcType,
        target: target,
      };
      const response = await fetch(post_url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      });
      const responseData = await response.json();
      if (responseData.success) {
        dispatch(setCreditTrigger(true));
        setHeadings(responseData.data);
      } else {
        setAlert("error", responseData.message);
      }
    } catch (e) {
    } finally {
      setProcessing(false);
    }
  };

  // 見出しだけのファイル作成
  const handleNewFile = async () => {
    try {
      axios.defaults.withCredentials = true;
      const csrftoken = Cookies.get("csrftoken");
      // リクエストヘッダーにCSRFトークンを追加
      const config = {
        headers: { "X-CSRFToken": csrftoken },
      };
      await axios.post("/api/v1/text-file/", { type: 1, content: headings }, config);
      setAlert("success", t("file.createFile"));
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };

  return (
    <Box>
      <Box display={"flex"} flexDirection={"row"} mb={2}>
        <StyledTypography variant="body1" gutterBottom>
          {t("article.heading")}
        </StyledTypography>
      </Box>
      <Box>
        <FormGroup sx={{ mb: 2 }}>
          <FormControlLabel
            control={<Checkbox checked={manualHeadings} onChange={(e) => setManualHeadings(e.target.checked)} />}
            label={<Typography variant={"body2"}>{t("article.autoGenerate")}</Typography>}
          />
        </FormGroup>
        <Collapse in={manualHeadings}>
          <Box display={"flex"} alignItems={"center"} mb={2}>
            <FormatListBulletedIcon sx={{ mr: 1 }} color={"secondary"} fontSize={"small"} />
            <Typography variant={"body2"}>{t("article.headingDescription")}</Typography>
          </Box>

          <TextField
            placeholder={t("article.headingPlaceholder")}
            type="text"
            variant="outlined"
            fullWidth
            value={headings}
            onChange={(e) => setHeadings(e.target.value)}
            multiline
            rows={10}
            disabled={processing}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {processing ? (
                    <CircularProgress size={30} />
                  ) : (
                    <Tooltip title={t("article.generateHeadingsDescription")}>
                      <span>
                        <IconButton onClick={createHeadings} disabled={processing || keywords.trim().length === 0}>
                          <AutoAwesomeIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title={t("article.createWithoutText")}>
                    <span>
                      <IconButton onClick={handleNewFile} disabled={headings.trim().length === 0}>
                        <SaveIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            inputProps={{ maxLength: 1000 }}
          />
          <Box display={"flex"} justifyContent={"flex-end"}>
            <Typography variant={"body2"}>Lines {headings.split("\n").length}/20</Typography>
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
}
