import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

interface EdgeControlsProps {
  /** エッジの種類 */
  edgeType: string;
  /** アニメーションの有無 */
  animation: boolean;
  /** エッジの種類を変更する関数 */
  onEdgeTypeChange: (event: SelectChangeEvent<string>) => void;
  /** アニメーションの有無を変更する関数 */
  onAnimationChange: (event: SelectChangeEvent<string>) => void;
}

/**
 * エッジの種類とアニメーションを制御するコンポーネント
 */
export const EdgeControls: React.FC<EdgeControlsProps> = ({
  edgeType,
  animation,
  onEdgeTypeChange,
  onAnimationChange,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: "absolute",
        right: 10,
        top: 10,
        display: "flex",
        borderRadius: 1,
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
        bgcolor: alpha(theme.palette.background.custom1, 0.2),
        backdropFilter: "blur(4px)",
        overflow: "hidden",
        p: 1,
        gap: 1,
      }}
    >
      <FormControl sx={{ width: 200 }} variant="outlined">
        <InputLabel id="edge-type">{t("mindmap.edgeTypes.title")}</InputLabel>
        <Select
          labelId="edge-type"
          id="edge"
          value={edgeType}
          onChange={onEdgeTypeChange}
          label={t("mindmap.edgeTypes.title")}
          size="small"
          variant="outlined"
        >
          <MenuItem value="default">{t("mindmap.edgeTypes.default")}</MenuItem>
          <MenuItem value="straight">{t("mindmap.edgeTypes.straight")}</MenuItem>
          <MenuItem value="step">{t("mindmap.edgeTypes.step")}</MenuItem>
          <MenuItem value="smoothstep">{t("mindmap.edgeTypes.smooth")}</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ width: 200 }} variant="outlined">
        <InputLabel id="Animation">{t("mindmap.animation.title")}</InputLabel>
        <Select
          labelId="Animation"
          id="animation-select"
          value={`${animation}`}
          onChange={onAnimationChange}
          label={t("mindmap.animation.title")}
          size="small"
        >
          <MenuItem value="true">{t("mindmap.animation.enable")}</MenuItem>
          <MenuItem value="false">{t("mindmap.animation.disable")}</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

EdgeControls.propTypes = {
  edgeType: PropTypes.string.isRequired,
  animation: PropTypes.bool.isRequired,
  onEdgeTypeChange: PropTypes.func.isRequired,
  onAnimationChange: PropTypes.func.isRequired,
};
