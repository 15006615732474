const article = {
  library: "ライブラリ",
  title: "SEO｜コンテンツ\n記事",
  description: "Tenak AI がGoogle検索を行い、SEO評価の高い記事を参考にしながら記事を生成",
  keyword: "キーワード / タイトル",
  keywordPlaceholder: "（例）iPhone カメラ 性能",
  target: "ターゲット",
  targetPlaceholder: "（例）20〜30代 動画クリエイター",
  source: "参考元",
  sourceDescription: "参考元を選択してください。",
  heading: "見出し構成",
  headingPlaceholder: "## 大見出し\n### 小見出し\n## 大見出し2\n### 小見出し2",
  headingDescription: "行ごとに見出しを入力してください。",
  tone: "テキストのトーン",
  additional: "追加情報",
  additionalPlaceholder: "従うべき注意点やガイドラインを入力してください。",
  autoGenerate: "見出しを詳細に指定する",
  srcType: {
    url: "URL",
    urlPlaceholder: "https://www.example.com",
    urlDescription: "参考にするためのWebサイトのURLを入力して下さい。",
    youtube: "YouTube",
    youtubePlaceholder: "https://www.youtube.com/watch?v=xxxxxxxxxxx ( We also support YouTube Shorts. )",
    youtubeDescription: "参考にするためのYouTubeのURLを入力して下さい。",
    google: "Google 検索",
    googleDescription: "Google 検索の上位結果を参考にします。",
    text: "テキスト",
    textPlaceholder: "テキスト（最大30,000文字）",
    textDescription: "参考にするためのテキストを入力して下さい。",
    none: "なし",
    noneDescription: "Tenak AI が文章を生成します。",
  },
  generateHeadings: "Tenak AI で見出し生成",
  generateHeadingsDescription: "キーワードを元に、Tenak AI が見出しを生成（クレジットを消費）",
  createWithoutText: "見出しのみのファイル作成",
  submit: "記事を生成",
  error: {
    createHeading: "キーワードを入力してから見出しを生成してください。",
    invalidYoutubeUrl: "YouTubeのURLを正しく入力してください。",
    invalidUrl: "URLを正しく入力してください。",
    invalidText: "テキストを正しく入力してください。",
    invalidHeadings: "見出しを正しく入力してください。",
    invalidKeywords: "キーワードを正しく入力してください。",
    invalidHeadingsLength: "見出しは20個まで入力可能です。",
  },
};
export default article;
