import { useCallback, useEffect, useRef, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ReactFlow, {
  addEdge,
  applyNodeChanges,
  Background,
  Connection,
  Controls,
  Edge,
  getConnectedEdges,
  getIncomers,
  getOutgoers,
  Node,
  NodeChange,
  OnConnect,
  ReactFlowInstance,
  reconnectEdge,
} from "reactflow";
import { useAlert } from "../../../context/AlertContext";
import { useMindMap } from "../../../context/MindmapContext";
import CustomNode from "./CustomNode/index";

const nodeTypes = { custom: CustomNode };

const MindMap = () => {
  const reactFlowWrapper = useRef<HTMLDivElement | null>(null);
  const edgeUpdateSuccessful = useRef(true);
  const snapGrid = [10, 10];
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const { mindMapUuid } = useParams<{ mindMapUuid: string }>();

  const {
    nodes: contextNodes,
    edges: contextEdges,
    setNodes: setContextNodes,
    setEdges: setContextEdges,
    setSelectedNode,
    setReactFlowInstance,
    actionTrigger,
    getMindMap,
    autoLayout,
    animation,
    edgeType,
    setEdgeType,
    nodeDefaults,
    setTitle,
    status,
    setStatus,
    setAnimation,
  } = useMindMap();

  // ローカル状態でノードとエッジを管理
  const [nodesLocal, setNodesLocal] = useState<Node[]>([]);
  const [edgesLocal, setEdgesLocal] = useState<Edge[]>([]);

  // コンテキストからローカル状態に同期
  useEffect(() => {
    setNodesLocal(contextNodes);
  }, [contextNodes]);

  useEffect(() => {
    setEdgesLocal(contextEdges);
  }, [contextEdges]);

  // ノード削除アクションの監視
  useEffect(() => {
    if (actionTrigger.node_action === "delete") {
      onDelete(actionTrigger.id);
    }
  }, [actionTrigger.triggerFetch]);

  // ノード削除の処理
  const onDelete = useCallback(
    (id: string) => {
      if (contextNodes.length === 1) {
        setAlert("error", t("mindmap.alert.notDeletedNode"));
        return;
      }
      const nodeToDelete = contextNodes.find((node) => node.id === id);
      if (!nodeToDelete) return;
      const incomers = getIncomers(nodeToDelete, contextNodes, contextEdges);
      const outgoers = getOutgoers(nodeToDelete, contextNodes, contextEdges);
      const updatedEdges = contextEdges.filter((edge) => edge.source !== id && edge.target !== id);
      const createdEdges = incomers.flatMap(({ id: source }) =>
        outgoers.map(({ id: target }) => {
          const existingEdge = contextEdges.find(
            (e) => (e.source === source && e.target === id) || (e.source === id && e.target === target)
          );
          return {
            id: `${source}->${target}`,
            source,
            target,
            ...(existingEdge || {}),
            animated: animation,
            type: edgeType,
          };
        })
      );
      unstable_batchedUpdates(() => {
        const newEdges = [...updatedEdges, ...createdEdges];
        const newNodes = contextNodes.filter((node) => node.id !== id);
        setContextEdges(newEdges);
        setContextNodes(newNodes);
      });
    },
    [contextNodes, contextEdges, animation, edgeType, setAlert, t, setContextEdges, setContextNodes]
  );

  // ReactFlowの初期化
  const onLoad = useCallback(
    (instance: ReactFlowInstance) => {
      setReactFlowInstance(instance);
      instance?.fitView();
    },
    [setReactFlowInstance]
  );

  // ノード間の接続処理
  const onConnect: OnConnect = useCallback(
    (connection) => {
      const newEdge = {
        ...connection,
        animated: animation,
        type: edgeType,
      };
      unstable_batchedUpdates(() => {
        const newEdges = addEdge(newEdge, contextEdges);
        setContextEdges(newEdges);
      });
    },
    [contextEdges, setContextEdges, animation, edgeType]
  );

  // ノード削除時のエッジ処理
  const onNodesDelete = useCallback(
    (deleted: Node[]) => {
      const newEdges = deleted.reduce((acc, node) => {
        const incomers = getIncomers(node, contextNodes, acc);
        const outgoers = getOutgoers(node, contextNodes, acc);
        const connectedEdges = getConnectedEdges([node], acc);
        const remainingEdges = acc.filter((edge) => !connectedEdges.includes(edge));
        const createdEdges = incomers.flatMap(({ id: source }) =>
          outgoers.map(({ id: target }) => {
            const existingEdge = contextEdges.find(
              (e) => (e.source === source && e.target === node.id) || (e.source === node.id && e.target === target)
            );
            return {
              id: `${source}->${target}`,
              source,
              target,
              ...(existingEdge || {}),
              animated: animation,
              type: edgeType,
            };
          })
        );
        return [...remainingEdges, ...createdEdges];
      }, contextEdges);
      unstable_batchedUpdates(() => {
        const newNodes = contextNodes.filter((n) => !deleted.find((d) => d.id === n.id));
        setContextEdges(newEdges);
        setContextNodes(newNodes);
      });
    },
    [contextNodes, contextEdges, animation, edgeType, setContextEdges, setContextNodes]
  );

  // エッジ更新開始時の処理
  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false;
  }, []);

  // エッジ更新時の処理
  const onEdgeUpdate = useCallback(
    (oldEdge: Edge, newConnection: Connection) => {
      edgeUpdateSuccessful.current = true;
      unstable_batchedUpdates(() => {
        const newEdges = reconnectEdge(oldEdge, newConnection, contextEdges);
        setContextEdges(newEdges);
      });
    },
    [contextEdges, setContextEdges]
  );

  // エッジ更新終了時の処理
  const onEdgeUpdateEnd = useCallback(
    (_: MouseEvent, edge: Edge) => {
      if (!edgeUpdateSuccessful.current) {
        unstable_batchedUpdates(() => {
          const newEdges = contextEdges.filter((e) => e.id !== edge.id);
          setContextEdges(newEdges);
        });
      }
    },
    [contextEdges, setContextEdges]
  );

  // ノードの位置が未設定の場合、自動レイアウトを実行
  useEffect(() => {
    if (nodesLocal.length > 0 && nodesLocal[0]?.position.x == null) {
      const allNodesHaveSize = nodesLocal.every((node) => node.data.size);
      if (allNodesHaveSize) {
        const updatedNodes = autoLayout();
        setNodesLocal(updatedNodes as Node[]);
      }
    }
  }, [nodesLocal, autoLayout]);

  // MindMap生成中のデータ取得
  useEffect(() => {
    if (status !== 3) return;
    const timer = setInterval(() => {
      getMindMap();
    }, 5000);
    return () => clearInterval(timer);
  }, [status, getMindMap]);

  // MindMapデータの取得
  useEffect(() => {
    const fetchMindMap = async () => {
      const data = await getMindMap();
      if (data) {
        unstable_batchedUpdates(() => {
          setTitle(data.title);
          setStatus(data.status);
          if (data.nodes.length === 0) {
            const defaultNode = {
              id: "1",
              type: "custom",
              position: { x: 0, y: 0 },
              data: {
                label: "Hello, World!",
                color: "",
                links: [],
                images: [],
              },
              width: 200,
              height: 100,
              ...nodeDefaults,
            };
            setContextNodes([defaultNode]);
          } else {
            setContextNodes(data.nodes);
          }
          setContextEdges(data.edges);
          setEdgeType(data.edges[0]?.type || "default");
          setAnimation(data.edges[0]?.animated || false);
        });
      }
    };
    fetchMindMap();
  }, [
    mindMapUuid,
    setTitle,
    setStatus,
    setContextNodes,
    setContextEdges,
    setEdgeType,
    setAnimation,
    nodeDefaults,
    getMindMap,
  ]);

  // ノードの変更を管理（ドラッグ中はローカル状態を即時更新する）
  const onNodesChange = useCallback(
    (changes: NodeChange[]) => {
      const updatedNodes = applyNodeChanges(changes, nodesLocal);
      setNodesLocal(updatedNodes);
    },
    [nodesLocal, setContextNodes]
  );

  // ドラッグ終了時の処理
  const onNodeDragStop = useCallback(() => {
    setContextNodes(nodesLocal);
    setContextEdges(edgesLocal);
  }, [nodesLocal, edgesLocal, setContextNodes, setContextEdges]);

  const handleSelectionChange = (nodes) => {
    setSelectedNode(nodes.nodes[0]?.id);
  };

  return (
    <div ref={reactFlowWrapper} style={{ width: "100%", height: "100%" }}>
      <ReactFlow
        id="reactflow"
        nodes={nodesLocal}
        nodeTypes={nodeTypes}
        edges={edgesLocal}
        onNodesChange={onNodesChange}
        onNodeDragStop={onNodeDragStop}
        onNodesDelete={onNodesDelete}
        onEdgeUpdate={onEdgeUpdate}
        onEdgeUpdateEnd={(event, edge) => {
          onEdgeUpdateEnd(event as MouseEvent, edge);
          if (edgeUpdateSuccessful.current) {
            unstable_batchedUpdates(() => {
              setContextNodes(contextNodes);
              setContextEdges(contextEdges);
            });
          }
        }}
        onEdgeUpdateStart={onEdgeUpdateStart}
        onSelectionChange={handleSelectionChange}
        onConnect={onConnect}
        onInit={onLoad}
        fitView
        maxZoom={1.5}
        minZoom={0.1}
        snapToGrid
        snapGrid={[snapGrid[0], snapGrid[1]]}
        proOptions={{ hideAttribution: true }}
        style={{ width: "100%", height: "100%" }}
      >
        <Background />
        <Controls />
      </ReactFlow>
    </div>
  );
};

export default MindMap;
