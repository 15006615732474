const junoStyle = {
  title: "スタイル",
  weight: "スタイルの強さ",
  dalle3: {
    vivid: "鮮やか（デフォルト）",
    natural: "ナチュラル",
  },
  ideogram: {
    general: "一般",
    realistic: "リアル",
    design: "デザイン",
    anime: "アニメ",
    render3d: "3Dレンダリング",
  },
  fluxJuno: {
    general: "一般",
    anime: "アニメ v1.0",
    animePro: "アニメ PRO v1.0",
    japanese: "日本人ポートレート v1.0",
    japanesePro: "日本人ポートレート PRO v1.0",
  },
  recraft: {
    any: "一般",
    realisticImage: "フォトリアル",
    realisticImageBW: "フォトリアル - モノクロ写真",
    realisticImageHardFlash: "フォトリアル - 強いフラッシュの写真",
    realisticImageHDR: "フォトリアル - HDR写真",
    realisticImageNaturalLight: "フォトリアル - 自然光の写真",
    realisticImageStudioPortrait: "フォトリアル - スタジオポートレート写真",
    realisticImageEnterprise: "フォトリアル - ビジネス用写真",
    realisticImageMotionBlur: "フォトリアル - 動きのある写真",
    realisticImageEveningLight: "フォトリアル - 夜の光",
    realisticImageFadedNostalgia: "フォトリアル - 色褪せた懐かしさ",
    realisticImageForestLife: "フォトリアル - フォレストライフ",
    realisticImageMysticNaturalism: "フォトリアル - ミスティックナチュラリズム",
    realisticImageNaturalTones: "フォトリアル - 自然なトーン",
    realisticImageOrganicCalm: "フォトリアル - オーガニックな穏やかさ",
    realisticImageRealLifeGlow: "フォトリアル - 現実の輝き",
    realisticImageRetroRealism: "フォトリアル - レトロリアリズム",
    realisticImageRetroSnapshot: "フォトリアル - レトロスナップ",
    realisticImageUrbanDrama: "フォトリアル - 都市のドラマ",
    realisticImageVillageRealism: "フォトリアル - ヴィレッジリアリズム",
    realisticImageWarmFolk: "フォトリアル - 暖かいフォーク風",
    digitalIllustration: "デジタルイラスト",
    digitalIllustrationPixelArt: "デジタルイラスト - ピクセルアート風",
    digitalIllustrationHandDrawn: "デジタルイラスト - 手描き風",
    digitalIllustrationGrain: "デジタルイラスト - グレイン加工",
    digitalIllustrationGrain20: "デジタルイラスト - グレイン加工 2.0",
    digitalIllustrationInfantileSketch: "デジタルイラスト - 子供のスケッチ風",
    digitalIllustration2DArtPoster: "デジタルイラスト - 2Dポスター風アート",
    digitalIllustration2DArtPoster2: "デジタルイラスト - 2Dポスター風アート2",
    digitalIllustrationHandmade3D: "デジタルイラスト - 粘土風3Dアート",
    digitalIllustrationHandDrawnOutline: "デジタルイラスト - 手描きのアウトラインアート",
    digitalIllustrationEngravingColor: "デジタルイラスト - 彫刻風カラー",
    digitalIllustrationAntiquarian: "デジタルイラスト - アンティーク調",
    digitalIllustrationBoldFantasy: "デジタルイラスト - 大胆なファンタジー",
    digitalIllustrationChildBook: "デジタルイラスト - 子供向け絵本風",
    digitalIllustrationChildBooks: "デジタルイラスト - 子供向け絵本風(複数)",
    digitalIllustrationCover: "デジタルイラスト - 表紙風",
    digitalIllustrationCrosshatch: "デジタルイラスト - クロスハッチング",
    digitalIllustrationDigitalEngraving: "デジタルイラスト - デジタル彫刻",
    digitalIllustrationExpressionism: "デジタルイラスト - エクスプレッショニズム",
    digitalIllustrationFreehandDetails: "デジタルイラスト - フリーハンドの詳細",
    digitalIllustrationGraphicIntensity: "デジタルイラスト - グラフィックインテンシティ",
    digitalIllustrationHardComics: "デジタルイラスト - ハードコミックス",
    digitalIllustrationLongShadow: "デジタルイラスト - 長い影",
    digitalIllustrationModernFolk: "デジタルイラスト - モダンフォーク風",
    digitalIllustrationMulticolor: "デジタルイラスト - マルチカラー",
    digitalIllustrationNeonCalm: "デジタルイラスト - ネオンの穏やかさ",
    digitalIllustrationNoir: "デジタルイラスト - ノワール",
    digitalIllustrationNostalgicPastel: "デジタルイラスト - 懐かしいパステル",
    digitalIllustrationOutlineDetails: "デジタルイラスト - アウトラインの詳細",
    digitalIllustrationPastelGradient: "デジタルイラスト - パステルグラデーション",
    digitalIllustrationPastelSketch: "デジタルイラスト - パステルスケッチ",
    digitalIllustrationPopArt: "デジタルイラスト - ポップアート",
    digitalIllustrationPopRenaissance: "デジタルイラスト - ポップルネッサンス",
    digitalIllustrationStreetArt: "デジタルイラスト - ストリートアート",
    digitalIllustrationTabletSketch: "デジタルイラスト - タブレットスケッチ",
    digitalIllustrationUrbanGlow: "デジタルイラスト - 都会の輝き",
    digitalIllustrationUrbanSketching: "デジタルイラスト - アーバンスケッチ",
    digitalIllustrationVanillaDreams: "デジタルイラスト - バニラドリーム",
    digitalIllustrationYoungAdultBook: "デジタルイラスト - 青少年絵本",
    digitalIllustrationYoungAdultBook2: "デジタルイラスト - 青少年絵本2",
    vectorIllustration: "ベクターイラスト（.SVG）",
    vectorIllustrationBoldStroke: "ベクターイラスト - 太いストローク（.SVG）",
    vectorIllustrationChemistry: "ベクターイラスト - 化学的要素（.SVG）",
    vectorIllustrationColoredStencil: "ベクターイラスト - カラー型紙風（.SVG）",
    vectorIllustrationContourPopArt: "ベクターイラスト - 輪郭ポップアート（.SVG）",
    vectorIllustrationCosmics: "ベクターイラスト - コズミクス（.SVG）",
    vectorIllustrationCutout: "ベクターイラスト - カットアウト（.SVG）",
    vectorIllustrationDepressive: "ベクターイラスト - ディプレッシヴ（.SVG）",
    vectorIllustrationEditorial: "ベクターイラスト - エディトリアルデザイン（.SVG）",
    vectorIllustrationEmotionalFlat: "ベクターイラスト - 感情的フラットデザイン（.SVG）",
    vectorIllustrationInfographical: "ベクターイラスト - インフォグラフィック風（.SVG）",
    vectorIllustrationMarkerOutline: "ベクターイラスト - マーカーアウトライン（.SVG）",
    vectorIllustrationMosaic: "ベクターイラスト - モザイク風（.SVG）",
    vectorIllustrationNaivector: "ベクターイラスト - ナイーブ風（.SVG）",
    vectorIllustrationRoundishFlat: "ベクターイラスト - 丸みのあるフラットデザイン（.SVG）",
    vectorIllustrationSegmentedColors: "ベクターイラスト - セグメントカラー（.SVG）",
    vectorIllustrationSharpContrast: "ベクターイラスト - シャープコントラスト（.SVG）",
    vectorIllustrationThin: "ベクターイラスト - シンプル線画（.SVG）",
    vectorIllustrationVectorPhoto: "ベクターイラスト - ベクターフォト（.SVG）",
    vectorIllustrationVividShapes: "ベクターイラスト - 鮮やかな形状（.SVG）",
    vectorIllustrationEngraving: "ベクターイラスト - 彫刻風（.SVG）",
    vectorIllustrationLineArt: "ベクターイラスト - シンプルラインアート（.SVG）",
    vectorIllustrationLineCircuit: "ベクターイラスト - 色の塊とライン（.SVG）",
    vectorIllustrationLinocut: "ベクターイラスト - 版画風（.SVG）",
  },
};
export default junoStyle;
