import CreateIcon from "@mui/icons-material/Create";
import FolderIcon from "@mui/icons-material/Folder";
import { Box, useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import { ColumnBox, RowBox } from "../../../utils/styledBox";
import ButtonAI from "./ButtonAI";

const WritingAI = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useCustomNavigate();

  return (
    <Box sx={{ width: "100%" }}>
      <ColumnBox>
        {/* WritingAI */}
        <RowBox sx={{ height: 48 }}>
          <Typography
            variant={"h6"}
            component={"h3"}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <CreateIcon sx={{ color: theme.palette.secondary.main, mr: 2 }} />
            {t("dashboard.tenak.writingAI")}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Tooltip title={t("dashboard.library")} placement="left">
            <IconButton onClick={() => navigate("/notes-nebula/files")} sx={{ color: theme.palette.primary.main }}>
              <FolderIcon />
            </IconButton>
          </Tooltip>
        </RowBox>
        <ButtonAI
          title={t("dashboard.tenak.writingAI")}
          icon={<CreateIcon />}
          path={"/writing-ai/article"}
          image={"/images/dashBoard/writing.jpg"}
        />
      </ColumnBox>
    </Box>
  );
};
export default WritingAI;
