import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import FolderIcon from "@mui/icons-material/Folder";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { GradationButton } from "../../../utils/gradationButton";

interface GenerateButtonProps {
  processing: boolean;
  disabled: boolean;
  onGenerate: () => void;
  onLibraryClick: () => void;
}

export const GenerateButton: React.FC<GenerateButtonProps> = ({ processing, disabled, onGenerate, onLibraryClick }) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" justifyContent="space-between">
      <Button disableElevation variant="text" onClick={onLibraryClick}>
        <FolderIcon sx={{ mr: 1 }} />
        <Typography variant="button">{t("voice.library")}</Typography>
      </Button>
      <GradationButton variant="contained" onClick={onGenerate} disabled={processing || disabled} sx={{ width: 300 }}>
        {!processing && (
          <>
            <AutoAwesomeIcon sx={{ mr: 1 }} fontSize="small" />
            {t("voice.submit")}
          </>
        )}
        {processing && (
          <>
            <Typography variant="button">{"Generating..."}</Typography>
            <CircularProgress size={20} sx={{ ml: 1 }} />
          </>
        )}
      </GradationButton>
    </Box>
  );
};
