export interface Link {
  url: string;
  title: string;
}

export interface NodeColor {
  name: string;
  lightColor: string;
  darkColor: string;
}

export const colorList: NodeColor[] = [
  { name: "red", lightColor: "#ffafaf", darkColor: "#eb3434" },
  { name: "blue", lightColor: "#add8e6", darkColor: "#344feb" },
  { name: "green", lightColor: "#77dd77", darkColor: "#228b22" },
  { name: "yellow", lightColor: "#ffffb3", darkColor: "#9e9e00" },
  { name: "orange", lightColor: "#ffdab9", darkColor: "#eb7434" },
  { name: "purple", lightColor: "#b19cd9", darkColor: "#6a0dad" },
];
