import AddIcon from "@mui/icons-material/Add";
import ArticleIcon from "@mui/icons-material/Article";
import CloseIcon from "@mui/icons-material/Close";
import ImageIcon from "@mui/icons-material/Image";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { alpha } from "@mui/material/styles";
import React, { ChangeEvent, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Attachment } from "../../../types/chatTypes";

interface AttachmentInputProps {
  mode: string;
  attachments: Attachment[];
  onAttachmentAdd: (files: FileList | null, type: "file" | "image") => void;
  onAttachmentRemove: (index: number) => void;
  isPro: boolean;
}

export const AttachmentInput: React.FC<AttachmentInputProps> = ({
  mode,
  attachments,
  onAttachmentAdd,
  onAttachmentRemove,
  isPro,
}) => {
  const { t } = useTranslation();
  const [attachmentAnchorEl, setAttachmentAnchorEl] = useState<null | HTMLElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const imageInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>, type: "file" | "image") => {
    onAttachmentAdd(e.target.files, type);
    setAttachmentAnchorEl(null);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <input type="file" ref={fileInputRef} style={{ display: "none" }} onChange={(e) => handleFileChange(e, "file")} />
      <input
        type="file"
        accept="image/*"
        multiple
        ref={imageInputRef}
        style={{ display: "none" }}
        onChange={(e) => handleFileChange(e, "image")}
      />
      <IconButton
        onClick={(e) => setAttachmentAnchorEl(e.currentTarget)}
        size="small"
        disabled={mode !== "llm" || isPro}
      >
        <AddIcon />
      </IconButton>
      <Menu
        anchorEl={attachmentAnchorEl}
        open={Boolean(attachmentAnchorEl)}
        onClose={() => {
          setAttachmentAnchorEl(null);
          if (imageInputRef.current) {
            imageInputRef.current.value = "";
          }
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <MenuItem
          onClick={() => {
            if (mode === "llm") {
              imageInputRef.current?.click();
            }
            setAttachmentAnchorEl(null);
          }}
          disabled={mode !== "llm"}
        >
          <ImageIcon sx={{ mr: 1 }} />
          {t("browsing.attachImage")}
        </MenuItem>
      </Menu>

      {attachments.map((attachment, index) => (
        <Box
          key={index}
          sx={{
            position: "relative",
            width: 34,
            height: 34,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {attachment.type === "image" ? (
            <img
              src={attachment.url}
              alt=""
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "4px",
                border: "1px solid",
                borderColor: "divider",
                opacity: mode === "llm" ? 1 : 0.5,
                filter: mode === "llm" ? "none" : "grayscale(100%)",
              }}
            />
          ) : (
            <ArticleIcon />
          )}
          <IconButton
            size="small"
            sx={{
              position: "absolute",
              top: -6,
              right: -6,
              backgroundColor: (theme) => alpha(theme.palette.background.default, 0.7),
              width: 18,
              height: 18,
              "&:hover": {
                backgroundColor: (theme) => alpha(theme.palette.background.default, 0.9),
              },
              transition: "background-color 0.2s",
            }}
            onClick={() => onAttachmentRemove(index)}
          >
            <CloseIcon fontSize="small" sx={{ width: 12, height: 12 }} />
          </IconButton>
        </Box>
      ))}
    </Box>
  );
};
