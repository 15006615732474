import { Fade, FormControlLabel, Grid, Paper, Switch, Tooltip, Typography, useTheme } from "@mui/material";
import { alpha, lighten } from "@mui/material/styles";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setInputQuery } from "../../../redux/slices/browsingSlice";
import { RootState } from "../../../redux/store";
import { Attachment, InputBoxProps } from "../../../types/chatTypes";
import { streamStop } from "../../../utils/stream_stop";
import { ColumnCenteredBox } from "../../../utils/styledBox";
import { scrollToBottom } from "../../../utils/utils";
import { AttachmentInput } from "./AttachmentInput";
import { MessageInput } from "./MessageInput";
import { Toolbar } from "./Toolbar";
import { VoiceInput } from "./VoiceInput";

function InputBox(props: InputBoxProps) {
  const { sendMessage, historyData, stopStreamController, historyRef, onModeChange, mode, isPro, setIsPro } = props;
  const dispatch = useDispatch();
  const { chatUuid } = useParams();
  const processing = useSelector((state: RootState) => state.browsing.processing[chatUuid!]);
  const inputQuery = useSelector((state: RootState) => state.browsing.inputQuery[chatUuid!] || "");
  const [inputError, setInputError] = useState(false);
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [isRecording, setIsRecording] = useState(false);
  const { t } = useTranslation();

  // 最下部のフッターと重ならないようにリッスン
  const [atBottom, setAtBottom] = useState(true);
  const handleScroll = () => {
    const isAtBottom =
      document.documentElement.scrollHeight - (document.documentElement.scrollTop + window.innerHeight) < 100;
    setAtBottom(isAtBottom);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [chatUuid]);

  // chatResponseが変更されるたびに実行されるuseEffect
  useEffect(() => {
    if (atBottom) {
      scrollToBottom(200);
    }
  }, [document.documentElement.scrollHeight]);

  // Chatが変更されたら最下部にスクロール
  useEffect(() => {
    scrollToBottom(200);
  }, [chatUuid]);

  // Fixed Box のメッセージ送信用の横幅を取得
  const elementRef = useRef<HTMLDivElement | null>(null);
  const [width, setWidth] = useState<number | undefined>(undefined);

  useEffect(() => {
    const updateWidth = () => {
      if (historyRef?.current) {
        setWidth(historyRef.current.offsetWidth);
      }
    };

    const debouncedUpdateWidth = debounce(updateWidth, 0);

    updateWidth();

    const resizeObserver = new ResizeObserver(debouncedUpdateWidth);
    if (historyRef?.current) {
      resizeObserver.observe(historyRef.current);
    }

    return () => {
      if (historyRef?.current) {
        resizeObserver.unobserve(historyRef.current);
      }
    };
  }, [historyRef]);

  // ユーザーの入力バリデーション
  const handleInputQueryChange = (value: string) => {
    dispatch(setInputQuery({ chatUuid: chatUuid!, inputQuery: value }));
    if (value.length > 6000) {
      setInputError(true);
    } else {
      setInputError(false);
    }
  };

  const handleAttachmentAdd = useCallback(
    (files: FileList | null, type: "file" | "image") => {
      if (!files) return;

      const validFiles = Array.from(files).filter((file) => {
        if (file.size > 20 * 1024 * 1024) {
          alert(`${file.name}は20MB以上のため、アップロードできません。`);
          return false;
        }
        return true;
      });

      if (attachments.length + validFiles.length > 5) {
        alert("画像は最大5枚までアップロードできます。");
        return;
      }

      const uploadedFiles: Attachment[] = validFiles.map((file) => ({
        type,
        url: URL.createObjectURL(file),
        file,
      }));

      setAttachments((prev) => [...prev, ...uploadedFiles]);
    },
    [attachments.length]
  );

  const handleAttachmentRemove = useCallback((index: number) => {
    setAttachments((prev) => {
      const removedAttachment = prev[index];
      if (removedAttachment) {
        URL.revokeObjectURL(removedAttachment.url);
      }
      return prev.filter((_, i) => i !== index);
    });
  }, []);

  // コンポーネントのアンマウント時にURLを解放
  useEffect(() => {
    return () => {
      attachments.forEach((attachment) => {
        URL.revokeObjectURL(attachment.url);
      });
    };
  }, [attachments]);

  const send = () => {
    const images = mode === "llm" ? attachments.filter((a) => a.type === "image").map((a) => a.file) : [];
    const files = attachments.filter((a) => a.type === "file").map((a) => a.file);
    sendMessage(inputQuery, images, files);
    dispatch(setInputQuery({ chatUuid: chatUuid!, inputQuery: "" }));
    setAttachments([]);
  };

  const handleStreamStop = () => {
    stopStreamController();
    streamStop(chatUuid!);
  };

  const historyExists = historyData.length > 0;
  const bgcolor = () => {
    if (!historyExists) return "transparent";
    return theme.palette.mode === "dark"
      ? alpha(lighten(theme.palette.background.paper, 0.08), 0.8)
      : atBottom
        ? "white"
        : "rgba(255, 255, 255, 0.5)";
  };

  const theme = useTheme();

  const [disablePro, setDisablePro] = useState(false);

  useEffect(() => {
    const hasAttachments = attachments.length > 0;
    const hasHistoryImages = historyData.some((item) => item.images && item.images.length > 0);
    const isResearchMode = mode === "research";

    // リサーチモードの場合は強制的にProモードをONにし、それ以外の場合は添付ファイルの有無に応じて設定
    if (isResearchMode) {
      setIsPro(true);
    } else if (hasAttachments || hasHistoryImages) {
      setIsPro(false);
    }

    // リサーチモード、または添付ファイル/履歴画像がある場合はProモードの切り替えを無効化
    setDisablePro(isResearchMode || hasAttachments || hasHistoryImages);
  }, [attachments, historyData, mode]);

  return (
    <ColumnCenteredBox sx={{ width: "inherit" }}>
      <Fade in={true} key={1} timeout={1500}>
        <Paper
          elevation={0}
          ref={elementRef}
          sx={{
            width: width,
            mb: 2,
            borderRadius: 1,
            position: "fixed",
            bottom: 0,
            backgroundColor: bgcolor,
            backdropFilter: "blur(5px)",
            zIndex: 100,
            px: { xs: 2, sm: 3 },
            pt: 2,
            pb: { xs: 1, sm: 1.5 },
            transition: "all 0.3s ease",
          }}
        >
          <MessageInput
            value={inputQuery}
            onChange={handleInputQueryChange}
            onSend={send}
            onStop={handleStreamStop}
            processing={processing}
            error={inputError}
          />
          <Grid container spacing={1} sx={{}}>
            <Grid item xs={1.5}>
              <AttachmentInput
                mode={mode}
                attachments={attachments}
                onAttachmentAdd={handleAttachmentAdd}
                onAttachmentRemove={handleAttachmentRemove}
                isPro={isPro}
              />
            </Grid>
            <Grid item xs={8.5}>
              <Toolbar mode={mode} onModeChange={onModeChange} historyData={historyData} />
            </Grid>
            <Grid item xs={2} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
              <Tooltip title={disablePro ? t("browsing.proDisableTooltip") : t("browsing.proTooltip")}>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={isPro}
                      onChange={(e) => setIsPro(e.target.checked)}
                      disabled={disablePro}
                    />
                  }
                  label={
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "bold", color: isPro ? "text.primary" : "text.disabled", whiteSpace: "nowrap" }}
                    >
                      {t("browsing.pro")}
                    </Typography>
                  }
                />
              </Tooltip>
              <VoiceInput
                isRecording={isRecording}
                onVoiceInput={() => setIsRecording(!isRecording)}
                onTranscriptChange={handleInputQueryChange}
                initialText={inputQuery}
              />
            </Grid>
          </Grid>
        </Paper>
      </Fade>
    </ColumnCenteredBox>
  );
}

export default InputBox;
