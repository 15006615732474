import { Check as CheckIcon, Clear as ClearIcon, Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import LaunchIcon from "@mui/icons-material/Launch";
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { RowCenteredBox } from "../../../../utils/styledBox";
import { Link } from "./types";

interface NodeLinksProps {
  links: Link[];
  onUpdateLinks: (links: Link[]) => void;
  selected: boolean;
}

export const NodeLinks: React.FC<NodeLinksProps> = ({ links, onUpdateLinks, selected }) => {
  const [editingLinkIndex, setEditingLinkIndex] = useState<number | null>(null);
  const [editingLink, setEditingLink] = useState<Link | null>(null);

  const handleDeleteLink = (event: React.MouseEvent, index: number) => {
    event?.stopPropagation();
    event?.preventDefault();

    // イベントの即時実行を保証
    if (event.isPropagationStopped() && event.isDefaultPrevented()) {
      const updatedLinks = links.filter((_, i) => i !== index);
      onUpdateLinks(updatedLinks);
    }
  };

  const handleEditLink = (event: React.MouseEvent, index: number) => {
    event?.preventDefault();
    event?.stopPropagation();
    setEditingLinkIndex(index);
    setEditingLink({ ...links[index] });
  };

  const handleUpdateLink = (event: React.MouseEvent, index: number, updatedLink: Link) => {
    event?.stopPropagation();
    event?.preventDefault();
    const updatedLinks = [...links];
    updatedLinks[index] = updatedLink;
    onUpdateLinks(updatedLinks);
    setEditingLinkIndex(null);
    setEditingLink(null);
  };

  const handleCancelEditLink = (event: React.MouseEvent) => {
    event?.stopPropagation();
    event?.preventDefault();
    setEditingLinkIndex(null);
    setEditingLink(null);
  };

  if (links.length === 0) return null;

  return (
    <List dense sx={{ p: 0, pt: 1 }}>
      {links.map((link, index) => (
        <ListItem key={`link-${index}`} disablePadding>
          {editingLinkIndex === index && selected ? (
            <RowCenteredBox px={2} pt={2} pb={1}>
              <TextField
                label="Title"
                value={editingLink?.title || ""}
                onChange={(e) => setEditingLink({ ...editingLink!, title: e.target.value })}
                size="small"
                sx={{ mr: 1, flexGrow: 1 }}
              />
              <TextField
                label="URL"
                value={editingLink?.url || ""}
                onChange={(e) => setEditingLink({ ...editingLink!, url: e.target.value })}
                size="small"
                sx={{ mr: 1, flexGrow: 1 }}
              />
              <IconButton onClick={(e) => handleUpdateLink(e, index, editingLink!)} size="small">
                <CheckIcon fontSize="small" />
              </IconButton>
              <IconButton onClick={handleCancelEditLink} size="small">
                <ClearIcon fontSize="small" />
              </IconButton>
            </RowCenteredBox>
          ) : (
            <ListItemButton component="a" href={link.url} target="_blank" sx={{ py: 0 }}>
              <ListItemIcon sx={{ mr: 2, minWidth: 0 }}>
                <LaunchIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography color="primary" variant="button" gutterBottom>
                    {link.title}
                  </Typography>
                }
              />
              {selected && (
                <ListItemSecondaryAction sx={{ display: "flex", gap: 0.5 }}>
                  <IconButton edge="end" aria-label="edit" onClick={(e) => handleEditLink(e, index)} size="small">
                    <EditIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={(e) => handleDeleteLink(e, index)}
                    size="small"
                    sx={{ pointerEvents: "auto" }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItemButton>
          )}
        </ListItem>
      ))}
    </List>
  );
};
