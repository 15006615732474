import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ProviderCard = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  borderRadius: theme.shape.borderRadius,
  transition: "all 0.3s ease",
  position: "relative",
  overflow: "hidden",
  width: "100%",
  "&:hover": {
    opacity: 0.8,
  },
  "&.selected": {
    outline: `2px solid ${theme.palette.primary.main}`,
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      // backgroundColor: "rgba(0, 0, 0, 0.1)",
      borderRadius: 4,
    },
  },
}));

export const ProviderImage = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  objectPosition: "center",
  borderRadius: 4,
});

export const CheckIcon = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 4,
  left: 4,
  color: theme.palette.primary.main,
  // backgroundColor: "white",
  borderRadius: "50%",
  padding: 2,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
