import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import { Fade, Grid, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Cookies from "js-cookie";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Meta from "../../../components/common/Meta";
import { useAlert } from "../../../context/AlertContext";
import { useVideoScriptContext } from "../../../context/VideoScriptContext";
import { useCheckCredit } from "../../../hooks/useCreditCheck";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import { setCreditTrigger } from "../../../redux/slices/triggerSlice";
import { GradationButton } from "../../../utils/gradationButton";
import { RowBox } from "../../../utils/styledBox";
import { validateUrl, validateYoutubeUrl } from "../../../utils/validation";
import AdditionalText from "./components/AdditionalText";
import DesiredVideo from "./components/DesiredVideo";
import SNS from "./components/SNS";
import Source from "./components/Source";
import Target from "./components/Target";
import Tone from "./components/Tone";
import VideoLength from "./components/VideoLength";

export default function VideoScriptGenerator() {
  const { t } = useTranslation();
  const { checkCredit } = useCheckCredit();
  const [processing, setProcessing] = React.useState(false);
  const navigate = useCustomNavigate();
  const { setAlert } = useAlert();
  const dispatch = useDispatch();
  const context = useVideoScriptContext();
  const location = useLocation();

  const create = async () => {
    try {
      if (!(await checkCredit())) return;
      setProcessing(true);
      if (!validates()) {
        setProcessing(false);
        return;
      }
      const post_url = "/api/v1/video-script/";
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });
      const data = {
        sns: context.sns,
        videoLength: context.videoLength,
        content: context.content,
        srcType: context.srcType,
        target: context.target,
        tone: context.tone,
        desiredVideo: context.desiredVideo,
        additionalText: context.additionalText,
      };
      const response = await fetch(post_url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      });
      const responseData = await response.json();
      if (responseData.success) {
        setProcessing(false);
        dispatch(setCreditTrigger(true));
        const currentPath = location.pathname.toLowerCase();
        setAlert("success", t("common.complete"));
        if (currentPath.includes("writing-ai/video-script")) {
          navigate("/notes-nebula/files/" + responseData.data.file_uuid);
        }
      } else {
        setAlert("error", responseData.data.message);
        setProcessing(false);
      }
    } catch (e) {
      setProcessing(false);
    } finally {
      setProcessing(false);
    }
  };

  // 入力チェック
  const validates = () => {
    if (context.sns.length === 0) {
      setAlert("error", t("videoScript.error.invalidSns"));
      return false;
    }
    if (context.srcType === "youtube" && !validateYoutubeUrl(context.content)) {
      setAlert("error", t("videoScript.error.invalidYoutubeUrl"));
      return false;
    }

    if (context.srcType === "url" && !validateUrl(context.content)) {
      setAlert("error", t("videoScript.error.invalidUrl"));
      return false;
    }

    if (context.srcType === "text" && context.content.length === 0) {
      setAlert("error", t("videoScript.error.invalidText"));
      return false;
    }

    if (context.content.trim().length === 0) {
      setAlert("error", t("videoScript.error.invalidHeadings"));
      return false;
    }
    return true;
  };

  return (
    <>
      <Meta title={t("videoScript.title")} meta={[{ name: "robots", content: "noindex, nofollow" }]} />
      <Fade in={true}>
        <div>
          <Box sx={{ height: "100%" }}>
            <Box sx={{ height: "100%", display: "flex", flexDirection: "column", gap: 4, mb: 4 }}>
              <RowBox>
                <FlashOnIcon sx={{ mr: 1 }} color={"secondary"} />
                <Typography variant={"body2"} gutterBottom>
                  {t("videoScript.description")}
                </Typography>
              </RowBox>
              <Grid container spacing={4} mb={2}>
                <Grid item xs={12} md={6}>
                  <Stack spacing={4}>
                    <Target />
                    <SNS />
                    <Tone />
                    <VideoLength />
                    <AdditionalText />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={4}>
                    <DesiredVideo />
                    <Source />
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            <Grid item xs={12}>
              <Box display={"flex"} justifyContent={"flex-end"} gap={1}>
                <GradationButton variant="contained" onClick={create} disabled={processing}>
                  {!processing && (
                    <>
                      <AutoAwesomeIcon sx={{ mr: 1 }} fontSize={"small"} />
                      {t("videoScript.submit")}
                    </>
                  )}
                  {processing && (
                    <>
                      <Typography variant={"button"}>{"Generating..."}</Typography>
                      <CircularProgress size={20} sx={{ ml: 1, color: "white" }} />
                    </>
                  )}
                </GradationButton>
              </Box>
            </Grid>
          </Box>
        </div>
      </Fade>
    </>
  );
}
