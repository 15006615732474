import React from "react";
// MindMapEditor.tsx
import FolderIcon from "@mui/icons-material/Folder";
import SaveIcon from "@mui/icons-material/Save";
import {
  Box,
  Button,
  CircularProgress,
  Fade,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import "reactflow/dist/style.css";
import PreviewImageModal from "../../components/common/ImageModal";
import Meta from "../../components/common/Meta";
import { DrawerHeader } from "../../components/layout/Drawer/DrawerMain";
import { useMindMap } from "../../context/MindmapContext";
import { useCustomNavigate } from "../../hooks/useCustomNavigate";
import { uiHeight } from "../../utils/uiHeight";
import Generating from "../TextFile/components/Generating";
import DownloadFlow from "./components/DownloadFlow";
import ImageModal from "./components/ImageModal";
import LinkModal from "./components/LinkModal";
import MindMap from "./components/MindMap";
import MindmapMenu from "./components/MindmapMenu";
import Toolbar from "./components/Toolbar";

import { Link, NodeColor } from "./components/CustomNode/types";

// EditorHeader コンポーネント: ヘッダーやツールバー部分を切り出してメモ化し、MindMapEditor の再レンダリング負荷を低減する。
const EditorHeader = React.memo(
  ({
    title,
    setTitle,
    handleSave,
    saving,
    navigate,
    t,
  }: {
    title: string;
    setTitle: (title: string) => void;
    handleSave: () => void;
    saving: boolean;
    navigate: (path: string) => void;
    t: (key: string) => string;
  }) => {
    return (
      <Box component="div">
        <DrawerHeader />
        <Box sx={{ maxWidth: "100%!important", mx: { xs: 0, md: 2 } }}>
          {/* ヘッダーやツールバーなどのコンポーネント */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              mb: 1,
              whiteSpace: "nowrap",
              gap: 1,
            }}
          >
            <TextField
              variant="standard"
              value={title}
              fullWidth
              onChange={(e) => setTitle(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography variant="subtitle1" sx={{ mr: 1 }} color="primary">
                      Title:
                    </Typography>
                  </InputAdornment>
                ),
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: { xs: "none", sm: "flex" },
                  alignItems: "center",
                }}
              >
                <Button
                  disableElevation
                  onClick={() => navigate("/library/mind-maps")}
                  variant="outlined"
                  sx={{ minWidth: "140px" }}
                  startIcon={<FolderIcon fontSize="small" />}
                >
                  <Typography variant="button">{t("mindmap.library")}</Typography>
                </Button>
                <Button
                  disableElevation
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                  disabled={saving}
                  sx={{ ml: 1, alignItems: "center", display: "flex" }}
                  startIcon={
                    saving ? <CircularProgress size={20} sx={{ color: "white" }} /> : <SaveIcon fontSize="small" />
                  }
                >
                  {t("common.save")}
                </Button>
              </Box>
              <MindmapMenu />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
);

const MindMapEditor = () => {
  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  const theme = useTheme();
  const { title, setTitle, status, handleSave, saving, nodes, setNodes, selectedNode } = useMindMap();

  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [openImagePreview, setOpenImagePreview] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(null);

  const selectedNodeData = useMemo(() => nodes.find((node) => node.id === selectedNode)?.data, [nodes, selectedNode]);
  const links = selectedNodeData?.url || [];
  const images = selectedNodeData?.images || [];

  const handleUpdateLinks = useCallback(
    (newLinks: Link[]) => {
      if (!selectedNode) return;
      setNodes((prevNodes) =>
        prevNodes.map((node) => (node.id === selectedNode ? { ...node, data: { ...node.data, url: newLinks } } : node))
      );
    },
    [selectedNode, setNodes]
  );

  const handleUpdateImages = useCallback(
    (newImages: string[]) => {
      if (!selectedNode) return;
      setNodes((prevNodes) =>
        prevNodes.map((node) =>
          node.id === selectedNode ? { ...node, data: { ...node.data, images: newImages } } : node
        )
      );
    },
    [selectedNode, setNodes]
  );

  const handleImagePreview = useCallback((index: number) => {
    setSelectedImageIndex(index);
    setOpenImagePreview(true);
  }, []);

  return (
    <>
      <Meta title={t("mindmap.editor")} meta={[{ name: "robots", content: "noindex, nofollow" }]} />
      <DownloadFlow />
      <Fade in={true} timeout={1000}>
        <Box>
          {status !== 3 ? (
            <>
              <EditorHeader
                title={title}
                setTitle={setTitle}
                handleSave={handleSave}
                saving={saving}
                navigate={navigate}
                t={t}
              />
              <Paper
                elevation={theme.palette.mode === "dark" ? 1 : 0}
                sx={{
                  width: "100%",
                  height: { xs: "100vh", md: `calc(${uiHeight()} - 64px)`, position: "relative" },
                  background: "background.paper",
                }}
              >
                <Box sx={{ position: "relative" }}>
                  <Toolbar
                    onOpenLinkModal={() => setIsLinkModalOpen(true)}
                    onOpenImageModal={() => setIsImageModalOpen(true)}
                    onColorChange={(newColor: NodeColor) => {
                      if (!selectedNode) return;
                      setNodes((prevNodes) =>
                        prevNodes.map((node) =>
                          node.id === selectedNode
                            ? {
                                ...node,
                                data: {
                                  ...node.data,
                                  color: newColor.name,
                                  lightColor: newColor.lightColor,
                                  darkColor: newColor.darkColor,
                                },
                              }
                            : node
                        )
                      );
                    }}
                  />
                  <LinkModal
                    isLinkModalOpen={isLinkModalOpen}
                    setIsLinkModalOpen={setIsLinkModalOpen}
                    links={links}
                    setLinks={handleUpdateLinks}
                    data={{ url: links }}
                  />
                  <ImageModal
                    isImageModalOpen={isImageModalOpen}
                    setIsImageModalOpen={setIsImageModalOpen}
                    images={images}
                    setImages={handleUpdateImages}
                    data={{ images }}
                    onPreviewImage={handleImagePreview}
                  />
                  <PreviewImageModal
                    src={images[selectedImageIndex || 0]}
                    alt="image"
                    open={openImagePreview}
                    onClose={() => setOpenImagePreview(false)}
                  />
                </Box>
                <MindMap />
              </Paper>
            </>
          ) : (
            <Generating />
          )}
        </Box>
      </Fade>
    </>
  );
};

EditorHeader.displayName = "EditorHeader";
const MemoizedMindMapEditor = React.memo(MindMapEditor);
MemoizedMindMapEditor.displayName = "MindMapEditor";
export default MemoizedMindMapEditor;
