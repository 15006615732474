import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const theme = (darkMode: boolean) => {
  //   darkMode = true;
  let theme = createTheme({
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          option: {
            backgroundColor: darkMode ? "#0a0a0a" : "#fff",
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            fontSize: "0.95rem",
            h1: {
              fontSize: "1.6rem",
            },
            h2: {
              fontSize: "1.5rem",
            },
            h3: {
              fontSize: "1.4rem",
            },
            h4: {
              fontSize: "1.3rem",
            },
            h5: {
              fontSize: "1.2rem",
            },
            h6: {
              fontSize: "1.1rem",
            },
          },
        },
      },
    },
    palette: {
      mode: darkMode ? "dark" : "light",
      background: {
        default: darkMode ? "#0f0f0f" : "#f5f0e8",
        paper: darkMode ? "#121212" : "#fff",
        custom1: darkMode ? "#262626" : "#FFFAF5",
      },
      primary: {
        main: darkMode ? "#ac7ecf" : "#b06f6f",
        light: darkMode ? "#d6b1f2" : "#de8c8c",
        dark: darkMode ? "#795096" : "#805050",
      },
      secondary: {
        main: darkMode ? "#90caf9" : "#b08d6f",
        light: darkMode ? "#e3f2fd" : "#deb68c",
        dark: darkMode ? "#42a5f5" : "#7d694c",
      },
    },
    custom: {
      gradient: {
        main: "linear-gradient(150deg, rgba(150, 40, 200, 1), rgba(40, 200, 180, 1))",
        light: "linear-gradient(150deg, rgba(150, 40, 255, 1), rgba(40, 255, 180, 1))",
        dark: "linear-gradient(300deg, rgba(150, 40, 145, 1), rgba(40, 145, 180, 1))",
      },
    },
    typography: {
      fontFamily: "'Noto Sans JP', 'Roboto', sans-serif",
      h1: {
        fontWeight: 800,
      },
      h2: {
        fontWeight: 800,
      },
      h3: {
        fontWeight: 600,
      },
      h4: {
        fontWeight: 600,
      },
      h5: {
        fontWeight: 500,
      },
      h6: {
        fontWeight: 500,
      },
      body1: {
        fontWeight: 400,
      },
      body2: {
        fontWeight: 400,
      },
      subtitle1: {
        fontWeight: 600,
      },
      subtitle2: {
        fontWeight: 500,
      },
      button: {
        fontWeight: 500,
      },
      caption: {
        fontWeight: 400,
      },
      overline: {
        fontWeight: 400,
      },
    },
  });

  // CSS Variables for Quill SCSS
  document.documentElement.style.setProperty("--primary-color", theme.palette.primary.main);
  document.documentElement.style.setProperty("--text-color", theme.palette.text.primary);
  document.documentElement.style.setProperty("--background-color", theme.palette.background.paper);
  document.documentElement.style.setProperty("--border-color", theme.palette.divider);

  theme = responsiveFontSizes(theme);
  return theme;
};
export default theme;
export const drawerWidth = 340;
