import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Provider } from "../../types";
import { CheckIcon, ProviderCard, ProviderImage } from "./styles";

interface ProviderSelectorProps {
  provider: Provider;
  onProviderChange: (provider: Provider) => void;
}

export const ProviderSelector: React.FC<ProviderSelectorProps> = ({ provider, onProviderChange }) => {
  const { t } = useTranslation();

  const providers = [
    {
      id: "openai",
      name: t("voice.provider.openai"),
      image: "/images/top/companies/openai.png",
    },
    {
      id: "google",
      name: t("voice.provider.google"),
      image: "/images/top/companies/google.png",
    },
  ] as const;

  return (
    <Box>
      <Typography variant="body2" mb={1}>
        {t("voice.provider.title")}
      </Typography>

      <Box sx={{ display: "flex", gap: 2, justifyContent: "center" }}>
        {providers.map((item) => (
          <ProviderCard
            key={item.id}
            className={provider === item.id ? "selected" : ""}
            onClick={() => onProviderChange(item.id as Provider)}
          >
            <ProviderImage src={item.image} alt={item.name} />
            {provider === item.id && (
              <CheckIcon>
                <CheckCircleIcon fontSize="small" />
              </CheckIcon>
            )}
          </ProviderCard>
        ))}
      </Box>
    </Box>
  );
};
