const common = {
  save: "保存",
  add: "追加",
  edit: "編集",
  share: "共有",
  apply: "適用",
  delete: "削除",
  deleteSelected: "{{length}}件の選択したアイテムを削除",
  selected: "{{length}}件選択済み",
  releaseSelected: "選択を解除",
  bulkDelete: "一括削除",
  refresh: "更新",
  cancel: "キャンセル",
  download: "ダウンロード",
  close: "閉じる",
  or: "または",
  copy: "クリップボードにコピー",
  next: "Next",
  back: "Back",
  generatingMessage: "生成中... ページを離れても生成は続行されます。",
  complete: "生成が完了しました。ライブラリで確認してください。",
  play: "再生",
  stop: "停止",
  error: {
    somethingWentWrong: "何らかのエラーが発生しました。後ほど、もう一度実行してください。",
  },
  enable: "有効",
  disable: "無効",
  generate: "Tenak AI で生成",
  stopGenerating: "生成を停止",
  generating: "生成中...",
  pro: "有料プランに加入中の方のみ利用可能です。",
  footer: {
    pricing: "価格",
    terms: "利用規約",
    privacy: "プライバシーポリシー",
    contact: "お問い合わせ",
    notesNebula: "Notes Nebula",
    juno: "Studio Juno",
    TenakAI: "Tenak.ai ",
  },
  proPlanDialog: {
    title: "有料プランに加入する必要があります",
    description: "この機能は有料プランに加入しているユーザーのみ利用可能です。",
    cancel: "キャンセル",
    confirm: "プランを確認する",
  },
};
export default common;
