import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { IconButton, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { useParamsContext } from "../../../../../context/juno/ParamsContext";

const Seed = () => {
  const { t } = useTranslation();
  const { seed, setSeed, aiEngine } = useParamsContext();

  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={1}>
      <TextField
        sx={{ mt: 1, width: "100%" }}
        label={t("juno.input.seedSD")}
        size={"small"}
        variant={"outlined"}
        value={seed[aiEngine!]}
        onChange={(event) => {
          if (event.target.value.match(/^[0-9]+$/)) {
            setSeed({ ...seed, [aiEngine!]: Number(event.target.value) });
          }
        }}
      />
      <Box>
        <IconButton onClick={() => setSeed({ ...seed, [aiEngine!]: 0 })}>
          <RestartAltIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
export default Seed;
