export const aiEngineColor = (ai_engine) => {
  switch (ai_engine) {
    case "SDXL":
    case "SD3":
    case "SD35":
    case "SDUltra":
      return "#374579";
    case "FLUX":
    case "Flux1Pro":
    case "Flux11Pro":
    case "Flux11ProUltra":
    case "Flux11ProUltraRedux":
    case "Flux11ProRedux":
    case "Flux1ProCanny":
    case "Flux1ProDepth":
    case "Flux1ProFill":
      return "#703f86";
    case "FluxPuLID":
    case "Flux1Dev":
    case "Flux1Schnell":
    case "Flux1RealismLoRA":
    case "Flux1DevRedux":
    case "Flux1SchnellRedux":
    case "Flux1DevCannyLoRA":
    case "Flux1DevDepthLoRA":
    case "Flux1DevFill":
      return "#7050ac";
    case "FluxJuno":
    case "Flux1ProDepthJuno":
    case "Flux1ProCannyJuno":
    case "Flux11ProUltraJuno":
    case "Flux1ProJuno":
    case "Flux1ProFillJuno":
      return "#3b5f84";
    case "RecraftV3":
      return "#793a37";
    case "Ideogram2":
    case "Ideogram2Turbo":
    case "Ideogram2Edit":
    case "Ideogram2TurboEdit":
      return "#b0922f";
    case "KolorsVTO":
    case "LuminaV2":
    case "DallE3":
    case "Imagen3":
    case "Imagen3Fast":
      return "#37796f";
    case "AuraSR":
    case "ClarityUpscaler":
    case "CreativeUpscaler":
    case "RecraftClarityUpscaler":
      return "#795237";
    default:
      return "#181818";
  }
};
