import AddIcon from "@mui/icons-material/Add";
import { ButtonBase, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { useAlert } from "../../../../context/AlertContext";
import { useMindMap } from "../../../../context/MindmapContext";

interface AddNodeButtonProps {
  /** 選択中のノードID */
  selectedNode: string | null;
}

/**
 * 新しいノードを追加するボタンコンポーネント
 */
export const AddNodeButton: React.FC<AddNodeButtonProps> = ({ selectedNode }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { setAlert } = useAlert();
  const { nodes, setNodes, edges, setEdges, nodeDefaults, animation, edgeType } = useMindMap();

  const addNode = (id: string) => {
    const selected_node_position = nodes.find((node) => node.id === id)?.position;
    const childCount = edges.filter((edge) => edge.source === id).length;
    const newNode = {
      id: uuidv4(),
      type: "custom",
      position: {
        x: (selected_node_position?.x || 0) + 500,
        y: (selected_node_position?.y || 0) + childCount * 100,
      },
      data: { label: `NEW ${nodes.length + 1}`, color: "", links: [], images: [] },
      ...nodeDefaults,
    };
    setNodes((prevNodes) => [...prevNodes, newNode]);

    const isEdgeExists = edges.some((edge) => edge.source === id && edge.target === newNode.id);

    if (!isEdgeExists) {
      const newEdge = {
        id: uuidv4(),
        source: id,
        target: newNode.id,
        animated: animation,
        type: edgeType,
      };
      setEdges((prevEdges) => [...prevEdges, newEdge]);
    }

    setAlert("success", t("mindmap.alert.addNode"));
  };

  return (
    <Tooltip arrow title={t("mindmap.addNodeTooltip")}>
      <span>
        <ButtonBase
          disabled={!selectedNode}
          color="primary"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            if (selectedNode) {
              addNode(selectedNode);
            }
          }}
          sx={{
            zIndex: 1000,
            py: 1,
            px: 2,
            transition: "color 0.2s ease-in-out",
            "&:hover": { color: theme.palette.primary.main },
            "&.Mui-disabled": {
              opacity: 0.5,
              color: theme.palette.text.disabled,
            },
          }}
        >
          <AddIcon sx={{ mr: 1 }} fontSize="small" />
          <Typography variant="button">{t("mindmap.addNode")}</Typography>
        </ButtonBase>
      </span>
    </Tooltip>
  );
};

AddNodeButton.propTypes = {
  selectedNode: PropTypes.string,
};
