import AddIcon from "@mui/icons-material/Add";
import ArticleIcon from "@mui/icons-material/Article";
import FolderIcon from "@mui/icons-material/Folder";
import MoneyIcon from "@mui/icons-material/Money";
import MovieFilterIcon from "@mui/icons-material/MovieFilter";
import ShortTextIcon from "@mui/icons-material/ShortText";
import SubjectIcon from "@mui/icons-material/Subject";
import { Box, Grid, Paper, useTheme } from "@mui/material";
import ButtonBase from "@mui/material/ButtonBase";
import Grow from "@mui/material/Grow";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";

import { CenteredBorderText } from "../../../components/common/CenteredBorderText";
import { extractTextFromHTML } from "../../../utils/utils";

interface TextData {
  text_files: Array<{
    uuid: string;
    title: string;
    content: string;
  }>;
}

const Text = ({ data }: { data: TextData | undefined }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useCustomNavigate();

  // 空ファイル新規作成
  const handleNewFile = async (type: number) => {
    try {
      axios.defaults.withCredentials = true;
      const csrftoken = Cookies.get("csrftoken");
      // リクエストヘッダーにCSRFトークンを追加
      const config = {
        headers: { "X-CSRFToken": csrftoken },
      };
      const res = await axios.post("/api/v1/text-file/", { type: type }, config);
      if (res.status === 200) {
        navigate("/notes-nebula/files/" + res.data.uuid);
      }
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };

  const AIs = [
    { title: t("article.title"), icon: <ArticleIcon />, path: "/writing-ai/article" },
    { title: t("summarize.title"), icon: <ShortTextIcon />, path: "/writing-ai/summarizer" },
    { title: t("videoScript.title"), icon: <MovieFilterIcon />, path: "/writing-ai/video-script" },
    { title: t("drawer.templates"), icon: <MoneyIcon />, path: "/template" },
  ];

  return (
    <Grid sx={{ width: "100%" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant={"h6"}
            component={"h3"}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <SubjectIcon sx={{ color: theme.palette.secondary.main, mr: 2 }} />
            {t("dashboard.text")}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Tooltip title={t("dashboard.library")} placement="left">
            <IconButton onClick={() => navigate("/notes-nebula/files")} sx={{ color: theme.palette.primary.main }}>
              <FolderIcon />
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid item xs={12}>
          <CenteredBorderText typographyProps={{ variant: "subtitle2", sx: { color: "grey.600" } }}>
            TEXT FILES
          </CenteredBorderText>
        </Grid>
        {data?.text_files?.map((tf, index) => {
          return (
            <Grid item xs={6} sm={4} key={index}>
              <ButtonBase
                onClick={() => navigate("/notes-nebula/files/" + tf.uuid)}
                sx={{
                  transition: "0.3s",
                  width: "100%",
                  "&:hover": {
                    transform: "translateY(-5px)",
                  },
                }}
              >
                <Grow in={true} timeout={(index + 1) * 500}>
                  <Paper
                    elevation={theme.palette.mode === "dark" ? 1 : 0}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      overflow: "hidden",
                      height: 150,
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        height: "5px",
                        width: "100%",
                        background: theme.custom.gradient.main,
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        height: 140,
                        p: 1,
                        mt: 1,
                      }}
                    >
                      <Typography
                        variant={"subtitle2"}
                        component={"p"}
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                          mb: 1,
                          textAlign: "left",
                        }}
                      >
                        {tf.title}
                      </Typography>
                      <Typography
                        variant={"caption"}
                        component={"p"}
                        sx={{
                          mb: 1,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: "vertical",
                          textAlign: "left",
                        }}
                      >
                        {extractTextFromHTML(tf.content).replace(/#|\*|--|\|/g, "")}
                      </Typography>
                    </Box>
                  </Paper>
                </Grow>
              </ButtonBase>
            </Grid>
          );
        })}
        <Grid item xs={6} sm={4}>
          <ButtonBase
            onClick={() => handleNewFile(1)}
            sx={{
              transition: "0.3s",
              width: "100%",
              "&:hover": {
                transform: "translateY(-5px)",
              },
            }}
          >
            <Grow in={true} timeout={500 * (data?.text_files?.length || 0 + 1)}>
              <Paper
                elevation={theme.palette.mode === "dark" ? 1 : 0}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: 150,
                  border: "2px dashed" + theme.palette.primary.main,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    m: 1,
                  }}
                >
                  <Typography variant={"subtitle1"} textAlign={"center"} component={"p"} sx={{ mb: 1 }}>
                    Create new Document
                  </Typography>
                  <AddIcon sx={{ mb: 1, color: theme.palette.primary.main }} />
                  <Typography variant={"caption"} component={"p"}>
                    {t("dashboard.addText")}
                  </Typography>
                </Box>
              </Paper>
            </Grow>
          </ButtonBase>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Text;
