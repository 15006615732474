import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import InfoIcon from "@mui/icons-material/Info";
import { Fade, Grid } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import VipLabel from "../../../../components/common/VipLabel";
import { useFileSidebarContext } from "../../../../context/FileSidebarContext";
import languagesList from "../../../../json/ai_languages.json";
import file_sidebar from "../../../../json/file_sidebar.json";
import { ColumnBox, RowBox, RowCenteredBox } from "../../../../utils/styledBox";
import SidebarAdditionalTextField from "./SidebarAdditionalTextField";
import SidebarInputTextField from "./SidebarInputTextField";

interface SidebarDescriptionBoxProps {
  descriptionKey: string;
}

interface SidebarSelectBoxProps {
  label: string;
  value: string;
  setValue: (value: string) => void;
  itemsList: { value: string; label: string; pro?: boolean; description?: string }[];
  itemKeyPrefix?: string;
}

const SidebarSelectBox: React.FC<SidebarSelectBoxProps> = ({ label, value, setValue, itemsList, itemKeyPrefix }) => {
  const { t } = useTranslation();

  return (
    <RowCenteredBox sx={{ mb: 2 }}>
      <FormControl variant="outlined" fullWidth size={"small"}>
        <InputLabel>{label}</InputLabel>
        <Select value={value} label={label} onChange={(e) => setValue(e.target.value)}>
          {itemsList.map((item) => (
            <MenuItem value={item.value} key={item.value}>
              <RowBox>
                {itemKeyPrefix ? t(`${itemKeyPrefix}.${item.label}`) : item.label}
                {item.pro && <VipLabel sx={{ ml: 1 }} disabeledLink={true} />}
              </RowBox>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </RowCenteredBox>
  );
};

const SidebarActionCompletion = () => {
  const toneList = file_sidebar.toneList;
  const snsList = file_sidebar.snsList;

  const {
    selectedButton,
    selectedSns,
    setSelectedSns,
    selectedTone,
    setSelectedTone,
    language,
    setLanguage,
    customMessage,
    setCustomMessage,
    aiEngine,
    setAiEngine,
  } = useFileSidebarContext();

  const { t } = useTranslation();
  const [rows, setRows] = useState(1);

  const handleFocus = () => {
    setRows(8);
  };

  const handleBlur = () => {
    setRows(1);
  };

  const SidebarDescriptionBox: React.FC<SidebarDescriptionBoxProps> = ({ descriptionKey }) => {
    const { t } = useTranslation();

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          mb: 2,
        }}
      >
        <FlashOnIcon fontSize={"small"} sx={{ mr: 1 }} color={"secondary"} />
        <Typography variant={"caption"} mr={1}>
          {t(descriptionKey)}
        </Typography>
        <Tooltip title={t("textEditor.sidebar.maxLengthInfo")}>
          <IconButton size={"small"}>
            <InfoIcon fontSize={"small"} />
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  const descriptionKey = `textEditor.sidebar.${selectedButton}Description`;

  if (
    [
      "createSummary",
      "createRephrase",
      "createLonger",
      "createCopyWriting",
      "createText",
      "createCheckText",
      "createNarration",
      "createFAQ",
      "createVoice",
    ].includes(selectedButton)
  ) {
    return (
      <Fade in={true} timeout={500}>
        <Box>
          <SidebarDescriptionBox descriptionKey={descriptionKey} />
          <SidebarInputTextField />
          <SidebarAdditionalTextField />
        </Box>
      </Fade>
    );
  }

  if (["createImages"].includes(selectedButton)) {
    const itemsList = [
      {
        value: "DallE3",
        label: t("juno.input.engine.DallE3"),
        description: t("juno.input.engine.DallE3Description"),
        pro: true,
      },
      {
        value: "Flux11Pro",
        label: t("juno.input.engine.Flux11Pro"),
        description: t("juno.input.engine.Flux1ProDescription"),
        pro: true,
      },
      {
        value: "Flux1Dev",
        label: t("juno.input.engine.Flux1Dev"),
        description: t("juno.input.engine.Flux1DevDescription"),
      },
      {
        value: "Flux1Schnell",
        label: t("juno.input.engine.Flux1Schnell"),
        description: t("juno.input.engine.Flux1SchnellDescription"),
      },
      {
        value: "Flux1RealismLoRA",
        label: t("juno.input.engine.Flux1RealismLoRA"),
        description: t("juno.input.engine.Flux1RealismLoRADescription"),
      },
      {
        value: "Ideogram2",
        label: t("juno.input.engine.Ideogram2"),
        description: t("juno.input.engine.Ideogram2Description"),
        pro: true,
      },
      {
        value: "Ideogram2Turbo",
        label: t("juno.input.engine.Ideogram2Turbo"),
        description: t("juno.input.engine.Ideogram2TurboDescription"),
      },
      { value: "SD3", label: t("juno.input.engine.SD3"), description: t("juno.input.engine.SD3Description") },
      {
        value: "SDUltra",
        label: t("juno.input.engine.SDUltra"),
        description: t("juno.input.engine.SDUltraDescription"),
        pro: true,
      },
    ];

    return (
      <Fade in={true} timeout={500}>
        <Box>
          <SidebarDescriptionBox descriptionKey={descriptionKey} />
          <SidebarSelectBox
            label={t("textEditor.sidebar.imageModel")}
            value={aiEngine}
            setValue={setAiEngine}
            itemsList={itemsList}
          />
          <SidebarInputTextField />
          {/*<SidebarAdditionalTextField />*/}
        </Box>
      </Fade>
    );
  }

  if (["createCustom"].includes(selectedButton)) {
    return (
      <Fade in={true} timeout={500}>
        <Box>
          <SidebarDescriptionBox descriptionKey={descriptionKey} />
          <SidebarInputTextField />
          <ColumnBox mb={2}>
            <TextField
              // size={"small"}
              value={customMessage}
              onChange={(e) => {
                setCustomMessage(e.target.value);
              }}
              multiline
              variant={"outlined"}
              inputProps={{ maxLength: 1000 }}
              label={t("textEditor.sidebar.customLabel")}
              sx={{ width: "100%" }}
              InputLabelProps={{ shrink: true }}
              maxRows={rows}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            <Box sx={{ justifyContent: "flex-end", display: "flex", width: "100%" }}>
              <Typography variant={"caption"} color={"textSecondary"} mr={1}>
                {customMessage.length} / 1000
              </Typography>
            </Box>
          </ColumnBox>
        </Box>
      </Fade>
    );
  }

  if (
    ["createTitles", "createContinue", "createIntroduction", "createVoice", "createConclusion"].includes(selectedButton)
  ) {
    return (
      <Fade in={true} timeout={500}>
        <Box>
          <SidebarDescriptionBox descriptionKey={descriptionKey} />
          <SidebarAdditionalTextField />
        </Box>
      </Fade>
    );
  }

  switch (selectedButton) {
    case "createTags":
      return (
        <Fade in={true} timeout={500}>
          <Box>
            <SidebarDescriptionBox descriptionKey={descriptionKey} />
            <SidebarSelectBox
              label="Social Media"
              value={selectedSns}
              setValue={setSelectedSns}
              itemsList={snsList.map((sns) => ({ value: sns, label: sns }))}
              itemKeyPrefix="textEditor.sidebar.tagType"
            />
            <SidebarAdditionalTextField />
          </Box>
        </Fade>
      );
    case "createTone":
      return (
        <Fade in={true} timeout={500}>
          <Box>
            <SidebarDescriptionBox descriptionKey={descriptionKey} />
            <SidebarSelectBox
              label="Tone"
              value={selectedTone}
              setValue={setSelectedTone}
              itemsList={toneList.map((tone) => ({
                value: t(`textEditor.sidebar.toneType.${tone}`),
                label: tone,
              }))}
              itemKeyPrefix="textEditor.sidebar.toneType"
            />
            <SidebarInputTextField />
            <SidebarAdditionalTextField />
          </Box>
        </Fade>
      );
    case "createTranslate":
      return (
        <Fade in={true} timeout={500}>
          <Box>
            <SidebarDescriptionBox descriptionKey={descriptionKey} />
            <SidebarSelectBox
              label="Translate to"
              value={language}
              setValue={setLanguage}
              itemsList={languagesList.map((lang) => ({ value: lang.language_code, label: lang.label }))}
            />
            <SidebarInputTextField />
            <SidebarAdditionalTextField />
          </Box>
        </Fade>
      );
    default:
      return null;
  }
};

const SidebarButtonList = () => {
  const { t } = useTranslation();
  const buttons = file_sidebar.buttons;
  const { selectedButton, setSelectedButton } = useFileSidebarContext();
  const handleButtonClick = (buttonName: string) => {
    setSelectedButton(buttonName);
  };
  const theme = useTheme();
  const [expanded, setExpanded] = useState<string | false>("panel1");

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "left", width: "100%", gap: 1 }}>
        <Hidden smUp>
          <Select value={selectedButton} onChange={(e) => handleButtonClick(e.target.value)} sx={{ width: "100%" }}>
            {buttons.map((button, index) => (
              <MenuItem value={button} key={index}>
                {t(`textEditor.sidebar.${button}`)}
              </MenuItem>
            ))}
          </Select>
        </Hidden>

        <Hidden smDown>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            variant={"outlined"}
            sx={{
              border: "none",
              borderColor: "primary.main",
              borderRadius: 2,
              bgcolor: theme.palette.background.custom1,
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
              <RowBox>
                <AutoAwesomeIcon fontSize={"small"} sx={{ mr: 1 }} color={"secondary"} />
                <Typography variant={"button"} sx={{ width: "100%", flexShrink: 0 }} color={"primary"}>
                  {selectedButton ? t(`textEditor.sidebar.${selectedButton}`) : "Select AI Action"}
                </Typography>
              </RowBox>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1}>
                {buttons.map((button, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        handleButtonClick(button);
                        setSelectedButton(button);
                        setExpanded(false);
                      }}
                      sx={{
                        width: "100%",
                        background:
                          selectedButton === button
                            ? theme.palette.mode === "dark"
                              ? "linear-gradient(135deg, #4287f5, #9942f5)"
                              : "linear-gradient(135deg, rgba(247, 128, 93, 1), rgba(149, 70, 70, 1));"
                            : "",
                        textTransform: "capitalize",
                        fontWeight: selectedButton === button ? "600" : "normal",
                        color: selectedButton === button ? "white" : "primary.main",
                        border: selectedButton === button ? "none" : "",
                        "&:hover": { border: selectedButton === button ? "none" : "" },
                      }}
                    >
                      {t(`textEditor.sidebar.${button}`)}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Hidden>
      </Box>
      <Box sx={{ borderRadius: 2, width: "100%", mt: 2 }}>
        <SidebarActionCompletion />
      </Box>
    </>
  );
};

export default SidebarButtonList;
