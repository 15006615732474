import { Close as CloseIcon } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import React from "react";

interface NodeImagesProps {
  images: string[];
  onDeleteImage: (index: number) => void;
  onPreviewImage: (index: number) => void;
  selected: boolean;
}

export const NodeImages: React.FC<NodeImagesProps> = ({ images, onDeleteImage, onPreviewImage, selected }) => {
  const renderDeleteButton = (index: number) => {
    if (!selected) return null;
    return (
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (e.isPropagationStopped() && e.isDefaultPrevented()) {
            console.log("delete image");
            onDeleteImage(index);
          }
        }}
        size="small"
        sx={{
          position: "absolute",
          top: 4,
          right: 4,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
          pointerEvents: "auto",
          zIndex: 1,
        }}
      >
        <CloseIcon sx={{ color: "white", fontSize: "small" }} />
      </IconButton>
    );
  };

  const imageCount = images.length;
  if (imageCount === 0) return null;
  const containerHeight = 240;

  if (imageCount === 1) {
    return (
      <Box sx={{ position: "relative", height: containerHeight, pt: 1 }}>
        <img
          src={images[0]}
          alt="image"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          onClick={() => onPreviewImage(0)}
        />
        {renderDeleteButton(0)}
      </Box>
    );
  }

  if (imageCount === 2) {
    return (
      <Box sx={{ display: "flex", height: containerHeight, pt: 1 }}>
        {images.map((image, index) => (
          <Box key={index} sx={{ position: "relative", width: "50%", height: "100%" }}>
            <img
              src={image}
              alt={`image ${index + 1}`}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              onClick={() => onPreviewImage(index)}
            />
            {renderDeleteButton(index)}
          </Box>
        ))}
      </Box>
    );
  }

  if (imageCount === 3) {
    return (
      <Box sx={{ display: "flex", height: containerHeight, pt: 1 }}>
        <Box sx={{ position: "relative", width: "50%", height: "100%" }}>
          <img
            src={images[0]}
            alt="image 1"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            onClick={() => onPreviewImage(0)}
          />
          {renderDeleteButton(0)}
        </Box>
        <Box sx={{ width: "50%", height: "100%", display: "flex", flexDirection: "column" }}>
          <Box sx={{ position: "relative", height: "50%" }}>
            <img
              src={images[1]}
              alt="image 2"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              onClick={() => onPreviewImage(1)}
            />
            {renderDeleteButton(1)}
          </Box>
          <Box sx={{ position: "relative", height: "50%" }}>
            <img
              src={images[2]}
              alt="image 3"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              onClick={() => onPreviewImage(2)}
            />
            {renderDeleteButton(2)}
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", height: containerHeight, pt: 1 }}>
      <Box sx={{ width: "50%", height: "100%", display: "flex", flexDirection: "column" }}>
        <Box sx={{ position: "relative", height: "50%" }}>
          <img
            src={images[0]}
            alt="image 1"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            onClick={() => onPreviewImage(0)}
          />
          {renderDeleteButton(0)}
        </Box>
        <Box sx={{ position: "relative", height: "50%" }}>
          <img
            src={images[1]}
            alt="image 2"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            onClick={() => onPreviewImage(1)}
          />
          {renderDeleteButton(1)}
        </Box>
      </Box>
      <Box sx={{ width: "50%", height: "100%", display: "flex", flexDirection: "column" }}>
        <Box sx={{ position: "relative", height: "50%" }}>
          <img
            src={images[2]}
            alt="image 3"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            onClick={() => onPreviewImage(2)}
          />
          {renderDeleteButton(2)}
        </Box>
        <Box sx={{ position: "relative", height: "50%" }}>
          <img
            src={images[3]}
            alt="image 4"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            onClick={() => onPreviewImage(3)}
          />
          {renderDeleteButton(3)}
        </Box>
      </Box>
    </Box>
  );
};
