import AltRouteIcon from "@mui/icons-material/AltRoute";
import BoltIcon from "@mui/icons-material/Bolt";
import BrushIcon from "@mui/icons-material/Brush";
import FontDownloadIcon from "@mui/icons-material/FontDownload";
import FormatShapesIcon from "@mui/icons-material/FormatShapes";
import ImageIcon from "@mui/icons-material/Image";
import InfoIcon from "@mui/icons-material/Info";
import PhotoSizeSelectLargeIcon from "@mui/icons-material/PhotoSizeSelectLarge";
import RouteIcon from "@mui/icons-material/Route";
import TwoKIcon from "@mui/icons-material/TwoK";
import {
  Box,
  ButtonBase,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Fade,
  Grid,
  Paper,
  Popover,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import VipLabel from "../../../../components/common/VipLabel";
import { useParamsContext } from "../../../../context/juno/ParamsContext";
import { useCustomNavigate } from "../../../../hooks/useCustomNavigate";
import { useJunoModels } from "../../../../hooks/useJunoModels";
import { AiEngineList, MenuList } from "../../../../types/junoTypes";
import { aiEngineColor } from "../../../../utils/aiEngineColor";
import { ColumnBox, ColumnCenteredBox, RowBox } from "../../../../utils/styledBox";
interface ImageModelOption {
  value: string;
  label: string;
  category: string[];
  description: string;
}

export default function SelectImageModel() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { getImageModels } = useJunoModels();
  const options = getImageModels;

  const { menu, setMenu, aiEngine, setAiEngine } = useParamsContext();
  const [tempMenu, setTempMenu] = useState<MenuList>(menu);
  const [selectedEngine, setSelectedEngine] = useState<AiEngineList>(aiEngine);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [rotation, setRotation] = useState(0);
  const isMounted = useRef(false);
  const open = Boolean(anchorEl);
  const navigate = useCustomNavigate();

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    if (tempMenu !== menu) {
      // メニューが変更されている場合、新しいメニューで選択されているエンジンが利用可能かチェック
      if (options[tempMenu].some((option) => option.value === selectedEngine)) {
        setMenu(tempMenu);
      } else {
        // 利用できない場合は、新しいメニューのデフォルトエンジンを設定
        const defaultEngine = (
          tempMenu === "txt2img" || tempMenu === "img2img" ? "Flux1Dev" : options[tempMenu][0].value
        ) as AiEngineList;
        setMenu(tempMenu);
        setSelectedEngine(defaultEngine);
        setAiEngine(defaultEngine);
      }
    }
    setAnchorEl(null);
  };

  const handleMenuChange = (_: React.SyntheticEvent, newMenu: MenuList) => {
    setTempMenu(newMenu);
  };

  useEffect(() => {
    setSelectedEngine(aiEngine);
  }, [aiEngine]);

  const handleAiEngineChange = (value: AiEngineList) => {
    setSelectedEngine(value);
    setAiEngine(value);
    handlePopoverClose();
    navigate(`/juno/image-generator?menu=${tempMenu}&ai_engine=${value}`);
  };

  useEffect(() => {
    if (isMounted.current) {
      setRotation((prev) => prev + 360);
    } else {
      isMounted.current = true;
    }
  }, [selectedEngine]);

  const getMenuIcon = () => {
    switch (menu) {
      case "txt2img":
        return <FontDownloadIcon color="primary" />;
      case "img2img":
        return <ImageIcon color="primary" />;
      case "upscale":
        return <PhotoSizeSelectLargeIcon color="primary" />;
      case "editor":
        return <BrushIcon color="primary" />;
      default:
        return null;
    }
  };

  const getMenuTitle = () => {
    switch (menu) {
      case "txt2img":
        return t("juno.menu.txt2img");
      case "img2img":
        return t("juno.menu.img2img");
      case "upscale":
        return t("juno.menu.upscale");
      case "editor":
        return t("juno.menu.editor");
      default:
        return null;
    }
  };

  return (
    <Paper elevation={theme.palette.mode === "dark" ? 1 : 0}>
      <Box
        sx={{
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <ButtonBase onClick={handlePopoverOpen} sx={{ width: "100%", borderRadius: 1, overflow: "hidden" }}>
          <Box sx={{ display: "flex", width: "100%", position: "relative" }}>
            <Box sx={{ width: "130px", height: "80px", position: "relative" }}>
              <Fade in={true} key={menu} timeout={1000}>
                <CardMedia
                  component="img"
                  image={`/images/juno/ai-engine/${menu === "txt2img" ? "t2i" : menu === "img2img" ? "i2i" : menu === "upscale" ? "upscale" : "editor"}.png`}
                  alt={getMenuTitle() || ""}
                  sx={{ width: 120, height: "100%", objectFit: "cover" }}
                />
              </Fade>
            </Box>
            <ColumnBox sx={{ ml: 2, gap: 0.5 }}>
              <Fade in={true} key={menu} timeout={1000}>
                <RowBox sx={{ gap: 1 }}>
                  {getMenuIcon()}
                  <Typography variant="body2">{getMenuTitle()}</Typography>
                </RowBox>
              </Fade>
              <Fade in={true} key={selectedEngine} timeout={1000}>
                <RowBox sx={{ gap: 1 }}>
                  <BoltIcon
                    sx={{
                      color: aiEngineColor(selectedEngine),
                      transform: `rotate(${rotation}deg)`,
                      transition: "transform 1s ease-in-out",
                    }}
                  />
                  <Typography variant="body2" whiteSpace={"nowrap"}>
                    {t(`juno.input.engine.${selectedEngine}`)}
                  </Typography>

                  {/* PROならVIPを表記 */}
                  {options[menu].find((option) => option.value === selectedEngine)?.category.includes("pro") && (
                    <VipLabel />
                  )}
                </RowBox>
              </Fade>
            </ColumnBox>
          </Box>
        </ButtonBase>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          elevation={4}
          sx={{ mx: 1 }}
        >
          <Box sx={{ p: 2, width: { xs: "100vw", md: 900 }, backgroundColor: theme.palette.background.custom1 }}>
            <Tabs value={tempMenu} onChange={handleMenuChange} sx={{ mb: 2 }}>
              <Tab
                value="txt2img"
                label={
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <FontDownloadIcon fontSize="small" />
                    Text to Image
                  </Box>
                }
              />
              <Tab
                value="img2img"
                label={
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <ImageIcon fontSize="small" />
                    Image to Image
                  </Box>
                }
              />
              <Tab
                value="upscale"
                label={
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <PhotoSizeSelectLargeIcon fontSize="small" />
                    Image Upscaling
                  </Box>
                }
              />
              <Tab
                value="editor"
                label={
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <BrushIcon fontSize="small" />
                    Image Editor
                  </Box>
                }
              />
            </Tabs>
            <Grid container spacing={2}>
              {options[tempMenu].map((option: ImageModelOption, index: number) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <Fade in={true} key={tempMenu} timeout={200 * index > 1000 ? 1000 : 200 * index}>
                    <Card
                      sx={{
                        width: "100%",
                        border: selectedEngine === option.value ? "2px solid" : "0px solid",
                        borderColor: selectedEngine === option.value ? theme.palette.primary.main : "transparent",
                      }}
                      onClick={() => handleAiEngineChange(option.value as AiEngineList)}
                      elevation={0}
                    >
                      <CardActionArea>
                        <CardContent
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            textAlign: "center",
                            p: 0,
                            position: "relative",
                          }}
                        >
                          <Box
                            sx={{
                              position: "absolute",
                              top: 4,
                              left: 4,
                              zIndex: 1,
                            }}
                          >
                            {option.category.includes("pro") ? <VipLabel /> : null}
                          </Box>

                          <Box
                            sx={{
                              position: "absolute",
                              top: 4,
                              right: 4,
                              zIndex: 1,
                            }}
                          >
                            <RowBox gap={1}>
                              {option.category.includes("vector") && (
                                <Tooltip title={t("juno.input.category.vector")} arrow placement="top">
                                  <FormatShapesIcon
                                    sx={{
                                      opacity: 0.8,
                                      color: "#fff",
                                      backgroundColor: "#036c03",
                                      borderRadius: "50%",
                                      p: 0.5,
                                    }}
                                  />
                                </Tooltip>
                              )}
                              {option.category.includes("juno") && (
                                <Tooltip title={t("juno.input.category.juno")} arrow placement="top">
                                  <ColumnCenteredBox
                                    sx={{
                                      opacity: 0.8,
                                      backgroundColor: "#3273a8",
                                      borderRadius: "50%",
                                      p: 0.5,
                                      color: "#fff",
                                      width: 24,
                                      height: 24,
                                    }}
                                  >
                                    <Typography variant="caption" fontWeight={"bold"}>
                                      J
                                    </Typography>
                                  </ColumnCenteredBox>
                                </Tooltip>
                              )}
                              {option.category.includes("2k") && (
                                <Tooltip title={t("juno.input.category.twoK")} arrow placement="top">
                                  <TwoKIcon
                                    sx={{
                                      opacity: 0.8,
                                      color: "#fff",
                                      backgroundColor: "#0b7780",
                                      borderRadius: "50%",
                                      p: 0.5,
                                    }}
                                  />
                                </Tooltip>
                              )}
                              {option.category.includes("vary") && (
                                <Tooltip title={t("juno.input.category.vary")} arrow placement="top">
                                  <AltRouteIcon
                                    sx={{
                                      opacity: 0.8,
                                      color: "#fff",
                                      backgroundColor: "#4d2464",
                                      borderRadius: "50%",
                                      p: 0.5,
                                    }}
                                  />
                                </Tooltip>
                              )}
                              {option.category.includes("controlNet") && (
                                <Tooltip title={t("juno.input.category.controlNet")} arrow placement="top">
                                  <RouteIcon
                                    sx={{
                                      opacity: 0.8,
                                      color: "#fff",
                                      backgroundColor: "#ce6e00",
                                      borderRadius: "50%",
                                      p: 0.5,
                                    }}
                                  />
                                </Tooltip>
                              )}
                              <Tooltip title={option.description} arrow placement="top">
                                <InfoIcon
                                  sx={{
                                    opacity: 0.8,
                                    color: "#fff",
                                    backgroundColor: "#313131",
                                    borderRadius: "50%",
                                    p: 0.5,
                                  }}
                                />
                              </Tooltip>
                            </RowBox>
                          </Box>

                          <CardMedia
                            component="img"
                            height="130"
                            image={`/images/juno/ai-engine/${option["value"]}.png`}
                            alt={option.label}
                          />
                          <Box
                            sx={{
                              position: "absolute",
                              bottom: 8,
                              backgroundColor: alpha(theme.palette.background.paper, 0.8),
                              borderRadius: 4,
                              px: 2,
                              py: 0.5,
                            }}
                          >
                            <Typography variant="caption" fontWeight={"bold"}>
                              {option.label}
                            </Typography>
                          </Box>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Fade>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Popover>
      </Box>
    </Paper>
  );
}
