import MicIcon from "@mui/icons-material/Mic";
import { IconButton } from "@mui/material";
import React, { useCallback, useEffect, useRef } from "react";
import {
  SpeechRecognitionConstructor,
  SpeechRecognitionErrorEvent,
  SpeechRecognitionEvent,
} from "../../../types/chatTypes";

interface VoiceInputProps {
  isRecording: boolean;
  onVoiceInput: () => void;
  onTranscriptChange: (transcript: string) => void;
  initialText: string;
}

interface SpeechRecognition extends EventTarget {
  lang: string;
  continuous: boolean;
  interimResults: boolean;
  start: () => void;
  stop: () => void;
  onresult: (event: SpeechRecognitionEvent) => void;
  onerror: (event: SpeechRecognitionErrorEvent) => void;
}

declare global {
  interface Window {
    SpeechRecognition?: SpeechRecognitionConstructor;
    webkitSpeechRecognition?: SpeechRecognitionConstructor;
  }
}

export const VoiceInput: React.FC<VoiceInputProps> = ({
  isRecording,
  onVoiceInput,
  onTranscriptChange,
  initialText,
}) => {
  const recognitionRef = useRef<SpeechRecognition | null>(null);
  const finalTranscriptRef = useRef(initialText);
  const lastSoundRef = useRef<number>(Date.now());

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isRecording) {
      // 無音検出のためのインターバル
      intervalId = setInterval(() => {
        const now = Date.now();
        const silentDuration = now - lastSoundRef.current;

        if (silentDuration >= 2000) {
          // 3秒以上無音
          if (recognitionRef.current) {
            recognitionRef.current.stop();
            recognitionRef.current = null;
            onVoiceInput();
          }
        }
      }, 500); // 500ミリ秒ごとにチェック
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isRecording, onVoiceInput]);

  const handleVoiceInput = useCallback(async () => {
    if (!isRecording) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        console.log("Audio stream obtained:", stream);
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        if (!SpeechRecognition) {
          console.error("Speech Recognition APIがサポートされていません");
          return;
        }

        recognitionRef.current = new SpeechRecognition();
        recognitionRef.current.lang = "ja-JP";
        recognitionRef.current.continuous = true;
        recognitionRef.current.interimResults = true;

        finalTranscriptRef.current = initialText;

        recognitionRef.current.onresult = (event) => {
          const results = Array.from(event.results);
          const lastResult = results[results.length - 1];

          if (lastResult) {
            lastSoundRef.current = Date.now(); // 音声検出時刻を更新
            const transcript = lastResult[0].transcript;
            if (lastResult.isFinal) {
              finalTranscriptRef.current += transcript;
              onTranscriptChange(finalTranscriptRef.current);
            } else {
              onTranscriptChange(finalTranscriptRef.current + transcript);
            }
          }
        };

        recognitionRef.current.onerror = (event) => {
          console.error("音声認識エラー:", event.error, event.message);
          // エラー時のみ録音状態を解除
          if (recognitionRef.current) {
            recognitionRef.current.stop();
            recognitionRef.current = null;
            onVoiceInput();
          }
        };

        recognitionRef.current.start();
        lastSoundRef.current = Date.now(); // 録音開始時に時刻を更新
        onVoiceInput();
      } catch (err) {
        console.error("音声の取得に失敗しました:", err);
      }
    } else {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
        recognitionRef.current = null;
      }
      onVoiceInput();
    }
  }, [isRecording, initialText, onVoiceInput, onTranscriptChange]);

  return (
    <IconButton onClick={handleVoiceInput} color={isRecording ? "error" : "default"} size="small">
      <MicIcon />
    </IconButton>
  );
};
