import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, Paper } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import GeneratedImageModal from "../../../../components/common/GeneratedImageModal";
import { useAlert } from "../../../../context/AlertContext";
import { useParamsContext } from "../../../../context/juno/ParamsContext";
import { useUploadImageContext } from "../../../../context/juno/UploadImageContext";
import { useUpscaleContext } from "../../../../context/juno/UpscaleContext";
import { ColumnCenteredBox } from "../../../../utils/styledBox";

type ImageUploadProps = {
  type: "primary" | "secondary";
};

export default function ImageUpload({ type }: ImageUploadProps) {
  const { t } = useTranslation();
  const { setImage, image, setImage2, image2 } = useUploadImageContext();

  const currentImage = type === "primary" ? image : image2;
  const setCurrentImage = type === "primary" ? setImage : setImage2;
  const { setUuidParent } = useUpscaleContext();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const boxRef = useRef<HTMLDivElement>(null);
  const { aiEngine } = useParamsContext();

  const { setAlert } = useAlert();

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files && files[0]) {
      const file = files[0];
      updateImage(file);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0]) {
      const file = files[0];
      updateImage(file);
      setUuidParent("");
    }
  };

  const updateImage = (file: File) => {
    setUuidParent("");

    // ファイルタイプチェック (JPGまたはPNG)
    if (file.type !== "image/jpeg" && file.type !== "image/png") {
      setAlert("error", t("juno.input.uploadError.type"));
      return;
    }

    // ファイルサイズチェック
    const maxSizeInBytes = aiEngine === "SD35" ? 10000000 : 20000000; // 20MB
    if (file.size > maxSizeInBytes) {
      setAlert("error", t("juno.input.uploadError.size", { size: maxSizeInBytes / 1000000 }));
      return;
    }

    // 画像の幅と高さを非同期でチェック
    const checkImageSize = (file: File): Promise<void> => {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = URL.createObjectURL(file);
        image.onload = () => {
          if (image.width > 2048 || image.height > 2048) {
            setAlert("error", t("juno.input.uploadError.scale"));
            reject();
          } else {
            resolve();
          }
        };
      });
    };

    checkImageSize(file)
      .then(() => {
        // 画像サイズが条件を満たす場合にのみ実行
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
          if (e.target && e.target.result) {
            setCurrentImage(e.target.result as string);
          }
        };
        reader.readAsDataURL(file);
      })
      .catch(() => {
        // エラー処理や画像処理の中止
      });
  };

  const handleAreaClick = () => {
    fileInputRef.current?.click();
  };

  const handleDelete = (event: React.MouseEvent) => {
    event.stopPropagation();
    setUuidParent("");
    setCurrentImage(null);
  };

  const [openModal, setOpenModal] = React.useState(false);

  const handleGeneratedImageSelect = (imageUrl: string) => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = `${imageUrl}public`;

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      if (!ctx) return;

      ctx.drawImage(img, 0, 0);
      canvas.toBlob((blob) => {
        if (!blob) return;
        const file = new File([blob], "generated-image.jpg", { type: "image/jpeg" });
        updateImage(file);
      }, "image/jpeg");
    };

    img.onerror = (error) => {
      console.error("Error loading image:", error);
    };
  };

  return (
    <ColumnCenteredBox>
      <Box sx={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", mb: 1 }}>
        <Paper
          variant="outlined"
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onClick={handleAreaClick}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              aspectRatio: "1 / 1",
              "&:hover": {
                cursor: "pointer",
              },
              position: "relative",
            }}
            ref={boxRef}
          >
            {currentImage ? (
              <>
                <img
                  src={currentImage}
                  alt="Upload Preview"
                  style={{ width: "100%", height: "100%", objectFit: "contain", pointerEvents: "none" }}
                  onClick={handleAreaClick}
                />
                <IconButton
                  sx={{
                    position: "absolute",
                    bottom: 8,
                    right: 8,
                    backdropFilter: "blur(5px)",
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    transition: "0.3s",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.4)",
                    },
                  }}
                  onClick={handleDelete}
                >
                  <DeleteIcon style={{ color: "white" }} />
                </IconButton>
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <CloudUploadIcon fontSize={"large"} sx={{ mb: 2 }} />
                Upload an image
              </Box>
            )}
            <input type="file" hidden onChange={handleChange} accept="image/*" ref={fileInputRef} />
          </Box>
        </Paper>
      </Box>
      <Button variant="text" onClick={() => setOpenModal(true)} startIcon={<AddPhotoAlternateIcon />}>
        {t("juno.input.imageEditor.generated")}
      </Button>
      <GeneratedImageModal open={openModal} setOpen={setOpenModal} setImage={handleGeneratedImageSelect} />
    </ColumnCenteredBox>
  );
}
