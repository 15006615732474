import ChatIcon from "@mui/icons-material/Chat";
import SearchIcon from "@mui/icons-material/Search";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
import React, { MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import VipLabel from "../../../components/common/VipLabel";
import useSubscription from "../../../hooks/useSubscription";
import { ChatMode } from "../../../types/chatTypes";

interface ToolbarProps {
  mode: ChatMode;
  onModeChange: (mode: ChatMode) => void;
  historyData: Array<{ images?: string[] }>;
}

export const Toolbar: React.FC<ToolbarProps> = ({ mode, onModeChange, historyData }) => {
  const { t } = useTranslation();

  const { isFree } = useSubscription();
  const hasHistoryImages = historyData.some((item) => item.images && item.images.length > 0);

  return (
    <ToggleButtonGroup
      value={mode}
      exclusive
      onChange={(_: MouseEvent<HTMLElement>, newMode: ChatMode) => {
        if (newMode !== null) {
          onModeChange(newMode);
        }
      }}
      aria-label="chat mode"
      size="small"
      color="secondary"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        gap: 0,
        "& .MuiToggleButton-root": {
          px: { xs: 1, sm: 2 },
          py: { xs: 0.8 },
          fontSize: { xs: "0.75rem", sm: "inherit" },
          "& .MuiSvgIcon-root": {
            mr: { xs: 0.5, sm: 1 },
          },
          "& .MuiToggleButton-label": {
            display: { xs: "none", sm: "block" },
          },
        },
      }}
    >
      <Tooltip title={t("browsing.mode.llmTooltip")} arrow>
        <ToggleButton value="llm" aria-label="llm mode">
          <ChatIcon fontSize="small" />
          <Typography variant="caption" fontWeight={mode === "llm" ? 900 : 500}>
            {t("browsing.mode.llm")}
          </Typography>
        </ToggleButton>
      </Tooltip>
      <Tooltip title={t("browsing.mode.webTooltip")} arrow>
        <ToggleButton value="web" aria-label="web search mode">
          <SearchIcon fontSize="small" />
          <Typography variant="caption" fontWeight={mode === "web" ? 900 : 500}>
            {t("browsing.mode.web")}
          </Typography>
        </ToggleButton>
      </Tooltip>
      <Tooltip title={t("browsing.mode.youtubeTooltip")} arrow>
        <ToggleButton value="youtube" aria-label="youtube search mode">
          <YouTubeIcon fontSize="small" />
          <Typography variant="caption" fontWeight={mode === "youtube" ? 900 : 500}>
            {t("browsing.mode.youtube")}
          </Typography>
        </ToggleButton>
      </Tooltip>
      <Tooltip title={t("browsing.mode.researchTooltip")} arrow>
        <span>
          <ToggleButton
            value="research"
            aria-label="research mode"
            disabled={isFree || hasHistoryImages}
            sx={{ position: "relative", pr: "60px!important" }}
          >
            <SmartToyIcon fontSize="small" />
            <Typography variant="caption" fontWeight={mode === "research" ? 900 : 500}>
              {t("browsing.mode.research")}
            </Typography>
            <VipLabel sx={{ position: "absolute", right: 5.5, top: 5.5 }} />
          </ToggleButton>
        </span>
      </Tooltip>
    </ToggleButtonGroup>
  );
};
