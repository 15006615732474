import AddLinkIcon from "@mui/icons-material/AddLink";
import { ButtonBase, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

interface LinkButtonProps {
  /** 選択中のノードID */
  selectedNode: string | null;
  /** リンクを追加するモーダルを開く関数 */
  onOpenLinkModal: () => void;
}

/**
 * リンクを追加するボタンコンポーネント
 */
export const LinkButton: React.FC<LinkButtonProps> = ({ selectedNode, onOpenLinkModal }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Tooltip arrow title={t("mindmap.addLinkTooltip")}>
      <span>
        <ButtonBase
          disabled={!selectedNode}
          color="primary"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            onOpenLinkModal();
          }}
          sx={{
            zIndex: 1000,
            py: 1,
            px: 2,
            transition: "color 0.2s ease-in-out",
            "&:hover": { color: theme.palette.primary.main },
            "&.Mui-disabled": {
              opacity: 0.5,
              color: theme.palette.text.disabled,
            },
          }}
        >
          <AddLinkIcon sx={{ mr: 1 }} fontSize="small" />
          <Typography variant="button">{t("mindmap.addLink")}</Typography>
        </ButtonBase>
      </span>
    </Tooltip>
  );
};

LinkButton.propTypes = {
  selectedNode: PropTypes.string,
  onOpenLinkModal: PropTypes.func.isRequired,
};
