import SendIcon from "@mui/icons-material/Send";
import { Box, CircularProgress, IconButton, InputAdornment, TextField, Typography, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import PromptActions from "./PromptActions";

interface PromptTextFieldProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
  placeholder: string;
  localPrompt: string;
  aiEngine: string;
  rotationDegree: number;
  generatingPrompt: boolean;
  waitingGenerate: boolean;
  imageCredit: number;
  fetchingCredit: boolean;
  onGenerateSampleText: () => void;
  onUpdatePrompt: () => void;
  onDelete: () => void;
  onSend: () => void;
  setLocalPrompt: (prompt: string) => void;
  requiredPrompt: "REQUIRED" | "OPTIONAL" | "NOT_NEEDED";
}

const PromptTextField: React.FC<PromptTextFieldProps> = ({
  value,
  onChange,
  disabled,
  placeholder,
  localPrompt,
  aiEngine,
  rotationDegree,
  generatingPrompt,
  waitingGenerate,
  imageCredit,
  fetchingCredit,
  onGenerateSampleText,
  onUpdatePrompt,
  onDelete,
  onSend,
  setLocalPrompt,
  requiredPrompt,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box sx={{ px: 2, pb: 1, mt: 2, display: "flex" }}>
      <TextField
        label={t("juno.history.input")}
        variant="outlined"
        placeholder={placeholder}
        autoComplete="off"
        autoFocus={true}
        value={value}
        onChange={onChange}
        disabled={disabled}
        multiline
        fullWidth
        maxRows={6}
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PromptActions
                localPrompt={localPrompt}
                aiEngine={aiEngine}
                rotationDegree={rotationDegree}
                generatingPrompt={generatingPrompt}
                onGenerateSampleText={onGenerateSampleText}
                onUpdatePrompt={onUpdatePrompt}
                onDelete={onDelete}
                setLocalPrompt={setLocalPrompt}
              />
              <Typography variant="body2" color="secondary" sx={{ mr: 1 }}>
                {":"}
              </Typography>
              {fetchingCredit ? (
                <Box sx={{ width: 24, display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <CircularProgress size={16} color="secondary" />
                </Box>
              ) : (
                <Typography variant="body2" color="secondary" sx={{ width: 24 }}>
                  {imageCredit}
                </Typography>
              )}
            </InputAdornment>
          ),
          endAdornment: (
            <Box sx={{ display: "flex", alignItems: "flex-end", height: "100%" }}>
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  disabled={
                    waitingGenerate ||
                    generatingPrompt ||
                    (requiredPrompt === "REQUIRED" && localPrompt.trim().length === 0)
                  }
                  color="secondary"
                  onClick={onSend}
                >
                  {waitingGenerate ? <CircularProgress size={20} color="primary" /> : <SendIcon />}
                </IconButton>
              </Box>
            </Box>
          ),
          sx: { backgroundColor: theme.palette.background.paper },
          inputProps: { maxLength: 1000 },
        }}
      />
    </Box>
  );
};

export default PromptTextField;
