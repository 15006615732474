const browsing = {
  title: "Browsing",
  history: "History",
  time: {
    now: "Just now",
    minutes: "{{count}} minutes ago",
    hours: "{{count}} hours ago",
    days: "{{count}} days ago",
    weeks: "{{count}} weeks ago",
    months: "{{count}} months ago",
    years: "{{count}} years ago",
  },
  aiStatus: {
    searching: "Searching",
    analyzing: "Analyzing",
    reading: "Reading",
    thinking: "Thinking",
    planning: "Planning",
    researching: "Researching",
    reporting: "Reporting",
  },
  mode: {
    llm: "AI Chat",
    llmTooltip: "Tenak AI answers in natural language",
    web: "WEB Search",
    webTooltip: "URL or automatic search to generate answers based on the latest information.",
    youtube: "YOUTUBE Search",
    youtubeTooltip: "Analyze or search for YOUTUBE videos to answer.",
    research: "Research Agent",
    researchTooltip:
      "Analyze messages and generate reports by referring to multiple sources. Not available if the history contains images. Use PRO mode.",
  },
  preTitle: "Browsing with ",
  subtitle: "Utilizing web information to provide the latest and most accurate answers.",
  browsingMode: "Browsing Mode",
  browsingModeDescription: "URL or automatic search to generate answers based on the latest information.",
  search: "Search",
  send: "Send (Ctrl + Enter)",
  pro: "PRO Mode",
  proTooltip: "PRO Mode uses the OPEN AI reasoning model to provide answers using advanced analysis.",
  proDisableTooltip: "PRO mode (Reasoning model) cannot be used in chat spaces that include image data.",
  searchPlaceholder: "Ask me anything...",
  stopGenerating: "Stop Generating",
  query: "Message",
  answer: "Answer",
  searchMore: "Search More on Google",
  clear: "Clear conversation history",
  delete: "Delete chat",
  customize: "Customize",
  new: "NEW",
  displayHistoryDescribe: "30 messages are displayed",
  noHistory: "No Chat History",
  addChat: "Add a chat",
  createChat: "Created a new chat",
  deleteChat: {
    title: "Are you sure you want to delete the chat?",
    description: "Deleting the chat will remove all data within the chat. This action cannot be undone.",
    success: "The chat has been deleted.",
  },
  deleteHistory: {
    title: "Are you sure you want to delete the conversation history?",
    description: "This will delete the conversation history. This action cannot be undone.",
    success: "The conversation history has been deleted.",
  },
  customizeChat: {
    title: "Customize Chat",
    description: "You can customize the title of the chat and the behavior of Tenak AI.",
    chatTitle: "Chat name",
    chatTitleDescription: "The title will be displayed in the menu.",
    instructionTitle: "Behavior of Tenak AI",
    instructionDescription: "You can customize the behavior of Tenak AI, such as roles and answer formats.",
    instructionTemplate:
      "# Role \n- You are an excellent AI assistant. \n \n# Constraints\n- Answer concisely\n\n# Output format\n- Always answer in English",
    instructionPlaceholder: "You are an excellent AI assistant.",
    setTemplate: "Apply Sample Template",
    success: "The chat has been customized.",
  },
  article: "Generate Article from Answer",
  mindMap: "Generate Mind Map from Answer",
  summary: "Summarize Answer",
  attachImage: "Attach Image",
  save: "Save Answer to NotesNebula",
  error: {
    search: "No search results were found or the server was overloaded and could not retrieve the search results.",
  },
  url: {
    title: "Reference URL",
    result: {
      success: "Referenced the URL.",
      error: "Failed to reference the URL. Please insert the text directly.",
    },
  },
  sidebar: {
    preSearchTitle: "SOURCE",
    preSearchDescription:
      "When Browsing Mode is turned ON, search results will be displayed and used for reference in responses.",
  },
  caution: "AI may give wrong answers. Be sure to double check.",
};
export default browsing;
