import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import MoneyIcon from "@mui/icons-material/Money";
import PaletteIcon from "@mui/icons-material/Palette";
import { Container, Fade, Grid, useTheme } from "@mui/material";
import Grow from "@mui/material/Grow";
import Typography from "@mui/material/Typography";
import { Box, styled } from "@mui/system";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { JunoLogo } from "../../components/common/JunoLogo";
import Meta from "../../components/common/Meta";
import { NotesNebulaLogo } from "../../components/common/NotesNebulaLogo";
import { TenakLogo } from "../../components/common/TenakLogo";
import { getDataLayer } from "../../context/EventContext";
import { useLoginStatus } from "../../context/LoginStatusContext";
import { useCustomNavigate } from "../../hooks/useCustomNavigate";
import { ColumnCenteredBox, RowBox } from "../../utils/styledBox";
import { default as AIButton, default as AIs } from "./components/AIs";
import Browsing from "./components/Browsing";
import Image from "./components/Image";
import Mindmap from "./components/Mindmap";
import NotesNebula from "./components/NotesNebula";
import Text from "./components/Text";
import Voice from "./components/Voice";
import WritingAI from "./components/WritingAI";

const MemoizedBrowsing = React.memo(Browsing);
const MemoizedText = React.memo(Text);
const MemoizedMindmap = React.memo(Mindmap);
const MemoizedImage = React.memo(Image);
const MemoizedVoice = React.memo(Voice);
const MemoizedAIs = React.memo(AIButton);
const MemoizedWriting = React.memo(WritingAI);
const NotesNebulaAIs = React.memo(NotesNebula);

const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { user } = useLoginStatus();
  const [data, setData] = React.useState<any>(null);
  const massage = getDataLayer("DashboadMessage")?.message;

  const getData = useCallback(async () => {
    try {
      const csrfToken = Cookies.get("csrftoken");
      const response = await axios.get("/api/v1/dash-board/", {
        headers: {
          "X-CSRFToken": csrfToken,
        },
      });
      if (response.status === 200) {
        setData(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  const StyledBox = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 16,
    // backgroundColor: theme.palette.background.custom1,
    borderRadius: 8,
    width: "100%",
    // height: "100%",
  }));

  const browsingProps = useMemo(() => ({ data, getData }), [data, getData]);
  const textProps = useMemo(() => ({ data }), [data]);
  const mindmapProps = useMemo(() => ({ data }), [data]);
  const navigate = useCustomNavigate();

  return (
    <>
      <Meta title={t("dashboard.title")} />
      <Fade in={true} timeout={1500}>
        <Container maxWidth={false} sx={{ padding: "0px!Important" }}>
          <Meta title={t("dashboard.title")} />

          <Grid container spacing={0}>
            {/* 通知 */}
            <Grid item xs={12}>
              {massage && (
                <Grid item xs={12} sm={12}>
                  <Grow in={true} timeout={1000}>
                    <StyledBox>
                      <Typography variant={"body1"}>
                        {getDataLayer("DashboadMessage")?.message || "Dashboard"}
                      </Typography>
                    </StyledBox>
                  </Grow>
                </Grid>
              )}
            </Grid>
            {/* TENAK */}
            <Grid item xs={12} sm={4} sx={{ display: "flex" }}>
              <ColumnCenteredBox
                sx={{ background: (theme) => theme.palette.background.custom1, pb: 8, px: 4, height: "100%" }}
              >
                <StyledBox sx={{ mt: 8 }}>
                  <TenakLogo onClick={() => navigate("")} />
                </StyledBox>

                <Typography variant="body2" sx={{ mb: 2 }}>
                  {t("dashboard.tenak.description")}
                </Typography>

                <StyledBox>{data && <MemoizedBrowsing {...browsingProps} />}</StyledBox>
                {/* Mind Map */}
                <StyledBox>{data && <MemoizedMindmap {...mindmapProps} />}</StyledBox>

                <StyledBox>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <MemoizedWriting />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MemoizedVoice />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <RowBox sx={{ height: 48, mb: -2, mt: 2 }}>
                        <Typography
                          variant={"h6"}
                          component={"h3"}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <AutoAwesomeIcon sx={{ color: theme.palette.secondary.main, mr: 2 }} />
                          {t("dashboard.misc")}
                        </Typography>
                      </RowBox>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <AIButton
                        title={t("dashboard.tenak.color")}
                        icon={<PaletteIcon />}
                        path="/color-generator"
                        img="/images/dashBoard/color.jpg"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <AIButton
                        title={t("dashboard.tenak.template")}
                        icon={<MoneyIcon />}
                        path="/template"
                        img="/images/dashBoard/prompt.jpg"
                      />
                    </Grid>
                  </Grid>
                </StyledBox>
                <Box sx={{ flexGrow: 1 }} />
              </ColumnCenteredBox>
            </Grid>

            {/* STUDIO JUNO */}
            <Grid item xs={12} sm={4} sx={{ display: "flex" }}>
              <ColumnCenteredBox
                sx={{
                  background: (theme) => theme.palette.background.paper,
                  pb: 8,
                  px: 4,
                  height: "100%",
                }}
              >
                <StyledBox sx={{ mt: 8 }}>
                  <JunoLogo onClick={() => navigate("/juno")} />
                </StyledBox>

                <Typography variant="body2" sx={{ mb: 2 }}>
                  {t("dashboard.juno.description")}
                </Typography>

                <StyledBox>{data && <MemoizedImage />}</StyledBox>
                <Box sx={{ flexGrow: 1 }} />
              </ColumnCenteredBox>
            </Grid>

            {/* Notes Nebula */}
            <Grid item xs={12} sm={4} sx={{ fisplay: "flex" }}>
              <ColumnCenteredBox
                sx={{ background: (theme) => theme.palette.background.custom1, pb: 8, px: 4, height: "100%" }}
              >
                <StyledBox sx={{ mt: 8 }}>
                  <NotesNebulaLogo onClick={() => navigate("/notes-nebula")} />
                </StyledBox>

                <Typography variant="body2" sx={{ mb: 2 }}>
                  {t("dashboard.notesNebula.description")}
                </Typography>

                <StyledBox>
                  <AIs
                    title={t("dashboard.notesNebula.read")}
                    icon={<LibraryBooksIcon />}
                    path="/notes-nebula"
                    img="/images/notes-nebula/top.jpg"
                    mainTitle={t("dashboard.notesNebula.read")}
                  />
                </StyledBox>
                <StyledBox>{data && <MemoizedText {...textProps} />}</StyledBox>
                <Box sx={{ flexGrow: 1 }} />
              </ColumnCenteredBox>
            </Grid>
          </Grid>
        </Container>
      </Fade>
    </>
  );
};

export default React.memo(Dashboard);
