import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { ButtonBase, CircularProgress, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import Cookies from "js-cookie";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useAlert } from "../../../../context/AlertContext";
import { useMindMap } from "../../../../context/MindmapContext";
import { useCheckCredit } from "../../../../hooks/useCreditCheck";

interface CreateNodesButtonProps {
  /** 選択中のノードID */
  selectedNode: string | null;
}

/**
 * AIを使用して新しいノードを生成するボタンコンポーネント
 */
export const CreateNodesButton: React.FC<CreateNodesButtonProps> = ({ selectedNode }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { checkCredit } = useCheckCredit();
  const { setAlert } = useAlert();
  const { mindMapUuid } = useParams();
  const [nodeAdding, setNodeAdding] = useState(false);
  const { nodes, setNodes, edges, setEdges, nodeDefaults, edgeType, animation } = useMindMap();

  const createAddNode = async (id: string) => {
    setNodeAdding(true);
    if (!(await checkCredit())) return;

    const getFirstLine = (label: string): string => {
      const firstLine = label.split("\n")[0];
      const firstSentence = firstLine.split(".").length > 1 ? firstLine.split(".")[0] : firstLine.split("。")[0];
      return firstSentence;
    };

    const nodeParams = nodes.map((node) => ({
      id: node.id,
      label: getFirstLine(node.data.label),
    }));

    const edgeParams = edges.map((edge) => ({
      source: edge.source,
      target: edge.target,
      type: edge.type || "default",
      animated: edge.animated ?? false,
    }));

    axios.defaults.withCredentials = true;
    const csrftoken = Cookies.get("csrftoken");

    try {
      const res = await axios.post(
        `/api/v1/mind-map/${mindMapUuid}/additional-nodes`,
        {
          nodes: nodeParams,
          edges: edgeParams,
          targetNodeId: id,
        },
        {
          headers: { "X-CSRFToken": csrftoken },
        }
      );

      if (res.data.status === "success") {
        const labels = res.data.data;
        await addNodes(id, labels);
        setAlert("success", t("mindmap.alert.createNodes"));
      }
    } catch (error) {
      console.error("API call failed:", error);
      setAlert("error", t("mindmap.notice.error"));
    } finally {
      setNodeAdding(false);
    }
  };

  const addNodes = async (id: string, labels: string[]) => {
    const selected_node_position = nodes.find((node) => node.id === id)?.position;
    labels.forEach((label: string, index: number) => {
      const newNode = {
        id: `${nodes.length + index + 1}`,
        type: "custom",
        position: {
          x: (selected_node_position?.x || 0) + 500,
          y: (selected_node_position?.y || 0) + index * 200,
        },
        data: { label: label["label"], color: "", links: [], images: [] },
        ...nodeDefaults,
      };
      setNodes((prevNodes) => [...prevNodes, newNode]);

      const newEdge = {
        id: `e${edges.length + index + 1}`,
        source: id,
        target: newNode.id,
        animated: animation,
        type: edgeType,
      };
      setEdges((prevEdges) => [...prevEdges, newEdge]);
    });
  };

  return (
    <Tooltip arrow title={t("mindmap.createNodesTooltip")}>
      <span>
        <ButtonBase
          disabled={!selectedNode || nodeAdding}
          color="primary"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            if (selectedNode) {
              createAddNode(selectedNode).then();
            }
          }}
          sx={{
            zIndex: 1000,
            py: 1,
            px: 2,
            transition: "color 0.2s ease-in-out",
            "&:hover": { color: theme.palette.primary.main },
            "&.Mui-disabled": {
              opacity: 0.5,
              color: theme.palette.text.disabled,
            },
          }}
        >
          {nodeAdding ? (
            <CircularProgress size={20} sx={{ mr: 1 }} />
          ) : (
            <AutoAwesomeIcon sx={{ mr: 1 }} fontSize="small" />
          )}
          <Typography variant="button">{t("mindmap.createNodes")}</Typography>
        </ButtonBase>
      </span>
    </Tooltip>
  );
};

CreateNodesButton.propTypes = {
  selectedNode: PropTypes.string,
};
