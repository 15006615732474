import {
  Collections as CollectionsIcon,
  Explore as ExploreIcon,
  Favorite as FavoriteIcon,
  FlashOn as FlashOnIcon,
  MovieFilter as MovieFilterIcon,
  PhotoFilter as PhotoFilterIcon,
  Search as SearchIcon,
  VideoLibrary as VideoLibraryIcon,
} from "@mui/icons-material";
import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Meta from "../../../components/common/Meta";
import { DrawerHeader } from "../../../components/layout/Drawer/DrawerMain";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import JunoRouter from "../../../routes/JunoRouter";
import { Search, SearchIconWrapper, StyledInputBase } from "../../../utils/searchBox";
import { uiHeight } from "../../../utils/uiHeight";
import FilteredButton from "./image/FilteredButton";

const Layout = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [tabValue, setTabValue] = useState("juno");
  const navigate = useCustomNavigate();

  const tabs = [
    {
      value: "juno",
      path: "/juno?page=1",
      icon: <ExploreIcon sx={{ mr: 1 }} fontSize="small" />,
      label: t("juno.explore"),
    },
    {
      value: "gallery",
      path: "/juno/gallery",
      icon: <CollectionsIcon sx={{ mr: 1 }} fontSize="small" />,
      label: t("juno.myGallery"),
    },
    {
      value: "videos",
      path: "/juno/videos",
      icon: <VideoLibraryIcon sx={{ mr: 1 }} fontSize="small" />,
      label: t("juno.videos"),
    },
    {
      value: "likes",
      path: "/juno/likes",
      icon: <FavoriteIcon sx={{ mr: 1 }} fontSize="small" />,
      label: t("juno.likes"),
    },
    {
      value: "models",
      path: "/juno/models",
      icon: <FlashOnIcon sx={{ mr: 1 }} fontSize="small" />,
      label: t("juno.models"),
    },
    {
      value: "image-generator",
      path: "/juno/image-generator",
      icon: <PhotoFilterIcon sx={{ mr: 1 }} fontSize="small" />,
      label: t("juno.goToGenerator"),
    },
    {
      value: "video-generator",
      path: "/juno/video-generator",
      icon: <MovieFilterIcon sx={{ mr: 1 }} fontSize="small" />,
      label: t("juno.goToVideoGenerator"),
    },
  ];

  useEffect(() => {
    const pathSegment = location.pathname.split("/")[3] || "";
    const matchedTab = tabs.find((tab) => pathSegment.startsWith(tab.value));
    setTabValue(matchedTab ? matchedTab.value : "juno");
  }, [location.pathname]);

  const search = (q: string) => {
    navigate(q === "" ? "/juno" : `/juno?q=${q}`);
  };

  return (
    <>
      <Meta title={t("juno.goToGenerator")} />
      <Box sx={{ minHeight: uiHeight() }}>
        <DrawerHeader />
        <Grid container sx={{ px: 2, mb: 1 }}>
          <Grid
            item
            xs={12}
            md={4}
            lg={2}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 4,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search Images…"
                  inputProps={{ "aria-label": "search" }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.nativeEvent.isComposing) {
                      search((e.target as HTMLInputElement).value);
                    }
                  }}
                />
              </Search>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={7.5}
            lg={9.5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Tabs
              value={tabValue}
              variant="scrollable"
              aria-label="navigation tabs"
              sx={{ maxWidth: "90vw" }}
              scrollButtons
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab.value}
                  value={tab.value}
                  onClick={() => navigate(tab.path)}
                  icon={
                    <Box display="flex" alignItems="center">
                      {tab.icon}
                      <Typography variant="button">{tab.label}</Typography>
                    </Box>
                  }
                />
              ))}
            </Tabs>
          </Grid>
          <Grid item xs={12} md={0.5} lg={0.5} display="flex">
            <Box flexGrow={1} />
            <FilteredButton />
          </Grid>
        </Grid>
        <JunoRouter />
      </Box>
    </>
  );
};

export default memo(Layout);
