// useMethods.ts

import axios from "axios";
import Cookies from "js-cookie";
import { useParamsContext } from "../context/juno/ParamsContext";
import { useUploadImageContext } from "../context/juno/UploadImageContext";
import { useUpscaleContext } from "../context/juno/UpscaleContext";
import { useVideo } from "../context/juno/VideoContext";
import { AiEngineList, ImageInfo } from "../types/junoTypes";
import { getFileDateName } from "../utils/utils";
import { useCustomNavigate } from "./useCustomNavigate";

export const useJunoMethods = () => {
  const navigate = useCustomNavigate();
  const paramsContext = useParamsContext();
  const upscaleContext = useUpscaleContext();
  const uploadImageContext = useUploadImageContext();
  const useVideoContext = useVideo();

  const handleSetSameParams = (params: any) => {
    const {
      setPrompt,
      setWidthPartial,
      setHeightPartial,
      setStep,
      setGuidanceScale,
      setSeed,
      setNegativePrompt,
      setSample,
      setRevisedPrompt,
      setStyle,
      setStyleWeight,
      setAspectRatio,
      setRawMode,
    } = paramsContext;

    let aiEngine = newVersionAiEngine(params.ai_engine) as AiEngineList;

    const setCommonParams = (aiEngine: AiEngineList) => {
      if (params.width) setWidthPartial(aiEngine, params.width);
      if (params.height) setHeightPartial(aiEngine, params.height);
      if (params.step) setStep((prev) => ({ ...prev, [aiEngine]: params.step }));
      if (params.guidance_scale) setGuidanceScale((prev) => ({ ...prev, [aiEngine]: params.guidance_scale }));
      if (params.seed) setSeed((prev) => ({ ...prev, [aiEngine]: params.seed }));
      if (params.negative_prompt) setNegativePrompt(params.negative_prompt);
      if (params.sample) {
        setSample((prev) => ({ ...prev, [aiEngine]: params.sample }));
      } else {
        setSample((prev) => ({ ...prev, [aiEngine]: 1 }));
      }
    };

    setPrompt(params.prompt);

    switch (aiEngine) {
      case "DallE3":
        setWidthPartial("DallE3", params?.width || 1024);
        setHeightPartial("DallE3", params?.height || 1024);
        setStyle((prev) => ({ ...prev, [aiEngine]: params.style }));
        setRevisedPrompt(false);
        break;

      case "SD35":
      case "SDUltra":
        setAspectRatio((prev) => ({ ...prev, [aiEngine]: params.aspect_ratio }));
        setCommonParams(aiEngine);
        break;

      default:
        if (aiEngine.includes("Flux")) {
          if (params.aspect_ratio) setAspectRatio((prev) => ({ ...prev, [aiEngine]: params.aspect_ratio }));
          if (params.raw) setRawMode(params.raw);
          if (params.style) setStyle((prev) => ({ ...prev, [aiEngine]: params.style }));
          if (params.style_weight) setStyleWeight((prev) => ({ ...prev, [aiEngine]: params.style_weight }));
        }
        if (aiEngine.includes("RecraftV3")) {
          if (params.aspect_ratio) setAspectRatio((prev) => ({ ...prev, [aiEngine]: params.aspect_ratio }));
          if (params.style) setStyle((prev) => ({ ...prev, [aiEngine]: params.style }));
        }
        if (["Ideogram2", "Ideogram2Turbo"].includes(aiEngine)) {
          setAspectRatio((prev) => ({ ...prev, [aiEngine]: params.aspect_ratio }));
          setSeed((prev) => ({ ...prev, [aiEngine]: params.seed }));
          setStyle((prev) => ({ ...prev, [aiEngine]: params.style }));
        }
        break;
    }

    navigate(`/juno/image-generator?menu=${params.action}&ai_engine=${aiEngine}`);
  };

  // AI Engineのバージョンを生成用に最新に変更する
  const newVersionAiEngine = (aiEngine: string): string => {
    switch (aiEngine) {
      case "Flux1Pro":
        return "Flux11Pro";
      case "SD3":
        return "SD35";
      default:
        return aiEngine;
    }
  };

  const handleImg2Img = async (image_uuid: string, params: any) => {
    try {
      const { setUuidParent } = upscaleContext;
      const { setImage } = uploadImageContext;
      handleSetSameParams(params);
      setUuidParent(image_uuid);
      const response = await axios.get(`/api/v1/juno/image/${image_uuid}`, { responseType: "blob" });
      const blob = new Blob([response.data], { type: response.data.type });

      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result as string;
        setImage(base64data);
      };
      navigate(`/juno/image-generator?menu=img2img&ai_engine=${params.ai_engine}`);
    } catch (error) {
      console.error("画像の取得に失敗しました:", error);
    }
  };

  const handleImg2Vid = async (image_uuid: string, prompt: any) => {
    try {
      const { setImage1, setPrompt } = useVideoContext;
      const response = await axios.get(`/api/v1/juno/image/${image_uuid}`, { responseType: "blob" });
      const blob = new Blob([response.data], { type: response.data.type });

      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result as string;
        setImage1(base64data);
        setPrompt(prompt);
      };
      navigate("/juno/video-generator");
    } catch (error) {
      console.error("画像の取得に失敗しました:", error);
    }
  };

  const handleUpscale = async (image_uuid: string) => {
    try {
      const { setUuidParent } = upscaleContext;
      const { setImage } = uploadImageContext;
      setUuidParent(image_uuid);
      const response = await axios.get(`/api/v1/juno/image/${image_uuid}`, { responseType: "blob" });
      const blob = new Blob([response.data], { type: response.data.type });

      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result as string;
        setImage(base64data);
      };
      navigate(`/juno/image-generator?menu=upscale&ai_engine=AuraSR`);
    } catch (error) {
      console.error("画像の取得に失敗しました:", error);
    }
  };

  const handleEdit = async (image_uuid: string) => {
    try {
      let image = "";
      const response = await axios.get(`/api/v1/juno/image/${image_uuid}`, { responseType: "blob" });
      const blob = new Blob([response.data], { type: response.data.type });

      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result as string;
        image = base64data;
        navigate(`/juno/image-generator?menu=editor&ai_engine=Flux1DevFill`, { state: { outsideImage: image } });
      };
    } catch (error) {
      console.error("画像の取得に失敗しました:", error);
    }
  };

  const handleDownload = async (image_uuid: string) => {
    try {
      const response = await axios.get(`/api/v1/juno/image/${image_uuid}`, { responseType: "blob" });
      if (response.data.type === "image/svg+xml") {
        const url = window.URL.createObjectURL(response.data);
        const a = document.createElement("a");
        a.href = url;
        a.download = `juno-${getFileDateName()}.svg`;
        a.click();
        return { success: true };
      }
      // png
      if (response.data.type === "image/png") {
        const url = window.URL.createObjectURL(response.data);
        const a = document.createElement("a");
        a.href = url;
        a.download = `juno-${getFileDateName()}.png`;
        a.click();
        return { success: true };
      }
      // jpg
      if (response.data.type === "image/jpeg") {
        const url = window.URL.createObjectURL(response.data);
        const a = document.createElement("a");
        a.href = url;
        a.download = `juno-${getFileDateName()}.jpg`;
        a.click();
        return { success: true };
      }

      return { success: false };
    } catch (error) {
      console.error("画像の取得に失敗しました:", error);
      return { success: false };
    }
  };

  const fetchHistory = async (): Promise<[ImageInfo][]> => {
    try {
      const url = "/api/v1/juno/generated-images";
      const csrftoken = Cookies.get("csrftoken");
      const headers = {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      };
      const response = await axios.get(url, { headers });
      const res_json: [ImageInfo][] = response.data;
      return res_json;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  const fetchQueuedImages = async (imageRequestUuid: string, action: string): Promise<ImageInfo[]> => {
    try {
      const url = `/api/v1/juno/queued-images?uuid=${imageRequestUuid}&action=${action}`;
      const csrftoken = Cookies.get("csrftoken");

      const headers = {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      };

      const response = await axios.get(url, { headers });
      const res_json: ImageInfo[] = response.data;
      return res_json;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  return {
    handleSetSameParams,
    handleImg2Img,
    handleUpscale,
    handleDownload,
    fetchHistory,
    fetchQueuedImages,
    handleImg2Vid,
    handleEdit,
  };
};
