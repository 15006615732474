import SmartToyIcon from "@mui/icons-material/SmartToy";
import { Box, CircularProgress, Container, Divider, Grid, Paper, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
interface CreditDataItem {
  name: string;
  type: string;
  list_price: number | { input: number; output: number };
  minimum_unit: number;
  cost_credit_per_unit: number | { input: number; output: number };
  offer_credit_per_unit: number | { input: number; output: number };
}

interface CreditItemRowProps {
  title?: string;
  description: string;
  cost?: string;
}

// Reusable component for credit items
const CreditItemRow: React.FC<CreditItemRowProps> = ({ title, description, cost }) => (
  <Grid container spacing={2} justifyContent="center" px={1} mb={2}>
    {title && (
      <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
        <SmartToyIcon sx={{ color: "primary.main", mr: 1 }} />
        <Typography variant="h6">{title}</Typography>
      </Grid>
    )}
    {description && (
      <Grid item xs={10} md={9} sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body2" sx={{ mr: 1 }} color={"primary.main"}>
          ・
        </Typography>
        <Typography variant="body1">{description}</Typography>
      </Grid>
    )}
    {cost && (
      <Grid item xs={2} md={3}>
        <Typography textAlign="right">{cost}</Typography>
      </Grid>
    )}
  </Grid>
);

// APIから取得したデータを整形するヘルパー関数
const formatCreditData = (apiData: CreditDataItem[]) => {
  const formattedData = {
    text: {
      sectionTitle: "pricing.aboutCredit.text.title",
      items: apiData
        .filter(
          (item) =>
            item.type === "chat" &&
            (item.name === "openai-gpt4o" || item.name === "openai-gpt4o-mini" || item.name === "openai-o3-mini")
        )
        .flatMap((item) => [
          {
            description: `pricing.aboutCredit.text.${item.name}-input`,
            cost: `${(item.offer_credit_per_unit as { input: number }).input.toFixed(1)} / 1M tokens`,
          },
          {
            description: `pricing.aboutCredit.text.${item.name}-output`,
            cost: `${(item.offer_credit_per_unit as { output: number }).output.toFixed(1)} / 1M tokens`,
          },
        ]),
    },
    images: {
      sectionTitle: "pricing.aboutCredit.image.title",
      items: apiData
        .filter((item) => item.type === "image")
        .map((item) => ({
          description: `pricing.aboutCredit.image.${item.name}`,
          cost: `${(item.offer_credit_per_unit as number).toFixed(1)} / img`,
        })),
    },
    videos: {
      sectionTitle: "pricing.aboutCredit.video.title",
      items: apiData
        .filter((item) => item.type === "video")
        .map((item) => ({
          description: `pricing.aboutCredit.video.${item.name}`,
          cost: `${((item.offer_credit_per_unit as number) * 1).toFixed(1)} / 1sec`,
        })),
    },
    voice: {
      sectionTitle: "pricing.aboutCredit.voice.title",
      items: [
        ...apiData
          .filter((item) => item.type === "voice")
          .map((item) => ({
            description: `pricing.aboutCredit.voice.${item.name}`,
            cost: `${(item.offer_credit_per_unit as number).toFixed(1)}`,
          })),
        {
          description: "pricing.aboutCredit.voice.openai-tts-hd-advanced",
          cost: "20.0",
        },
      ],
    },
    mindmap: {
      sectionTitle: "pricing.aboutCredit.mindmap.title",
      description: "pricing.aboutCredit.mindmap.description",
      cost: "0",
    },
  };

  return formattedData;
};

const AboutCredit: React.FC = () => {
  const { t } = useTranslation();
  const [creditData, setCreditData] = useState<ReturnType<typeof formatCreditData> | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCreditData = async () => {
      try {
        const response = await axios.get("/api/v1/credit/data/");
        const formattedData = formatCreditData(response.data.data);
        setCreditData(formattedData);
      } catch (error) {
        console.error("Error fetching credit data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCreditData();
  }, []);

  if (loading || !creditData) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Paper elevation={1} sx={{ py: 8, boxShadow: 0, borderRadius: 0 }}>
      <Container maxWidth="md">
        {/* クレジットについて */}
        <Box>
          <Typography variant="h4" gutterBottom>
            {t("pricing.aboutCredit.title")}
          </Typography>
          <Typography paragraph sx={{ my: 4 }}>
            {t("pricing.aboutCredit.description")}
          </Typography>
        </Box>

        <Divider sx={{ mb: 2 }} />

        {/* Text Credit */}
        <CreditItemRow title={t(creditData.text.sectionTitle)} description="" cost="" />
        {creditData.text.items.map((item, index) => (
          <CreditItemRow key={`text-${index}`} description={t(item.description)} cost={item.cost} />
        ))}
        <Typography variant="body2" sx={{ my: 2, px: 1, textAlign: "right" }}>
          {t("pricing.aboutCredit.text.note")}
        </Typography>

        <Divider sx={{ mb: 2 }} />

        {/* 画像生成 */}
        <CreditItemRow title={t(creditData.images.sectionTitle)} description="" cost="" />
        {creditData.images.items.map((item, index) => (
          <CreditItemRow key={`image-${index}`} description={t(item.description)} cost={item.cost} />
        ))}

        <Divider sx={{ mb: 2 }} />

        {/* 動画生成 */}
        <CreditItemRow title={t(creditData.videos.sectionTitle)} description="" cost="" />
        {creditData.videos.items.map((item, index) => (
          <CreditItemRow key={`video-${index}`} description={t(item.description)} cost={item.cost} />
        ))}

        <Divider sx={{ mb: 2 }} />

        {/* 音声生成 */}
        <CreditItemRow title={t(creditData.voice.sectionTitle)} description="" cost="" />
        {creditData.voice.items.map((item, index) => (
          <CreditItemRow key={`voice-${index}`} description={t(item.description)} cost={item.cost} />
        ))}
        <Typography variant="body2" sx={{ my: 2, px: 1, textAlign: "right" }}>
          {t("pricing.aboutCredit.voice.note")}
        </Typography>

        <Divider sx={{ mb: 2 }} />

        {/* Mindmap生成 */}
        <CreditItemRow
          title={t(creditData.mindmap.sectionTitle)}
          description={t(creditData.mindmap.description)}
          cost={creditData.mindmap.cost}
        />

        <Divider sx={{ mb: 4 }} />

        {/* Annotation */}
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Typography variant="caption">{t("pricing.aboutCredit.annotation")}</Typography>
        </Box>
      </Container>
    </Paper>
  );
};

export default AboutCredit;
