import AltRouteIcon from "@mui/icons-material/AltRoute";
import BrushIcon from "@mui/icons-material/Brush";
import FontDownloadIcon from "@mui/icons-material/FontDownload";
import FormatShapesIcon from "@mui/icons-material/FormatShapes";
import ImageIcon from "@mui/icons-material/Image";
import MovieIcon from "@mui/icons-material/Movie";
import PhotoSizeSelectLargeIcon from "@mui/icons-material/PhotoSizeSelectLarge";
import RouteIcon from "@mui/icons-material/Route";
import TwoKIcon from "@mui/icons-material/TwoK";
import { Fade, Tooltip, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Meta from "../../components/common/Meta";
import VipLabel from "../../components/common/VipLabel";
import { useCustomNavigate } from "../../hooks/useCustomNavigate";
import { useJunoModels } from "../../hooks/useJunoModels";
import { ColumnCenteredBox, RowBox } from "../../utils/styledBox";

type Model = {
  value: string;
  label: string;
  description: string;
  category?: string[];
  type?: string[]; // どのリストに属しているかを追加
};

type ModelsList = {
  txt2img: Model[];
  img2img: Model[];
  upscale: Model[];
  editor: Model[];
};

export default function Models() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { getImageModels, getVideoModels } = useJunoModels();
  const imageModelList: ModelsList = getImageModels;
  const videoModelList = getVideoModels;
  const navigate = useCustomNavigate();

  const mergeModels = (models: ModelsList): Model[] => {
    const merged: Record<string, Model> = {};

    // txt2img のモデルに type を追加
    models.txt2img.forEach((model) => {
      if (!merged[model.value]) {
        merged[model.value] = { ...model, type: ["txt2img"] };
      } else {
        merged[model.value].type?.push("txt2img");
      }
    });

    // img2img のモデルに type を追加
    models.img2img.forEach((model) => {
      if (!merged[model.value]) {
        merged[model.value] = { ...model, type: ["img2img"] };
      } else {
        merged[model.value].type?.push("img2img");
      }
    });

    // editor のモデルに type を追加
    models.editor.forEach((model) => {
      if (!merged[model.value]) {
        merged[model.value] = { ...model, type: ["editor"] };
      } else {
        merged[model.value].type?.push("editor");
      }
    });

    // upscale のモデルに type を追加
    models.upscale.forEach((model) => {
      if (!merged[model.value]) {
        merged[model.value] = { ...model, type: ["upscale"] };
      } else {
        merged[model.value].type?.push("upscale");
      }
    });

    return Object.values(merged);
  };

  const ModelCard = ({ item, isVideo }: { item: Model; isVideo: boolean }) => {
    const handleNavigate = (template: Model) => {
      if (template?.type && item.type && item.type.length > 0) {
        navigate(`/juno/image-generator?menu=${item.type[0]}&ai_engine=${template.value}`);
      } else {
        navigate("/juno/video-generator", { state: { aiEngine: template.value } });
      }
    };
    return (
      <Card
        sx={{
          maxWidth: 345,
          bgcolor: theme.palette.mode === "dark" ? "background.paper" : "white",
          boxShadow: 0,
        }}
      >
        <CardActionArea onClick={() => handleNavigate(item)}>
          <CardMedia
            image={
              isVideo
                ? `/images/juno/companies/${item["value"].toLowerCase()}.png`
                : `/images/juno/ai-engine/${item["value"]}.png`
            }
            sx={{
              height: 165,
              position: "relative",
              backgroundSize: isVideo ? "contain" : "cover",
              backgroundColor: (theme) => theme.palette.background.custom1,
              backgroundRepeat: "repeat",
              backgroundPosition: "center",
            }}
          >
            {/* グラデーションオーバーレイ */}
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background:
                  "linear-gradient(to bottom, transparent 60%, rgba(0, 0, 0, 0.4) 80%, rgba(0, 0, 0, 0.8) 100%)",
              }}
            />
            {/* PRO */}
            <Box
              sx={{
                position: "absolute",
                top: 4,
                left: 4,
                zIndex: 1,
              }}
            >
              <RowBox gap={1}>
                {item?.category?.includes("pro") || isVideo ? <VipLabel /> : null}
                {item?.type?.includes("txt2img") && (
                  <Tooltip title={"Text to Image"} arrow placement="top">
                    <FontDownloadIcon
                      sx={{
                        opacity: 0.8,
                        color: "#fff",
                        backgroundColor: "#036c03",
                        borderRadius: 1,
                        p: 0.5,
                      }}
                    />
                  </Tooltip>
                )}
                {item?.type?.includes("img2img") && (
                  <Tooltip title={"Image to Image"} arrow placement="top">
                    <ImageIcon
                      sx={{
                        opacity: 0.8,
                        color: "#fff",
                        backgroundColor: "#0b7780",
                        borderRadius: 1,
                        p: 0.5,
                      }}
                    />
                  </Tooltip>
                )}
                {item?.type?.includes("upscale") && (
                  <Tooltip title={"Upscale Model"} arrow placement="top">
                    <PhotoSizeSelectLargeIcon
                      sx={{
                        opacity: 0.8,
                        color: "#fff",
                        backgroundColor: "#800b0b",
                        borderRadius: 1,
                        p: 0.5,
                      }}
                    />
                  </Tooltip>
                )}
                {item?.type?.includes("editor") && (
                  <Tooltip title={"Editor Model"} arrow placement="top">
                    <BrushIcon
                      sx={{
                        opacity: 0.8,
                        color: "#fff",
                        backgroundColor: "#80570b",
                        borderRadius: 1,
                        p: 0.5,
                      }}
                    />
                  </Tooltip>
                )}
              </RowBox>
            </Box>

            <RowBox
              gap={1}
              sx={{
                position: "absolute",
                top: 4,
                right: 4,
                px: 0.6,
                py: 0.2,
                justifyContent: "flex-end",
              }}
            >
              {item?.category?.includes("juno") && (
                <Tooltip title={t("juno.input.category.juno")} arrow placement="top">
                  <ColumnCenteredBox
                    sx={{
                      opacity: 0.8,
                      backgroundColor: "#3273a8",
                      borderRadius: 1,
                      p: 0.5,
                      color: "#fff",
                      width: 24,
                      height: 24,
                    }}
                  >
                    <Typography variant="caption" fontWeight={"bold"}>
                      J
                    </Typography>
                  </ColumnCenteredBox>
                </Tooltip>
              )}

              {item?.category?.includes("vector") && (
                <Tooltip title={t("juno.input.category.vector")} arrow placement="top">
                  <FormatShapesIcon
                    sx={{
                      opacity: 0.8,
                      color: "#fff",
                      backgroundColor: "#036c03",
                      borderRadius: 1,
                      p: 0.5,
                    }}
                  />
                </Tooltip>
              )}
              {item?.category?.includes("2k") && (
                <Tooltip title={t("juno.input.category.twoK")} arrow placement="top">
                  <TwoKIcon
                    sx={{
                      opacity: 0.8,
                      color: "#fff",
                      backgroundColor: "#0b7780",
                      borderRadius: 1,
                      p: 0.5,
                    }}
                  />
                </Tooltip>
              )}
              {item?.category?.includes("vary") && (
                <Tooltip title={t("juno.input.category.vary")} arrow placement="top">
                  <AltRouteIcon
                    sx={{
                      opacity: 0.8,
                      color: "#fff",
                      backgroundColor: "#4d2464",
                      borderRadius: 1,
                      p: 0.5,
                    }}
                  />
                </Tooltip>
              )}
              {item?.category?.includes("controlNet") && (
                <Tooltip title={t("juno.input.category.controlNet")} arrow placement="top">
                  <RouteIcon
                    sx={{
                      opacity: 0.8,
                      color: "#fff",
                      backgroundColor: "#ce6e00",
                      borderRadius: 1,
                      p: 0.5,
                    }}
                  />
                </Tooltip>
              )}
            </RowBox>

            {/* テキスト */}
            <Typography
              variant="subtitle1"
              component="h4"
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                px: 2,
                pb: 1,
                color: "white",
                zIndex: 1,
                whiteSpace: "break-spaces",
              }}
            >
              {item["label"]}
            </Typography>
          </CardMedia>

          <CardContent sx={{ display: "flex", flexDirection: "column", height: 70 }}>
            {/*説明*/}
            <Box sx={{}}>
              <Tooltip title={item["description"]} arrow placement="top">
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    textOverflow: "ellipsis",
                  }}
                >
                  {item["description"]}
                </Typography>
              </Tooltip>
            </Box>
            <Box flexGrow={1} />
          </CardContent>
        </CardActionArea>
      </Card>
    );
  };

  return (
    <>
      <Meta title={t("juno.modelList.title")} route={"Studio JUNO by Tenak AI"} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          m: 2,
          mb: 8,
        }}
      >
        <Typography variant={"body2"} component={"p"}>
          {t("juno.modelList.description")}
        </Typography>
        <Grid container spacing={2} sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
          {/* txt2img*/}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <RowBox gap={1}>
              <ImageIcon color={"secondary"} />
              <Typography variant={"h6"} component={"h3"}>
                {t("juno.goToGenerator")}
              </Typography>
            </RowBox>
          </Grid>

          {imageModelList &&
            mergeModels(imageModelList).map((item, index) => {
              return (
                <Fade in={true} key={index} timeout={Math.min(index * 100, 2000)}>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
                    <ModelCard item={item} isVideo={false} />
                  </Grid>
                </Fade>
              );
            })}

          {/* video*/}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={4}>
            <RowBox gap={1}>
              <MovieIcon color={"secondary"} />
              <Typography variant={"h6"} component={"h3"}>
                {t("juno.goToVideoGenerator")}
              </Typography>
            </RowBox>
          </Grid>

          {videoModelList &&
            videoModelList.map((item, index) => {
              return (
                <Fade in={true} key={index} timeout={Math.min(index * 100, 2000)}>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
                    <ModelCard item={item} isVideo={true} />
                  </Grid>
                </Fade>
              );
            })}
        </Grid>
      </Box>
    </>
  );
}
