const dashboard = {
  title: "ダッシュボード",
  greeting: "こんにちは、{{name}}。",
  welcome: "ようこそ、Tenak AIへ。",
  search: "調べる、会話する",
  addChat: "チャット作成",
  text: "記事を書く",
  addText: "白紙の文書",
  addMindmap: "白紙のマインドマップ",
  mindmap: "マインドマップ / ブレインストーミングを作る",
  misc: "MINI AI",
  library: "ライブラリ",
  tenak: {
    description: "AIを活用したコンテンツ作成支援AI",
    writingAI: "ライティングAI",
    voice: "音声生成AI",
    mindmap: "マインドマップ生成AI",
    color: "カラーパレット生成AI",
    template: "プロンプトテンプレート",
  },
  juno: {
    description: "画像・動画生成AIのプラットフォーム",
    image: "画像生成AI",
    video: "動画生成AI",
    explore: "みんなの作品",
    t2i: "テキストから画像生成",
    i2i: "画像から画像生成",
    upscale: "画像の拡大",
    models: "モデルリスト",
  },
  notesNebula: {
    description: "AIエディターを利用した記事投稿プラットフォーム",
    read: "記事を探す",
  },
};
export default dashboard;
