import React, { createContext, useContext, useState } from "react";

export type SnsType = "instagram" | "x" | "facebook" | "tiktok" | "youtube" | "threads" | "other" | "pinterest";

// Contextの型定義
type VideoScriptContextType = {
  content: string;
  setContent: React.Dispatch<React.SetStateAction<string>>;
  tone: string;
  setTone: React.Dispatch<React.SetStateAction<string>>;
  srcType: string;
  setSrcType: React.Dispatch<React.SetStateAction<string>>;
  additionalText: string;
  setAdditionalText: React.Dispatch<React.SetStateAction<string>>;
  target: string;
  setTarget: React.Dispatch<React.SetStateAction<string>>;
  sns: SnsType[];
  setSns: React.Dispatch<React.SetStateAction<SnsType[]>>;
  videoLength: number;
  setVideoLength: React.Dispatch<React.SetStateAction<number>>;
  desiredVideo: string;
  setDesiredVideo: React.Dispatch<React.SetStateAction<string>>;
};

// Contextの作成
const VideoScriptContext = createContext<VideoScriptContextType | undefined>(undefined);

// プロバイダコンポーネント
export const VideoScriptProvider: React.FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
  const [content, setContent] = useState<string>("");
  const [tone, setTone] = useState("");
  const [additionalText, setAdditionalText] = useState<string>("");
  const [srcType, setSrcType] = useState<string>("text");
  const [target, setTarget] = useState<string>("");
  const [sns, setSns] = useState<SnsType[]>(["instagram"]);
  const [videoLength, setVideoLength] = useState<number>(30);
  const [desiredVideo, setDesiredVideo] = useState<string>("");

  return (
    <VideoScriptContext.Provider
      value={{
        content,
        setContent,
        tone,
        setTone,
        additionalText,
        setAdditionalText,
        srcType,
        setSrcType,
        target,
        setTarget,
        sns,
        setSns,
        videoLength,
        setVideoLength,
        desiredVideo,
        setDesiredVideo,
      }}
    >
      {children}
    </VideoScriptContext.Provider>
  );
};

// コンテキストを使用するためのカスタムフック
export const useVideoScriptContext = () => {
  const context = useContext(VideoScriptContext);
  if (context === undefined) {
    throw new Error("useVideoScriptContext must be used within a VideoScriptProvider");
  }
  return context;
};
