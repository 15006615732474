import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import React from "react";
import { useTranslation } from "react-i18next";
import { LanguageOption, Provider, VoiceOption } from "../../types";

interface VoiceSelectorProps {
  provider: Provider;
  voiceName: string;
  voiceList: VoiceOption[];
  languageList: LanguageOption[];
  language: string;
  openaiInstructions: string;
  onVoiceNameChange: (event: SelectChangeEvent) => void;
  onLanguageChange: (value: string) => void;
  onInstructionsChange: (instructions: string) => void;
  onInfoClick: () => void;
}

export const VoiceSelector: React.FC<VoiceSelectorProps> = ({
  provider,
  voiceName,
  voiceList,
  languageList,
  language,
  openaiInstructions,
  onVoiceNameChange,
  onLanguageChange,
  onInstructionsChange,
  onInfoClick,
}) => {
  const { t } = useTranslation();

  const getVoiceLabel = (item: VoiceOption): string => {
    if (provider === "google") {
      return `${t(`voice.googleVoiceLabel.${item.type}`)} - ${t(`voice.gender.${item.gender}`)} ${item.voice_number}`;
    }
    return `${t(`voice.openaiVoiceLabel.${item.value}`)} - ${t(`voice.gender.${item.gender}`)}`;
  };

  return (
    <Box>
      {provider === "google" && (
        <FormControl variant="standard" sx={{ width: "100%", mb: 2 }}>
          <InputLabel>Language</InputLabel>
          <Select value={language} onChange={(e) => onLanguageChange(e.target.value)} label="Language">
            {languageList.map((item, index) => (
              <MenuItem key={index} value={item.code}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <Box flexDirection="row" display="flex" alignItems="baseline" justifyContent="space-between">
        <FormControl variant="standard" sx={{ width: "100%" }}>
          <InputLabel shrink>Voice</InputLabel>
          <Select
            value={voiceName}
            onChange={onVoiceNameChange}
            label="Voice"
            disabled={provider === "google" && voiceList.length === 0}
          >
            {voiceList.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {getVoiceLabel(item)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Tooltip title={t("voice.typeDescription")}>
          <IconButton onClick={onInfoClick}>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Collapse in={voiceName.includes("_A")} timeout={500}>
        <Box>
          <Typography variant="body2" gutterBottom sx={{ mb: 2 }} color="secondary">
            {t("voice.advanceVoiceMode")}
          </Typography>
          <TextField
            label={t("voice.instructions")}
            placeholder={t("voice.instructionsPlaceholder")}
            fullWidth
            multiline
            rows={4}
            value={openaiInstructions}
            onChange={(e) => onInstructionsChange(e.target.value)}
            autoFocus
            inputProps={{ maxLength: 400 }}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        </Box>
      </Collapse>
    </Box>
  );
};
