const top = {
  login: "Login",
  logout: "Logout",
  delete: "Delete Account",
  signupHeader: "Sign Up for Free",
  signup: "Sign Up",
  createAccount: "Create Account",
  resetPassword: "Reset Password",
  resetSuccess: "Password reset successful. Please log in.",
  resetError: "Password reset failed. Please try again or contact support@tenak.ai.",
  forgotPassword: "Forgot Password",
  check_email: "Please check your email to confirm your account.",
  agree:
    "By creating an account, you are deemed to have agreed to the following documents. Please read and understand these documents before using the service.",
  tooltip: {
    username: "Username must be 4-16 characters long and consist of lowercase letters, numbers, and underscores.",
    email: "Email must be a valid email address.",
    password:
      "Please make sure your password is 8 to 15 characters and includes at least one lowercase letter, one uppercase letter, one number, and one symbol (@$!%*?&).",
    resetPassword:
      "Please enter the registered email address accurately. It will not be delivered to an unregistered email address.",
  },
  googleLogin: "Login or Sign up with Google",
  fv: {
    welcome: "Welcome to Tenak AI",
    catchCopy: "Navigate the sea of creativity with Tenak AI.",
    description: "A versatile AI assistant supporting everything from daily tasks to creative endeavors",
    whatIsTenak: "All in one with Tenak AI.",
    detail:
      "Tenak AI is a multifunctional AI tool that supports digital content creation. For example, it generates high-quality images with 'STUDIO JUNO' and allows you to easily create SEO-friendly articles of over 10,000 characters using the writing AI. It also can generate mind maps, voices, and color palettes. The created content can be easily published with 'Notes Nebula'. Tenak AI maximizes your creativity.",
    tenak: {
      title: "Tenak AI",
      description: "Tenak AI is a multifunctional AI tool that supports digital content creation.",
    },
    juno: {
      title: "Image Generation AI - STUDIO JUNO featuring Stable Diffusion 3 and DALL-E 3",
      description: "STUDIO JUNO is a platform for video and image generation AI that can use various models.",
      feature1: "Access to the latest AI engines",
      feature1Desc: "STUDIO JUNO offers DALL-E 3, Stable Diffusion 3, Stable Diffusion (XL), and FLUX.1.",
      feature2: "Over 500 community models",
      feature2Desc:
        "Provides over 500 community models for Stable Diffusion (XL). You can choose models specialized for various images.",
      feature3: "Quick image generation AI templates",
      feature3Desc:
        "Use templates for the complex image generation with Stable Diffusion AI, making it easy even for beginners.",
    },
    editor: {
      title: "Next-Generation AI Text Editor",
      description:
        "Tenak AI's text editor supports writing with an AI assistant. Easily add images generated by Studio Juno to your articles and publish them on Notes Nebula.",
    },
    notesNebula: {
      title: "Article Posting with Notes Nebula",
      description:
        "Tenak AI's article publishing platform, Notes Nebula, allows you to easily publish articles written with the AI text editor. ",
      button: "Notes Nebula - Article Publishing Platform",
    },
    seamless: {
      title: "Seamless Integration with Studio Juno",
      description:
        "Seamless integration with Tenak AI's image generation AI, Studio Juno. Easily add images generated by Studio Juno to your articles and publish them on Notes Nebula. Also compatible with mind maps.",
      button: "STUDIO JUNO - Image Generation AI Platform",
    },
    mindmap: {
      title: "Mind Map Generation",
      description:
        "Generate authentic mind maps from text using AI. Can also be generated from YouTube video or article URLs.",
    },
    browsing: {
      title: "Browsing-Enabled AI Chat",
      description:
        "Introducing a chat-based AI interaction system. Utilizes web information to provide the latest and most accurate answers. Customize the AI assistant to your liking.",
    },
    writingAI: {
      title: "Various Writing AIs Tailored to Your Needs",
      description:
        "Tenak AI offers various writing AIs to enhance the efficiency of different tasks.Creates scripts for social media platforms like Instagram, Facebook, and Twitter, as well as blog articles, referencing sources such as Google searches, YouTube, website articles, texts, and keywords.",
      source: {
        google: "Google Search",
        youtube: "YouTube Videos",
        link: "Link",
        text: "Text",
      },
      article: "Various Scripts",
      videoScript: "Short Video Scripts for Social Media",
      videoScriptDesc:
        "Generate scripts (structure, narration) for YouTube videos and short videos using AI. Also generates hashtags and video descriptions.",
      summarize: "Easily Summarize Various Contents",
      summarizeDesc:
        "Generate summaries from YouTube videos or blog article URLs using AI. You can set it to summarize in bullet points or paragraph form.",
      seo: "SEO-Optimized Blog Articles Over 10,000 Characters",
      seoDesc:
        "Search Google for keywords, reference articles highly rated for SEO, suggest structure, and generate headings and content.",
      template: "Writing AI Templates",
      templateDesc:
        "Offers numerous writing AI templates. Easily generate the desired responses with minimal input. Continuously adding new templates based on user feedback.",
    },
    misc: {
      title: "Various Other AI",
      description: "Offering a variety of AI functions and services to enhance your creativity.",
      voice: "Voice Generation",
      voiceDesc: "Generate voice from text using AI. Uses Google and OpenAI voice engines, supporting many languages.",
      color: "Color Palette Generation",
      colorDesc:
        "Generate color palettes from text using AI. For example, inputting 'winter' generates a related palette.",
    },
    cta: {
      title: "Try Tenak AI now and experience its effects.",
      description: "Tenak AI is the best partner to maximize your creativity.",
      signupButton: "Get Started for Free",
      loginButton: "Login",
    },
  },
};
export default top;
