import MovieFilterIcon from "@mui/icons-material/MovieFilter";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import Meta from "../../components/common/Meta";
import VipLabel from "../../components/common/VipLabel";
import { GradientTypography } from "../../utils/gradientTypography";
import { ColumnCenteredBox, RowBox } from "../../utils/styledBox";
import InputPrompt from "./components/video/InputPrompt";
import LatestVideoList from "./components/video/LatestVideoList";
import ParamsManager from "./components/video/ParamsManager";
import SampleVideoList from "./components/video/SampleVideoList";
import SelectAI from "./components/video/SelectAI";
import SelectMenu from "./components/video/SelectMenu";

const VideoGenerator = () => {
  const { t } = useTranslation();

  return (
    <>
      <Meta title={t("juno.video.title")} route={"Studio JUNO by Tenak AI"} />
      <Container maxWidth="xl">
        <ColumnCenteredBox
          sx={{ my: 2, justifyContent: "space-between", flexDirection: { xs: "column", sm: "row" }, gap: 2 }}
        >
          <RowBox>
            <MovieFilterIcon sx={{ mr: 2 }} fontSize={"medium"} />
            <GradientTypography component="h1" variant="h5" align="center" mb={0.5}>
              {t("juno.video.title")}
            </GradientTypography>
            <VipLabel sx={{ ml: 2 }} />
          </RowBox>
          <SelectMenu />
        </ColumnCenteredBox>
        <Box mb={4}>
          <SelectAI />
        </Box>
        <Box mb={4}>
          <InputPrompt />
        </Box>
        <Grid container spacing={6}>
          <Grid item xs={12} md={2.5}>
            <ParamsManager />
          </Grid>
          <Grid item xs={12} md={9.5}>
            <Box mb={4}>
              <LatestVideoList size={9} />
            </Box>
            <Box mb={4}>
              <SampleVideoList />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default VideoGenerator;
