import { Box, Container, Fade, Grid, Paper, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Meta from "../../components/common/Meta";
import { DrawerHeader } from "../../components/layout/Drawer/DrawerMain";
import { useChat } from "../../hooks/useChat";
import { useChatHistory } from "../../hooks/useChatHistory";
import BrowsingListSidebar from "./components/BrowsingListSidebar";
import ChatStatus from "./components/ChatStatus";
import InputBox from "./components/InputBox";
import SearchBox from "./components/SearchBox";
import Top from "./components/Top";

const Chat: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const params = useParams<{ chatUuid: string }>();
  const chatUuid = params.chatUuid ?? "";
  const historyRef = useRef<HTMLDivElement>(null);

  const {
    mode,
    handleModeChange,
    historyData,
    sidebarData,
    processingUuid,
    aiStatus,
    sendMessage,
    stopStream,
    setSelectSourceId,
    isPro,
    setIsPro,
  } = useChat(chatUuid);

  const { makeHistoryElement } = useChatHistory(historyData, sendMessage, processingUuid, setSelectSourceId);

  return (
    <>
      <Meta title={t("browsing.title")} meta={[{ name: "robots", content: "noindex, nofollow" }]} />
      <Container sx={{ transition: "all 0.3s ease-in-out", width: "100%", height: "100vh" }} maxWidth={false}>
        <DrawerHeader />
        <Grid container spacing={3}>
          {/* サイドバー（左） */}
          <Grid item xs={12} md={2.5}>
            <Box
              sx={{
                position: { xs: "static", sm: "sticky" },
                top: 56,
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Fade in={true} timeout={1000}>
                <Paper
                  elevation={theme.palette.mode === "dark" ? 1 : 0}
                  sx={{
                    width: "100%",
                    height: { xs: "100%", sm: "calc(100vh - 72px)" },
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: theme.palette.mode === "dark" ? "background.paper" : "#fff",
                    transition: "all 0.3s ease-in-out",
                  }}
                >
                  <BrowsingListSidebar />
                </Paper>
              </Fade>
            </Box>
          </Grid>

          {/* メインコンテンツ */}
          <Grid item xs={12} md={7} width={"100%"}>
            <Box ref={historyRef} sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
              {historyData.map((historyItem) => (
                <React.Fragment key={historyItem.message_uuid}>
                  <Fade in={true} timeout={1000}>
                    <div>{makeHistoryElement(historyItem)}</div>
                  </Fade>
                </React.Fragment>
              ))}

              <ChatStatus aiStatus={aiStatus} />

              {historyData.length === 0 && (
                <Box
                  sx={{
                    height: "calc(100% - 190px )",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Top />
                </Box>
              )}

              <Box sx={{ mt: 2 }}>
                <Box sx={{ display: "flex", justifyContent: "right", width: "100%" }}>
                  <Typography variant="caption" color="textSecondary" textAlign={"left"}>
                    {t("browsing.caution")}
                  </Typography>
                </Box>
                <Box sx={{ height: 170 }} />
              </Box>
              <Box flexGrow={1} />
            </Box>
            <InputBox
              sendMessage={sendMessage}
              historyData={historyData}
              stopStreamController={stopStream}
              historyRef={historyRef}
              onModeChange={handleModeChange}
              mode={mode}
              isPro={isPro}
              setIsPro={setIsPro}
            />
          </Grid>

          {/* サイドバー（右） */}
          <Grid item xs={12} md={2.5}>
            <Box
              sx={{
                position: { xs: "static", sm: "sticky" },
                top: 56,
                display: "flex",
                flexDirection: "column",
                gap: 2,
                mb: { xs: 19, sm: 0 },
              }}
            >
              <Paper
                elevation={theme.palette.mode === "dark" ? 1 : 0}
                sx={{
                  width: "100%",
                  height: "calc(100vh - 72px)",
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: theme.palette.mode === "dark" ? "background.paper" : "#fff",
                  transition: "all 0.3s ease-in-out",
                }}
              >
                <SearchBox
                  content={
                    sidebarData?.content
                      ? typeof sidebarData.content === "string"
                        ? JSON.parse(sidebarData.content)
                        : sidebarData.content
                      : null
                  }
                  aiStatus={aiStatus}
                />
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Chat;
