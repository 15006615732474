import junoPreset from "./junoPreset";
import junoStyle from "./junoStyle";
import junoVideo from "./junoVideo";

const juno = {
  title: "Studio Juno",
  description: "様々なモデルが扱える動画・画像生成AI",
  explore: "探検",
  goToGenerator: "画像生成",
  goToVideoGenerator: "動画生成",
  models: "モデルリスト",
  myGallery: "マイイメージ",
  videos: "マイビデオ",
  likes: "Likes",
  searchResult: "検索結果：",
  noResult: "検索結果が見つかりませんでした。（正しい英語で入力しているか確認してください）",
  menu: {
    title: "MENU",
    txt2img: "テキストから\n画像生成",
    img2img: "画像から\n画像生成",
    upscale: "アップスケール",
    editor: "イメージエディタ",
  },
  input: {
    engine: {
      title: "AI ENGINE",
      description: "AIエンジンを選択してください。",
      DallE3: "DALL·E 3",
      DallE3Description:
        "柔軟なプロンプトで、希望のが画像を簡単に生成でき、短い英単語の入力も可能です。初心者向けですが、最もクレジットを消費しやすいです。",
      LuminaV2: "Lumina Image V2",
      LuminaV2Description:
        "高解像度で多様なスタイルの画像生成が可能な、効率性と柔軟性を兼ね備えた次世代のオープンソースAI画像生成モデルです。",
      Flux11ProUltra: "FLUX1.1 Pro Ultra",
      Flux11ProUltraDescription:
        "FLUX.1 Proよりも高解像度の4MPの画像生成が可能です。また、RAWモードの選択も可能で自然な画像を生成可能。",
      Flux1Pro: "FLUX.1 Pro",
      Flux11Pro: "FLUX1.1 Pro",
      Flux1ProDescription:
        "FLUX.1シリーズの中で高解像度の画像生成が可能で、プロンプトの柔軟性が高い。また、MidjourneyV6やSD Ultraよりも高解像度の画像生成が可能とされています。",
      Flux1Dev: "FLUX.1 Dev",
      Flux1DevDescription: "FLUX.1 Proよりも画質が劣るが、FLUX.1 Proよりも高速に画像生成が可能です。",
      Flux1Schnell: "FLUX.1 Schnell",
      Flux1SchnellDescription: "FLUX.1 Devよりも画質が劣るが、FLUX.1 Devよりも高速に画像生成が可能です。",
      Flux1RealismLoRA: "FLUX.1 with Realism LoRA",
      Flux1RealismLoRADescription: "FLUX.1シリーズののリアリズムモデルです。リアリズムを重視した画像生成が可能です。",
      FluxJuno: "FLUX.1 DEV JUNO",
      FluxJunoDescription: "FLUXとSTUDIO JUNOのコラボモデルです。STUDIO JUNOの技術を活かしたスタイルを選択できます。",
      FluxPuLID: "PuLID FLUX",
      FluxPuLIDDescription:
        "FLUX.1 Dev をベースとし、元画像の顔の特徴を保持した画像生成が可能です。1枚の人物画像から生成が可能。",
      Flux11ProRedux: "FLUX1.1 Pro Redux",
      Flux11ProReduxDescription:
        "FLUX1.1 ProのReduxモデルです。入力画像をわずかに変化させたバリエーション画像を生成できます。高解像度出力と柔軟なアスペクト比を備えたFLUX1.1 Pro Ultraと組み合わせることで、より高品質な画像生成が可能です。",
      Flux1DevRedux: "FLUX.1 Dev Redux",
      Flux1DevReduxDescription:
        "FLUX.1 DevのReduxモデルです。入力画像をわずかに変化させたバリエーション画像を生成できます。開発者向けに、Flux Dev Licenseの下で提供されています。",
      Flux1SchnellRedux: "FLUX.1 Schnell Redux",
      Flux1SchnellReduxDescription:
        "FLUX.1 SchnellのReduxモデルです。入力画像をわずかに変化させたバリエーション画像を生成できます。より高解像度の画像生成が可能です。",
      Flux11ProUltraRedux: "FLUX1.1 Pro Ultra Redux",
      Flux11ProUltraReduxDescription:
        "FLUX1.1 Pro UltraのReduxモデルです。入力画像をわずかに変化させたバリエーション画像を生成できます。4メガピクセルの高解像度出力と柔軟なアスペクト比により、高品質な画像生成が可能です。",
      Flux1ProCanny: "FLUX.1 Pro Canny",
      Flux1ProCannyDescription:
        "FLUX.1 ProのCannyモデルです。入力画像から抽出されたCannyエッジを用いて、画像の構造を維持したままテキストガイドによる編集を可能にします。特に画像のテクスチャ変更に効果的です。",
      Flux1ProDepth: "FLUX.1 Pro Depth",
      Flux1ProDepthDescription:
        "FLUX.1 ProのDepthモデルです。入力画像から抽出された深度マップを用いて、画像の構造を維持したままテキストガイドによる編集を可能にします。より高解像度の画像生成が可能で、Midjourney ReTextureのような従来のモデルよりも高品質な結果を出力します。",
      Flux1DevCannyLoRA: "FLUX JUNO Canny",
      Flux1DevCannyLoRADescription:
        "FLUX.1 DevのCannyモデルとSTUDIO JUNOのコラボモデルです。入力画像から抽出されたCannyエッジを用いて、リアリズムを重視した画像生成が可能です。開発の容易化のために、FLUX.1 Devをベースにしています。",
      Flux1DevDepthLoRA: "FLUX JUNO Depth",
      Flux1DevDepthLoRADescription:
        "FLUX.1 DevのDepthモデルとSTUDIO JUNOのコラボモデルです。入力画像から抽出された深度マップを用いて、リアリズムを重視した画像生成が可能です。 開発の容易化のために、FLUX.1 Devをベースにしています。",
      Flux1ProJuno: "FLUX.1 Pro JUNO",
      Flux1ProJunoDescription:
        "FLUX.1 ProのJUNOモデルです。FLUX.1 Proの高解像度機能とSTUDIO JUNOの技術を組み合わせ、よりリアルで芸術的な表現が可能です。",
      Flux11ProUltraJuno: "FLUX1.1 Pro Ultra JUNO",
      Flux11ProUltraJunoDescription:
        "FLUX1.1 Pro UltraのJUNOモデルです。4MPの高解像度出力とSTUDIO JUNOの技術を組み合わせ、より優れた画質とアーティスティックな制御を実現します。",
      Flux1ProCannyJuno: "FLUX.1 Pro Canny JUNO",
      Flux1ProCannyJunoDescription:
        "FLUX.1 Pro CannyモデルにSTUDIO JUNOの技術を組み合わせたモデルです。Cannyエッジ検出とSTUDIO JUNOの技術を活用し、構造を維持しながらより正確でリアルな画像編集が可能です。",
      Flux1ProDepthJuno: "FLUX.1 Pro Depth JUNO",
      Flux1ProDepthJunoDescription:
        "FLUX.1 Pro DepthモデルにSTUDIO JUNOの技術を組み合わせたモデルです。深度マップ処理とSTUDIO JUNOの技術を組み合わせ、3D認識に優れた画像編集と生成が可能です。",
      Flux1ProFillJuno: "FLUX.1 Pro Fill JUNO",
      Flux1ProFillJunoDescription:
        "FLUX.1 Pro FillモデルにSTUDIO JUNOの技術を組み合わせたモデルです。高度なインペインティング機能とSTUDIO JUNOの技術を組み合わせ、より自然でコンテキストを意識した画像編集が可能です。",
      Flux1ProFill: "FLUX.1 Pro Fill",
      Flux1ProFillDescription:
        "FLUX.1 ProのFillモデルです。テキスト記述とバイナリマスクを用いて、既存の画像に自然に統合されるシームレスな編集を可能にする、高度なインペインティング機能を提供します。また、画像を元の境界を超えて拡張するアウトペインティングもサポートしています。",
      Flux1DevFill: "FLUX.1 Dev Fill JUNO",
      Flux1DevFillDescription:
        "FLUX.1 DevのFillモデルです。FLUX.1 Devの画像生成機能に、高度なインペインティング機能を追加したモデルです。テキスト記述とバイナリマスクを用いて、既存の画像に自然に統合されるシームレスな編集を可能にします。また、画像を元の境界を超えて拡張するアウトペインティングもサポートしています。",
      KolorsVTO: "Kolors Virtual Try-On",
      KolorsVTODescription:
        "Kolors Virtual Try-Onは、ファッションアイテムを画像に合成することができるモデルです。ファッションアイテムの色やデザインを変更することができます。",
      RecraftV3: "Recraft V3",
      RecraftV3Description:
        "Recraft V3 は、プロンプトの理解力が非常に高く、ユーザーの意図を反映した精密で美しい画像生成が可能です。Midjourneyのように美観的な評価も高い。",
      Ideogram2: "Ideogram 2.0",
      Ideogram2Description:
        "文字入れが得意なモデル。マジックプロンプトを有効にすると、画像生成の際にプロンプトを大幅に省略し、画像プロンプトに沿った画像を生成することができます。",
      Ideogram2Turbo: "Ideogram 2 Turbo",
      Ideogram2TurboDescription:
        "Ideogram 2.0 を調整し、速度を向上させたモデル。マジックプロンプトを有効にすると、画像生成の際にプロンプトを大幅に省略し、画像プロンプトに沿った画像を生成することができます。",
      Ideogram2Edit: "Ideogram 2 Edit",
      Ideogram2EditDescription:
        "Ideogram 2.0 の編集モデル。テキスト記述とバイナリマスクを用いて、既存の画像に自然に統合されるシームレスな編集を可能にする、高度なインペインティング機能を提供します。",
      Ideogram2TurboEdit: "Ideogram 2 Turbo Edit",
      Ideogram2TurboEditDescription:
        "Ideogram 2 Edit を調整し、速度を向上させたモデル。テキスト記述とバイナリマスクを用いて、既存の画像に自然に統合されるシームレスな編集を可能にする。",
      Imagen3: "Imagen 3",
      Imagen3Description:
        "Imagen 3 は、Google が開発した画像生成モデルです。日本文化などにも精通しており、日本の風景やアニメキャラクター、人物などを生成するのに適しています。",
      Imagen3Fast: "Imagen 3 Fast",
      Imagen3FastDescription:
        "Imagen 3 Fast は、Imagen 3 よりも高速に画像生成が可能です。画像生成のスピードを重視する場合に適しています。",
      SD: "Stable Diffusion",
      SDDescription:
        "プロンプトの知識が必要となり、正確に入力しないと画像が破綻しやすく、難易度が高いです。しかし、モデルの種類が多く、他には出力できない種類の画像生成が可能となります。",
      SDXL: "Stable Diffusion XL",
      SDXLDescription:
        "従来のStable Diffusionよりも高解像度の画像生成が可能です。また、SDよりもプロンプトが柔軟になりましたが、モデルの種類がまだ少ないです。",
      AuraSR: "Aura SR",
      AuraSRDescription:
        "従来のアップスケーラーと異なり、次世代のアップスケーリング技術を使用し、元画像の類似性を保持しながら、画像の解像度を向上させます。",
      ClarityUpscaler: "Clarity Upscaler",
      ClarityUpscalerDescription:
        "元画像の類似性を保持しながら、画像を高画質化し、創造性を上げてそエンハンサーとしても素晴らしい性能を発揮します。",
      CreativeUpscaler: "Creative Upscaler",
      CreativeUpscalerDescription:
        "元画像をアップスケールし、欠落した部分を新しく生成することで、画像の解像度を向上させます。最大4,194,304px(約2,048pxの正方形)まで拡大可能です",
      RecraftClarityUpscaler: "Recraft Clarity Upscaler",
      RecraftClarityUpscalerDescription:
        "Recraftが提供するアップスケーラー。元画像の類似性を保持しながら、画像を高画質化し、創造性を上げてそエンハンサーとしても素晴らしい性能を発揮します。",
      RecraftCreativeUpscaler: "Recraft Creative Upscaler",
      RecraftCreativeUpscalerDescription:
        "Recraftが提供するアップスケーラー。元画像をアップスケールし、欠落した部分を新しく生成することで、画像の解像度を向上させます。最大4,194,304px(約2,048pxの正方形)まで拡大可能です",
      SD3: "Stable Diffusion 3 Large",
      SD3Description: "Stable Diffusionの最新バージョンです。プロンプトに忠実で、画像に対する文字入れを得意とします。",
      SD35: "Stable Diffusion 3.5 Large",
      SD35Description:
        "Stability AI 開発している最新モデル、Stable diffusion 3.5。80 億のパラメータ、優れた品質、迅速な順守を備えたモデルでFLUX.1 Devよりクオリティは劣るがプロンプト精度は高い。",
      SDUltra: "Stable Image Ultra",
      SDUltraDescription:
        "Stable Diffusion 3 を含む最も高度なモデルから作成された Ultra は、タイポグラフィ、複雑な構成、ダイナミックな照明、鮮やかな色彩、芸術作品の全体的なまとまりと構造に優れています。",
      RealESRGAN: "Real-ESRGAN",
    },
    category: {
      juno: "STUDIO JUNO オリジナルモデルです。当社のAI技術を活かし、画像生成の幅を広げます。",
      vary: "バリエーション生成モデル: 元画像から画像を再現し、画像の洗練を可能にします。画像の再スタイリング、プロンプト（一部モデルのみ）に合わせたバリエーションを生成",
      twoK: "2Kモデル: 高画質な2K解像度（4MP）の画像生成が可能",
      vector: "ベクターモデル: ベクターイラストを生成にも対応",
      controlNet:
        "ControlNet: 画像生成の制御が可能。元画像からCannyはエッジ検出、Depthは奥行きを生成し、それを下に新たな画像を生成",
      editor: "エディタモデル: 画像を編集し、新たな画像を生成。インペイント、アウトペイントに対応",
    },
    aspectRatio: {
      title: "アスペクト比",
      custom: "カスタム",
    },
    width: "横幅",
    height: "縦幅",
    step: "ステップ数",
    sample: "生成画像数",
    sampleDescription:
      "'横幅'、または'縦幅'が {{px}}px を超える場合、生成される枚数は1枚に制限されます。複数枚生成したい場合、{{px}}px 未満で生成してから、アップスケールすることを推奨します。",
    rawMode: "RAWモード",
    rawModeDescription:
      "人工的ではなく、より自然な美しさを持つ画像が生成されます。他のテキストから画像へのモデルと比較して、RAWモードは人間の被写体の多様性を大幅に高め、自然の写真のリアリティを高めます。",
    resolution: "高解像度の補正（自動適応）",
    resolutionCaution: "生成枚数が1枚に制限されます。",
    resolutionTooltip:
      "高解像度の補正を適用すると、生成された画像の解像度が向上します。横幅か縦幅の何れかが {{px}}px 以上の場合、自動的に適応されます。",
    guidanceScale: "プロンプトの重視度",
    seedSD: "シード値（同モデルのみ有効）",
    dallE3RevisedPrompt: "プロンプトをAI拡張",
    colorSelection: {
      title: "カラーパッレット",
    },
    style: junoStyle,
    basicSettings: "基本設定",
    advancedSettings: "詳細設定",
    negativePrompt: "ネガティブプロンプト",
    negativePromptHistory: "ネガティブプロンプト",
    negativePromptPlaceholder: "例: worst quality, nsfw, bad anatomy, out of focus",
    negativePromptDescription: "画像に含みたくない要素を指定してください。",
    negativePromptAI: "ネガティブプロンプトをAIに生成させる",
    negativePromptTitle: "プリセット名",
    negativePromptSave: "ネガティブプロンプトを保存します。",
    negativePromptList: "保存済みネガティブプロンプト",
    delete: {
      title: "ネガティブプロンプトを削除しますか？",
      description: "保存済みのネガティブプロンプトを削除します。この操作は取り消せません。",
      success: "ネガティブプロンプトが削除されました。",
    },
    image: "元画像",
    imageFace: "元画像（顔）",
    imageHuman: "元画像（人物）",
    imageVTO: "元画像（衣服）",
    imageStrength: "元画像の影響度",
    imageEditor: {
      title: "イメージエディタ",
      mask: "マスク",
      original: "オリジナル",
      open: "エディタを開く",
      openImage: "詳細画像",
      openImageDescription: "画像エディタから自動的にセットされます",
      save: "完了",
      brushSize: "マスクブラシサイズ",
      noImage: "最低1枚の画像をアップロードしてください。",
      reset: "全てをリセット",
      aspectRatio: "生成する画像のサイズ",
      eraser: "消しゴム",
      eraserSize: "消しゴムサイズ",
      select: "画像選択ツール: 移動、拡大、縮小\n（画像選択中にDELETEキーで削除）",
      upload: "画像をアップロード",
      generated: "生成画像からアップロード",
      unsplash: "Unsplashから画像を追加",
      description: "はじめに画像を挿入して下さい。余白とブラシ描画部分に新しい画像が生成されます。",
    },
    idWeight: "顔の特徴の影響度",
    alert: {
      imageType: "ファイルはJPGまたはPNG形式である必要があります。",
      imageSize: "ファイルサイズは10MB以下である必要があります。",
    },
    scale: "スケール",
    scaleInfo: "アップスケール後の拡大倍率",
    creativity: "創造性",
    creativityInfo: "アップスケール時、どの程度の創造性を許容するか指定できます。",
    detail: "テクスチャ",
    detailInfo: "アップスケール時、ディテールの強さを指定できます。",
    resemblance: "元画像の維持度",
    resemblanceInfo: "アップスケールで、どの程度元の画像に寄せるかを指定できます。",
    uploadSuccess: "画像がアップロードされました。",
    uploadError: {
      size: "ファイルサイズは {{size}}MB 以下である必要があります。",
      type: "ファイルは JPG または PNG 形式である必要があります。",
      scale: "画像の長辺は 2048px 以下である必要があります。",
    },
    filtered: "BLOCKED",
    filteredDescription: "ポリシーに違反している可能性があるため、表示されません。",
    show: "表示中 - ポリシーに違反している可能性がある画像",
    hide: "非表示中 - ポリシーに違反している可能性がある画像",
    publicStatus: "公開設定",
    publicDescription:
      "非公開設定はStandardプラン以上で利用可能です。公開を指定していても、非公開に変更される場合があります。",
    public: "公開",
    private: "非公開",
  },
  history: {
    title: "最新の10件",
    input: "テキストを入力",
    dice: "画像生成用のテキストのサンプルをランダムで生成します。* 不正行為防止の為、0.1クレジットを消費します。",
    improve:
      "入力されたテキスト（キーワード）からプロンプトを生成。また、英語に翻訳。（SD非推奨）* 不正行為防止の為、0.1クレジットを消費します。",
    prompt: "プロンプト",
    size: "サイズ",
    upscale: "アップスケール",
    edit: "編集",
    img2img: "画像から画像生成",
    img2vid: "画像から動画生成",
    useParams: "再生成",
    download: "ダウンロード",
    generating: "Generating Images",
    generatingDescription: "画像生成中です。しばらくお待ちください。\nページを離れても生成は続行されます。",
    delete: {
      title: "データを削除しますか？",
      description: "データを削除すると、元に戻すことはできません。",
      success: "データが削除されました。",
    },
    optionalPromptPlaceholder: "画像のプロンプトを入力してください（オプション）",
    notNeededPlaceholder: "画像のプロンプトは不要です",
    promptPlaceholder: "画像のプロンプトを入力してください（英語推奨）",
  },
  modelList: {
    title: "モデルリスト",
    description: "STUDIO JUNO は、画像生成のためのさまざまなモデルを提供しています。",
  },
  video: junoVideo,
  inputPreset: junoPreset,
  message: {
    delete: "画像が削除されました。",
  },
  error: {
    noCredit: "クレジットが不足しています。",
    upgrade: "このモデルを使用するには「ベーシックプラン」以上が必要です。",
  },
  validation: {
    model: "モデルを選択してください。",
    image: "画像をアップロードしてください。",
    prompt: "1,000文字以内でプロンプトを入力してください。",
    negativePrompt: "1,000文字以内でネガティブプロンプトを入力してください。",
  },
  selection: {
    new: "新モデル",
    popularModel: "人気モデル",
    highResolutionModel: "高解像度モデル",
    lowCostModel: "低コストモデル",
    highPrecisionModel: "高精度モデル",
    japanesePortrait: "日本人ポートレート",
    depthToImage: "深度から画像",
    outlineToImage: "アウトラインから画像",
    fixedPerson: "人物の固定",
    changeClothes: "衣服の変更",
    brush: "ブラシ",
  },
};
export default juno;
