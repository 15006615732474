import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import Edit from "@mui/icons-material/Edit";
import ExploreIcon from "@mui/icons-material/Explore";
import FeedIcon from "@mui/icons-material/Feed";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import MoneyIcon from "@mui/icons-material/Money";
import MovieIcon from "@mui/icons-material/Movie";
import PhotoLibrary from "@mui/icons-material/PhotoLibrary";
import SubjectIcon from "@mui/icons-material/Subject";
import VideoLibrary from "@mui/icons-material/VideoLibrary";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLoginStatus } from "../../../context/LoginStatusContext";
import CenterContentsMenu from "./CenterContentsMenu";

interface CenterContentsManagerProps {
  activeMenu: string;
}

const CenterContentsManager = ({ activeMenu }: CenterContentsManagerProps) => {
  const [chats, setChats] = useState([]);
  const { isLoggedIn } = useLoginStatus();

  useEffect(() => {
    if (activeMenu == "" && isLoggedIn) {
      const get_chats = async () => {
        try {
          const res = await axios.get("/api/v1/chat/");
          setChats(res.data.data);
        } catch (error) {
          console.error("An unknown error occurred:", error);
        }
      };

      get_chats();
    }
  }, []);

  const tenakAis = [
    {
      key: "browsing",
      path: chats.length > 0 ? chats[0]["path"] : "",
      icon: <ChatBubbleIcon fontSize={"small"} color={"primary"} sx={{ my: 1 }} />,
      label: "drawer.browsing",
    },
    {
      key: "text",
      path: "/writing-ai/article",
      icon: <SubjectIcon fontSize={"small"} color={"primary"} sx={{ my: 1 }} />,
      label: "drawer.writingAI",
    },
    {
      key: "voices",
      path: "/voice-generator",
      icon: <GraphicEqIcon fontSize={"small"} color={"primary"} sx={{ my: 1 }} />,
      label: "drawer.voiceGenerator",
    },
    {
      key: "mind-maps",
      path: "/mind-map-generator",
      icon: <AccountTreeOutlinedIcon fontSize={"small"} color={"primary"} sx={{ my: 1 }} />,
      label: "drawer.mindmapGenerator",
    },
    {
      key: "color-generator",
      path: "/color-generator",
      icon: <ColorLensIcon fontSize={"small"} color={"primary"} sx={{ my: 1 }} />,
      label: "drawer.colorGenerator",
    },
    {
      key: "templates",
      path: "/template",
      icon: <MoneyIcon fontSize={"small"} color={"primary"} sx={{ my: 1 }} />,
      label: "drawer.templates",
    },
  ];

  const tenakLibraries = [
    {
      key: "text",
      path: "/notes-nebula/files",
      icon: <SubjectIcon fontSize={"small"} color={"primary"} sx={{ my: 1 }} />,
      label: "drawer.text",
    },
    {
      key: "voices",
      path: "/library/voices",
      icon: <GraphicEqIcon fontSize={"small"} color={"primary"} sx={{ my: 1 }} />,
      label: "drawer.voice",
    },
    {
      key: "mind-maps",
      path: "/library/mind-maps",
      icon: <AccountTreeOutlinedIcon fontSize={"small"} color={"primary"} sx={{ my: 1 }} />,
      label: "drawer.mindmap",
    },
  ];

  const junoAis = [
    {
      key: "explore",
      path: "/juno",
      icon: <ExploreIcon fontSize={"small"} color={"primary"} sx={{ my: 1 }} />,
      label: "drawer.explore",
    },
    {
      key: "imageGenerator",
      path: "/juno/image-generator",
      icon: <ImageOutlinedIcon fontSize={"small"} color={"primary"} sx={{ my: 1 }} />,
      label: "drawer.imageGenerator",
    },
    {
      key: "videoGenerator",
      path: "/juno/video-generator",
      icon: <MovieIcon fontSize={"small"} color={"primary"} sx={{ my: 1 }} />,
      label: "drawer.videoGenerator",
    },
  ];

  const junoLibraries = [
    {
      key: "images",
      path: "/juno/gallery",
      icon: <PhotoLibrary fontSize={"small"} color={"primary"} sx={{ my: 1 }} />,
      label: "drawer.myImages",
    },
    {
      key: "videos",
      path: "/juno/videos",
      icon: <VideoLibrary fontSize={"small"} color={"primary"} sx={{ my: 1 }} />,
      label: "drawer.myVideos",
    },
  ];

  const notesAis = [
    {
      key: "articles",
      path: "/notes-nebula",
      icon: <FeedIcon fontSize={"small"} color={"primary"} sx={{ my: 1 }} />,
      label: "drawer.articles",
    },
    {
      key: "textEditor",
      path: "",
      icon: <Edit fontSize={"small"} color={"primary"} sx={{ my: 1 }} />,
      label: "drawer.createText",
    },
  ];

  const notesLibraries = [
    {
      key: "text",
      path: "/notes-nebula/files",
      icon: <SubjectIcon fontSize={"small"} color={"primary"} sx={{ my: 1 }} />,
      label: "drawer.text",
    },
  ];

  const selectedAis = activeMenu === "" ? tenakAis : activeMenu === "juno" ? junoAis : notesAis;
  const selectedLibraries = activeMenu === "" ? tenakLibraries : activeMenu === "juno" ? junoLibraries : notesLibraries;

  return <CenterContentsMenu ais={selectedAis} libraries={selectedLibraries} activeMenu={activeMenu} />;
};
export default CenterContentsManager;
