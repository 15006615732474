const junoStyle = {
  title: "Style",
  weight: "Influence of Style",
  dalle3: {
    vivid: "Vivid (Default)",
    natural: "Natural",
  },
  ideogram: {
    general: "General",
    realistic: "Realistic",
    design: "Design",
    anime: "Anime",
    render3d: "3D Rendering",
  },
  fluxJuno: {
    general: "General",
    anime: "Anime v1.0",
    animePro: "Anime PRO v1.0",
    japanese: "Japanese Portrait v1.0",
    japanesePro: "Japanese Portrait PRO v1.0",
  },
  recraft: {
    any: "General",
    realisticImage: "Realistic Photo",
    realisticImageBW: "Realistic Photo - Black and White",
    realisticImageHardFlash: "Realistic Photo - Hard Flash",
    realisticImageHDR: "Realistic Photo - HDR",
    realisticImageNaturalLight: "Realistic Photo - Natural Light",
    realisticImageStudioPortrait: "Realistic Photo - Studio Portrait",
    realisticImageEnterprise: "Realistic Photo - Enterprise",
    realisticImageMotionBlur: "Realistic Photo - Motion Blur",
    realisticImageEveningLight: "Realistic Photo - Evening Light",
    realisticImageFadedNostalgia: "Realistic Photo - Faded Nostalgia",
    realisticImageForestLife: "Realistic Photo - Forest Life",
    realisticImageMysticNaturalism: "Realistic Photo - Mystic Naturalism",
    realisticImageNaturalTones: "Realistic Photo - Natural Tones",
    realisticImageOrganicCalm: "Realistic Photo - Organic Calm",
    realisticImageRealLifeGlow: "Realistic Photo - Real Life Glow",
    realisticImageRetroRealism: "Realistic Photo - Retro Realism",
    realisticImageRetroSnapshot: "Realistic Photo - Retro Snapshot",
    realisticImageUrbanDrama: "Realistic Photo - Urban Drama",
    realisticImageVillageRealism: "Realistic Photo - Village Realism",
    realisticImageWarmFolk: "Realistic Photo - Warm Folk",
    digitalIllustration: "Digital Illustration",
    digitalIllustrationPixelArt: "Digital Illustration - Pixel Art",
    digitalIllustrationHandDrawn: "Digital Illustration - Hand Drawn",
    digitalIllustrationGrain: "Digital Illustration - Grain 1.0",
    digitalIllustrationGrain20: "Digital Illustration - Grain 2.0",
    digitalIllustrationInfantileSketch: "Digital Illustration - Child's Sketch",
    digitalIllustration2DArtPoster: "Digital Illustration - 2D Art Poster",
    digitalIllustration2DArtPoster2: "Digital Illustration - 2D Art Poster 2",
    digitalIllustrationHandmade3D: "Digital Illustration - Clay 3D Art",
    digitalIllustrationHandDrawnOutline: "Digital Illustration - Hand Drawn Outline",
    digitalIllustrationEngravingColor: "Digital Illustration - Engraving Color",
    digitalIllustrationAntiquarian: "Digital Illustration - Antiquarian",
    digitalIllustrationBoldFantasy: "Digital Illustration - Bold Fantasy",
    digitalIllustrationChildBook: "Digital Illustration - Child's Book",
    digitalIllustrationChildBooks: "Digital Illustration - Children's Books",
    digitalIllustrationCover: "Digital Illustration - Cover Art",
    digitalIllustrationCrosshatch: "Digital Illustration - Crosshatching",
    digitalIllustrationDigitalEngraving: "Digital Illustration - Digital Engraving",
    digitalIllustrationExpressionism: "Digital Illustration - Expressionism",
    digitalIllustrationFreehandDetails: "Digital Illustration - Freehand Details",
    digitalIllustrationGraphicIntensity: "Digital Illustration - Graphic Intensity",
    digitalIllustrationHardComics: "Digital Illustration - Hard Comics",
    digitalIllustrationLongShadow: "Digital Illustration - Long Shadow",
    digitalIllustrationModernFolk: "Digital Illustration - Modern Folk",
    digitalIllustrationMulticolor: "Digital Illustration - Multicolor",
    digitalIllustrationNeonCalm: "Digital Illustration - Neon Calm",
    digitalIllustrationNoir: "Digital Illustration - Noir",
    digitalIllustrationNostalgicPastel: "Digital Illustration - Nostalgic Pastel",
    digitalIllustrationOutlineDetails: "Digital Illustration - Outline Details",
    digitalIllustrationPastelGradient: "Digital Illustration - Pastel Gradient",
    digitalIllustrationPastelSketch: "Digital Illustration - Pastel Sketch",
    digitalIllustrationPopArt: "Digital Illustration - Pop Art",
    digitalIllustrationPopRenaissance: "Digital Illustration - Pop Renaissance",
    digitalIllustrationStreetArt: "Digital Illustration - Street Art",
    digitalIllustrationTabletSketch: "Digital Illustration - Tablet Sketch",
    digitalIllustrationUrbanGlow: "Digital Illustration - Urban Glow",
    digitalIllustrationUrbanSketching: "Digital Illustration - Urban Sketching",
    digitalIllustrationVanillaDreams: "Digital Illustration - Vanilla Dreams",
    digitalIllustrationYoungAdultBook: "Digital Illustration - Young Adult Book",
    digitalIllustrationYoungAdultBook2: "Digital Illustration - Young Adult Book 2",
    vectorIllustration: "Vector Illustration (.SVG)",
    vectorIllustrationBoldStroke: "Vector Illustration - Bold Stroke (.SVG)",
    vectorIllustrationChemistry: "Vector Illustration - Chemistry (.SVG)",
    vectorIllustrationColoredStencil: "Vector Illustration - Colored Stencil (.SVG)",
    vectorIllustrationContourPopArt: "Vector Illustration - Contour Pop Art (.SVG)",
    vectorIllustrationCosmics: "Vector Illustration - Cosmics (.SVG)",
    vectorIllustrationCutout: "Vector Illustration - Cutout (.SVG)",
    vectorIllustrationDepressive: "Vector Illustration - Depressive (.SVG)",
    vectorIllustrationEditorial: "Vector Illustration - Editorial (.SVG)",
    vectorIllustrationEmotionalFlat: "Vector Illustration - Emotional Flat (.SVG)",
    vectorIllustrationInfographical: "Vector Illustration - Infographical (.SVG)",
    vectorIllustrationMarkerOutline: "Vector Illustration - Marker Outline (.SVG)",
    vectorIllustrationMosaic: "Vector Illustration - Mosaic (.SVG)",
    vectorIllustrationNaivector: "Vector Illustration - Naive Vector (.SVG)",
    vectorIllustrationRoundishFlat: "Vector Illustration - Roundish Flat (.SVG)",
    vectorIllustrationSegmentedColors: "Vector Illustration - Segmented Colors (.SVG)",
    vectorIllustrationSharpContrast: "Vector Illustration - Sharp Contrast (.SVG)",
    vectorIllustrationThin: "Vector Illustration - Thin (.SVG)",
    vectorIllustrationVectorPhoto: "Vector Illustration - Vector Photo (.SVG)",
    vectorIllustrationVividShapes: "Vector Illustration - Vivid Shapes (.SVG)",
    vectorIllustrationEngraving: "Vector Illustration - Engraving (.SVG)",
    vectorIllustrationLineArt: "Vector Illustration - Line Art (.SVG)",
    vectorIllustrationLineCircuit: "Vector Illustration - Line Circuit (.SVG)",
    vectorIllustrationLinocut: "Vector Illustration - Linocut (.SVG)",
  },
};
export default junoStyle;
