import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import axios from "axios";
import Paper from "@mui/material/Paper";
import { ColumnCenteredBox, RowBox } from "../../../utils/styledBox";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ButtonBase from "@mui/material/ButtonBase";
import { alpha } from "@mui/material/styles";
import Cookies from "js-cookie";
import { useAlert } from "../../../context/AlertContext";
import FolderIcon from "@mui/icons-material/Folder";

type AiType = {
  key: string;
  path: string;
  icon: React.ReactElement;
  label: string;
};

interface CenterContentsMenuProps {
  ais: AiType[];
  libraries: AiType[];
  activeMenu: string | null;
}

const CenterContentsMenu = ({ ais, libraries, activeMenu }: CenterContentsMenuProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useCustomNavigate();
  const { setAlert } = useAlert();

  const isPathMatch = (itemPath) => {
    const currentPath = location.pathname.replace(/\/$/, "");
    if (currentPath === "/ja" || currentPath === "/en") {
      return false;
    }

    const targetPath = itemPath.replace(/\/$/, "");

    const pathWithoutLang = currentPath.replace(/^\/[a-z]{2}/, "");

    if (targetPath === "/juno") {
      return pathWithoutLang === targetPath || pathWithoutLang === "/juno/";
    }

    // 特定のパスが空文字列または "/" の場合を明示的に処理
    if (targetPath === "" || targetPath === "/") {
      return pathWithoutLang === "" || pathWithoutLang === "/";
    }

    return pathWithoutLang === targetPath;
  };

  const handleNewTextFile = async (type: number) => {
    try {
      if (type === 1 || type === 2) {
        axios.defaults.withCredentials = true;
        const csrftoken = Cookies.get("csrftoken");
        const config = {
          headers: { "X-CSRFToken": csrftoken },
        };
        const res = await axios.post("/api/v1/text-file/", { type: type }, config);
        navigate("/notes-nebula/files/" + res.data.uuid);
        setAlert("success", t("library.text.message.create"));
      }
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };

  const handleOnClick = (path: string, key: string) => {
    if (key === "textEditor") {
      handleNewTextFile(1);
      return;
    }
    navigate(path);
  };

  return (
    <Paper
      sx={{
        borderRadius: 0,
        pt: 1,
        pb: 1,
        backgroundColor: "transparent",
      }}
      elevation={0}
    >
      <RowBox sx={{ ml: 2, mb: 0.5, width: "inherit" }}>
        <AutoAwesomeIcon fontSize={"small"} color={"secondary"} sx={{ mr: 1 }} />
        <Typography variant={"body2"} component={"span"}>
          {activeMenu === "" ? t("drawer.AIs") : activeMenu === "juno" ? t("drawer.juno") : t("drawer.notesNebula")}
        </Typography>
      </RowBox>
      <Grid container spacing={0.5} sx={{ px: 1, py: 0.5, mb: 2 }}>
        {ais.map((item) => (
          <Grid item xs={4} key={item.key}>
            <ButtonBase
              onClick={() => handleOnClick(item.path, item.key)}
              sx={{
                borderRadius: 1,
                width: "100%",
                height: "100%",
                alignItems: "flex-start",
                backgroundColor: (theme) =>
                  isPathMatch(item.path)
                    ? alpha(theme.palette.primary.main, 0.5)
                    : alpha(theme.palette.background.paper, 0.5),
                "&:hover": {
                  backgroundColor: (theme) => alpha(theme.palette.primary.light, 0.4),
                },
                transition: "background-color 0.5s",
              }}
            >
              <ColumnCenteredBox sx={{ m: 1, whiteSpace: "break-spaces" }}>
                {item.icon}
                <Typography
                  variant={"caption"}
                  component={"span"}
                  whiteSpace={"nowrap"}
                  sx={{ fontWeight: isPathMatch(item.path) ? "bold" : "normal" }}
                >
                  {t(item.label)}
                </Typography>
              </ColumnCenteredBox>
            </ButtonBase>
          </Grid>
        ))}
      </Grid>
      <RowBox sx={{ ml: 2, mb: 0.5, width: "inherit" }}>
        <FolderIcon fontSize={"small"} color={"secondary"} sx={{ mr: 1 }} />
        <Typography variant={"body2"} component={"span"}>
          {t("drawer.library")}
        </Typography>
      </RowBox>
      <Grid container={true} spacing={0.5} sx={{ px: 1, py: 0.5 }}>
        {libraries.map((item) => (
          <Grid item xs={4} key={item.key}>
            <ButtonBase
              onClick={() => navigate(item.path)}
              sx={{
                borderRadius: 1,
                width: "100%",
                height: "100%",
                alignItems: "flex-start",
                backgroundColor: (theme) =>
                  isPathMatch(item.path)
                    ? alpha(theme.palette.primary.main, 0.5)
                    : alpha(theme.palette.background.paper, 0.5),
                "&:hover": {
                  backgroundColor: (theme) => alpha(theme.palette.primary.light, 0.4),
                },
                transition: "background-color 0.5s",
              }}
            >
              <ColumnCenteredBox sx={{ m: 1, whiteSpace: "break-spaces" }}>
                {item.icon}
                <Typography
                  variant={"caption"}
                  component={"span"}
                  whiteSpace={"nowrap"}
                  sx={{ fontWeight: location.pathname.includes(item.path) ? "bold" : "normal" }}
                >
                  {t(item.label)}
                </Typography>
              </ColumnCenteredBox>
            </ButtonBase>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default CenterContentsMenu;
