import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import { Fade, Grid, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Cookies from "js-cookie";
import * as React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Meta from "../../../components/common/Meta";
import { useAlert } from "../../../context/AlertContext";
import { useArticleContext } from "../../../context/ArticleContext";
import { useCheckCredit } from "../../../hooks/useCreditCheck";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import { setCreditTrigger } from "../../../redux/slices/triggerSlice";
import { GradationButton } from "../../../utils/gradationButton";
import { RowBox } from "../../../utils/styledBox";
import { validateUrl, validateYoutubeUrl } from "../../../utils/validation";
import AdditionalText from "./components/AdditionalText";
import Heading from "./components/Heading";
import Keyword from "./components/Keyword";
import Source from "./components/Source";
import Target from "./components/Target";
import Tone from "./components/Tone";

export default function ArticleGenerator() {
  const { t } = useTranslation();
  const { checkCredit } = useCheckCredit();
  const [processing, setProcessing] = React.useState(false);
  const navigate = useCustomNavigate();
  const { setAlert } = useAlert();
  const dispatch = useDispatch();
  const location = useLocation();

  const { headings, keywords, content, srcType, target, tone, additionalText, manualHeadings, setSrcType, setContent } =
    useArticleContext();

  useEffect(() => {
    // 外部からテキストを受け取った場合
    const text = location.state?.text;
    if (text) {
      setContent(text);
      setSrcType("text");
    }
  }, []);

  const createArticle = async () => {
    try {
      setProcessing(true);
      if (!(await checkCredit())) return;
      if (!validates()) {
        setProcessing(false);
        return;
      }
      const post_url = "/api/v1/article/";
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });

      const data = {
        headings: headings,
        keywords: keywords,
        content: content,
        srcType: srcType,
        target: target,
        tone: tone,
        additionalText: additionalText,
        manualHeadings: manualHeadings,
      };
      const response = await fetch(post_url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      });
      const responseData = await response.json();
      if (responseData.success) {
        setProcessing(false);
        dispatch(setCreditTrigger(true));

        const currentPath = location.pathname.toLowerCase();
        setAlert("success", t("common.complete"));
        if (currentPath.includes("writing-ai/article")) {
          navigate("/notes-nebula/files/" + responseData.data.file_uuid);
        }
      } else {
        setAlert("error", responseData.message);
      }
    } catch (e) {
      setProcessing(false);
    } finally {
      setProcessing(false);
    }
  };

  // 入力チェック
  const validates = () => {
    if (keywords.trim().length === 0) {
      setAlert("error", t("article.error.invalidKeywords"));
      return false;
    }
    if (srcType === "youtube" && !validateYoutubeUrl(content)) {
      setAlert("error", t("article.error.invalidYoutubeUrl"));
      return false;
    }

    if (srcType === "url" && !validateUrl(content)) {
      setAlert("error", t("article.error.invalidUrl"));
      return false;
    }

    if (srcType === "text" && content.length === 0) {
      setAlert("error", t("article.error.invalidText"));
      return false;
    }

    if (manualHeadings && headings.trim().length === 0) {
      setAlert("error", t("article.error.invalidHeadings"));
      return false;
    }

    if (manualHeadings && headings.split("\n").length > 20) {
      setAlert("error", t("article.error.invalidHeadingsLength"));
      setProcessing(false);
      return;
    }
    return true;
  };

  const meta = [{ name: "robots", content: "noindex, nofollow" }];

  return (
    <>
      <Meta title={t("article.title")} meta={meta} />
      <Fade in={true}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <RowBox>
              <FlashOnIcon sx={{ mr: 1 }} color={"secondary"} />
              <Typography variant={"body2"} gutterBottom>
                {t("article.description")}
              </Typography>
            </RowBox>
          </Grid>
          <Grid item xs={12} md={6} width={"100%"}>
            <Stack spacing={4}>
              <Keyword />
              <Target />
              <Heading />
              <Tone />
              <AdditionalText />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Source />
          </Grid>

          <Grid item xs={12}>
            <Box display={"flex"} justifyContent={"flex-end"} gap={1}>
              <GradationButton variant="contained" onClick={createArticle} disabled={processing}>
                {!processing && (
                  <>
                    <AutoAwesomeIcon sx={{ mr: 1 }} fontSize={"small"} />
                    {t("article.submit")}
                  </>
                )}
                {processing && (
                  <>
                    <Typography variant={"button"}>{"Generating..."}</Typography>
                    <CircularProgress size={20} sx={{ ml: 1, color: "white" }} />
                  </>
                )}
              </GradationButton>
            </Box>
          </Grid>
        </Grid>
      </Fade>
    </>
  );
}
