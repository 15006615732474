import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import ProPlanModal from "../../../../../components/common/ProPlanModal";
import { useAlert } from "../../../../../context/AlertContext";
import { useCommonContext } from "../../../../../context/juno/CommonContext";
import { useParamsContext } from "../../../../../context/juno/ParamsContext";
import { useUploadImageContext } from "../../../../../context/juno/UploadImageContext";
import { useUpscaleContext } from "../../../../../context/juno/UpscaleContext";
import { useCheckCredit } from "../../../../../hooks/useCreditCheck";
import { useJunoModels } from "../../../../../hooks/useJunoModels";
import { setCreditTrigger } from "../../../../../redux/slices/triggerSlice";
import { AiEngineList, ApiResponse, InputData, InputPromptProps } from "../../../../../types/junoTypes";
import PromptTextField from "./PromptTextField";

const InputPrompt: React.FC<InputPromptProps> = ({ fetchAndSetImageList }) => {
  const { t } = useTranslation();
  const { checkCredit } = useCheckCredit();
  const { getModelParams } = useJunoModels();
  const {
    prompt,
    setPrompt,
    negativePrompt,
    revisedPrompt,
    aspectRatio,
    width,
    height,
    step,
    sample,
    guidanceScale,
    seed,
    style,
    colorsRecraft,
    styleWeight,
    rawMode,
    isPublic,
    menu,
    aiEngine,
    imageCredit,
    fetchingCredit,
  } = useParamsContext();

  const { image, imageStrength, image2, imageStrength2, editorInputImage, editorInputMask } = useUploadImageContext();
  const { uuidParent, upscaleFactor, upscaleCreativity, upscaleDetail, upscaleResemblance } = useUpscaleContext();
  const { setProcessing } = useCommonContext();
  const { setAlert } = useAlert();

  const [localPrompt, setLocalPrompt] = useState<string>("");
  const [rotationDegree, setRotationDegree] = useState(0);
  const [waitingGenerate, setWaitingGenerate] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const [generatingPrompt, setGeneratingPrompt] = useState(false);
  const [openProDialog, setOpenProDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState<string | undefined>(undefined);
  const [requiredPrompt, setRequiredPrompt] = useState<"REQUIRED" | "OPTIONAL" | "NOT_NEEDED">("REQUIRED");

  useEffect(() => {
    setLocalPrompt(prompt);
  }, [prompt]);

  useEffect(() => {
    const text = location.state?.textEditor;
    if (text) {
      setLocalPrompt(text as string);
    }
  }, [location.state?.textEditor]);

  useEffect(() => {
    requiredPromptMange();
  }, [menu, aiEngine]);

  useEffect(() => {
    if (waitingGenerate) {
      setTimeout(() => {
        setWaitingGenerate(false);
      }, 2000);
    }
  }, [waitingGenerate]);

  const validateContext = (aiEngine: string) => {
    const errorMessages: string[] = [];

    if (menu == "img2img" || menu == "upscale") {
      if (image === null) {
        errorMessages.push("・" + t("juno.validation.image"));
      }
      if (aiEngine === "KolorsVTO" && image2 === null) {
        errorMessages.push("・" + t("juno.validation.image"));
      }
    }

    if (localPrompt.trim().length > 1000) {
      errorMessages.push("・" + t("juno.validation.prompt"));
    }

    if (errorMessages.length > 0) {
      setAlert("error", errorMessages.join("\n"));
    }

    return errorMessages.length === 0;
  };

  const validateInputs = (aiEngine: AiEngineList) => {
    const aiEnginesRequiringImage = ["Flux1Dev", "SD3", "Ideogram2", "Ideogram2Turbo"];
    if (aiEnginesRequiringImage.includes(aiEngine) && menu === "img2img" && !image) {
      setAlert("error", "Please upload an image.");
      return false;
    }
    return true;
  };

  const getAiEngineData = (aiEngine: AiEngineList, data: InputData) => {
    const paramsArray = getModelParams(aiEngine, menu);
    const additionalData: Record<string, unknown> = {};

    const allParams = {
      width: width?.[aiEngine] ?? 1024,
      height: height?.[aiEngine] ?? 1024,
      negativePrompt: negativePrompt ?? "",
      step: step?.[aiEngine],
      sample: sample?.[aiEngine] ?? 1,
      guidanceScale: guidanceScale?.[aiEngine],
      seed: seed?.[aiEngine] ?? null,
      revisedPrompt: revisedPrompt ?? false,
      aspectRatio: aspectRatio?.[aiEngine],
      style: style?.[aiEngine],
      styleWeight: styleWeight?.[aiEngine],
      rawMode: ["Flux11ProUltra", "Flux11ProUltraRedux", "Flux11ProUltraJuno"].includes(aiEngine)
        ? (rawMode ?? false)
        : undefined,
      colors: colorsRecraft ?? [],
      initImage: image,
      imageStrength: imageStrength,
      initImage2: image2,
      imageStrength2: imageStrength2,
    };

    paramsArray.forEach((param) => {
      if (Object.prototype.hasOwnProperty.call(allParams, param)) {
        additionalData[param] = allParams[param as keyof typeof allParams];
      }
    });

    if (data.action === "editor" && editorInputMask && editorInputImage) {
      Object.assign(additionalData, {
        aspectRatio: aspectRatio?.[aiEngine],
        maskImage: editorInputMask,
        initImage: editorInputImage,
      });
    }

    if (data.action === "img2img" && image) {
      Object.assign(additionalData, {
        uuidParent: uuidParent,
      });
    }

    return additionalData;
  };

  const handleSend = async (request_uuid?: string | null) => {
    setWaitingGenerate(true);
    try {
      if (!(await checkCredit())) return;
      setProcessing(true);

      const url = "/api/v1/juno/generate";
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });

      if (!validateContext(aiEngine)) return;
      if (!validateInputs(aiEngine)) return;

      let data: InputData = {
        action: menu,
        prompt: localPrompt,
        aiEngine: aiEngine,
        requestUuid: request_uuid,
        isPublic: isPublic,
        needFixPrompt: false,
      };

      data = { ...data, ...getAiEngineData(aiEngine, data) };

      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ data }),
      });
      const res = (await response.json()) as ApiResponse;

      handleResponse(res);
    } catch (error: unknown) {
      console.error(error);
      setAlert("error", "Something went wrong. Please try again.");
    } finally {
      dispatch(setCreditTrigger(true));
      setProcessing(false);
    }
  };

  const handleResponse = (res: ApiResponse) => {
    if (res.status === "success") {
      fetchAndSetImageList();
    } else if (res.status === "temporary_success") {
      fetchAndSetImageList();
      handleSend(res.request_uuid);
    } else if (res.status === "processing") {
      // webhookを待つ
    } else {
      fetchAndSetImageList();
      if (res.message === "NO_CREDITS") {
        setDialogMessage(t("juno.error.noCredit"));
        setOpenProDialog(true);
      } else if (res.message === "UPGRADE_PLAN") {
        setDialogMessage(t("juno.error.upgrade"));
        setOpenProDialog(true);
      } else {
        setAlert("error", res.message ?? "An error occurred");
      }
    }
  };

  const handleUpscale = async (request_uuid?: string | null) => {
    setWaitingGenerate(true);
    try {
      if (!image) {
        setAlert("error", t("juno.validation.image"));
        return;
      }

      setProcessing(true);

      const url = "/api/v1/juno/generate-upscale";
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });

      const checkImageSize = async (): Promise<boolean> => {
        return new Promise((resolve) => {
          if (image && aiEngine === "CreativeUpscaler") {
            const img = new Image();
            img.src = image;
            img.onload = () => {
              if (img.width * img.height > 4194304) {
                setAlert("error", "The image size is too large.");
                setProcessing(false);
                resolve(false);
              } else {
                resolve(true);
              }
            };
          } else {
            resolve(true);
          }
        });
      };

      const isSizeValid = await checkImageSize();
      if (!isSizeValid) {
        return;
      }

      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          action: "upscale",
          aiEngine,
          initImage: image,
          factor: upscaleFactor[aiEngine],
          creativity: upscaleCreativity[aiEngine],
          detail: upscaleDetail[aiEngine],
          resemblance: upscaleResemblance[aiEngine],
          requestUuid: request_uuid,
          uuidParent: uuidParent,
          isPublic: uuidParent ? isPublic : false,
          prompt: localPrompt,
        }),
      });

      const res = await response.json();
      if (res.status === "success") {
        fetchAndSetImageList();
      } else if (res.status === "temporary_success") {
        fetchAndSetImageList();
        await handleUpscale(res.request_uuid);
      } else {
        fetchAndSetImageList();
        setAlert("error", res.message ?? "An error occurred");
      }
    } catch (error: unknown) {
      console.error(error);
    } finally {
      dispatch(setCreditTrigger(true));
      setProcessing(false);
    }
  };

  const generateSampleText = async () => {
    try {
      setGeneratingPrompt(true);
      if (!(await checkCredit())) return;
      setRotationDegree(rotationDegree + 360);
      setProcessing(true);

      const url = "/api/v1/juno/generate-prompt";
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ aiEngine }),
      });
      const data = await response.json();
      setPrompt(data);
      dispatch(setCreditTrigger(true));
    } catch (error: unknown) {
      console.error(error);
    } finally {
      setProcessing(false);
      setGeneratingPrompt(false);
    }
  };

  const updatePrompt = async () => {
    try {
      setGeneratingPrompt(true);
      if (!(await checkCredit())) return;
      setProcessing(true);

      const url = "/api/v1/juno/update-prompt";
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          aiEngine,
          prompt: localPrompt,
        }),
      });
      const data = await response.json();
      setPrompt(data);
      dispatch(setCreditTrigger(true));
    } catch (error: unknown) {
      console.error(error);
    } finally {
      setProcessing(false);
      setGeneratingPrompt(false);
    }
  };

  const requiredPromptMange = () => {
    if (
      [
        "AuraSR",
        "Flux1DevRedux",
        "Flux1SchnellRedux",
        "KolorsVTO",
        "RecraftClarityUpscaler",
        "RecraftCreativeUpscaler",
      ].includes(aiEngine)
    ) {
      setRequiredPrompt("NOT_NEEDED");
      return t("juno.history.notNeededPlaceholder");
    }
    if (menu === "upscale") {
      setRequiredPrompt("OPTIONAL");
      return t("juno.history.optionalPromptPlaceholder");
    }
    if (menu === "img2img" && ["Flux11ProUltraRedux", "Flux11ProRedux"].includes(aiEngine)) {
      setRequiredPrompt("OPTIONAL");
      return t("juno.history.optionalPromptPlaceholder");
    }
    setRequiredPrompt("REQUIRED");
    return t("juno.history.promptPlaceholder");
  };

  const placeholder = () => {
    switch (requiredPrompt) {
      case "REQUIRED":
        return t("juno.history.promptPlaceholder");
      case "OPTIONAL":
        return t("juno.history.optionalPromptPlaceholder");
      case "NOT_NEEDED":
        return t("juno.history.notNeededPlaceholder");
      default:
        return t("juno.history.promptPlaceholder");
    }
  };

  const inputValue = () => {
    let disabled = false;
    if (menu === "upscale") {
      disabled = false;
    }
    if (menu === "txt2img" && localPrompt.trim().length === 0) {
      disabled = true;
    }
    if (menu === "img2img") {
      if (["Flux11ProUltraRedux", "Flux11ProRedux"].includes(aiEngine)) {
        disabled = false;
      }
      if (["Flux1DevRedux", "Flux1SchnellRedux", "KolorsVTO"].includes(aiEngine)) {
        disabled = true;
      }
    }
    return disabled ? "" : localPrompt;
  };

  const isDisabled = [
    "AuraSR",
    "Flux1DevRedux",
    "Flux1SchnellRedux",
    "RecraftClarityUpscaler",
    "RecraftCreativeUpscaler",
  ].includes(aiEngine);

  return (
    <>
      <PromptTextField
        value={inputValue()}
        onChange={(event) => setLocalPrompt(event.target.value)}
        disabled={isDisabled}
        placeholder={placeholder()}
        localPrompt={localPrompt}
        aiEngine={aiEngine}
        rotationDegree={rotationDegree}
        generatingPrompt={generatingPrompt}
        waitingGenerate={waitingGenerate}
        imageCredit={imageCredit}
        fetchingCredit={fetchingCredit}
        onGenerateSampleText={generateSampleText}
        onUpdatePrompt={updatePrompt}
        onDelete={() => setLocalPrompt("")}
        onSend={menu === "upscale" ? () => handleUpscale() : () => handleSend()}
        setLocalPrompt={setLocalPrompt}
        requiredPrompt={requiredPrompt}
      />
      <ProPlanModal open={openProDialog} setOpen={setOpenProDialog} message={dialogMessage} />
    </>
  );
};

export default InputPrompt;
