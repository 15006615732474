import BrushIcon from "@mui/icons-material/Brush";
import CampaignIcon from "@mui/icons-material/Campaign";
import FontDownloadIcon from "@mui/icons-material/FontDownload";
import ImageIcon from "@mui/icons-material/Image";
import { Chip } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { useCustomNavigate } from "../../../../hooks/useCustomNavigate";

const QuickSelection = () => {
  const navigate = useCustomNavigate();
  const { t } = useTranslation();
  const params = new URLSearchParams(window.location.search);
  const ai_engine = params.get("ai_engine");
  const menu = params.get("menu");

  return (
    <Stack spacing={1} direction="row" sx={{}}>
      {/* T2I */}
      <Chip
        label={t("juno.selection.new")}
        onClick={() => navigate("/juno/image-generator?menu=txt2img&ai_engine=Flux11ProUltraJuno")}
        color={ai_engine === "Flux11ProUltraJuno" ? "primary" : "default"}
        avatar={<CampaignIcon fontSize={"small"} sx={{ backgroundColor: "transparent!important" }} />}
        size="small"
        sx={{ px: 1 }}
      />
      {/* T2I */}
      <Chip
        label={t("juno.selection.popularModel")}
        onClick={() => navigate("/juno/image-generator?menu=txt2img&ai_engine=Flux1Dev")}
        color={ai_engine === "Flux1Dev" && menu == "txt2img" ? "primary" : "default"}
        avatar={<FontDownloadIcon fontSize={"small"} sx={{ backgroundColor: "transparent!important" }} />}
        size="small"
        sx={{ px: 1 }}
      />

      <Chip
        label={t("juno.selection.highResolutionModel")}
        onClick={() => navigate("/juno/image-generator?menu=txt2img&ai_engine=Flux11ProUltra")}
        color={ai_engine === "Flux11ProUltra" && menu == "txt2img" ? "primary" : "default"}
        avatar={<FontDownloadIcon fontSize={"small"} sx={{ backgroundColor: "transparent!important" }} />}
        size="small"
        sx={{ px: 1 }}
      />

      <Chip
        label={t("juno.selection.lowCostModel")}
        onClick={() => navigate("/juno/image-generator?menu=txt2img&ai_engine=Flux1Schnell")}
        color={ai_engine === "Flux1Schnell" && menu == "txt2img" ? "primary" : "default"}
        avatar={<FontDownloadIcon fontSize={"small"} sx={{ backgroundColor: "transparent!important" }} />}
        size="small"
        sx={{ px: 1 }}
      />

      <Chip
        label={t("juno.selection.highPrecisionModel")}
        onClick={() => navigate("/juno/image-generator?menu=txt2img&ai_engine=RecraftV3")}
        color={ai_engine === "RecraftV3" && menu == "txt2img" ? "primary" : "default"}
        avatar={<FontDownloadIcon fontSize={"small"} sx={{ backgroundColor: "transparent!important" }} />}
        size="small"
        sx={{ px: 1 }}
      />

      <Chip
        label={t("juno.selection.japanesePortrait")}
        onClick={() => navigate("/juno/image-generator?menu=txt2img&ai_engine=FluxJuno")}
        color={ai_engine === "FluxJuno" && menu == "txt2img" ? "primary" : "default"}
        avatar={<FontDownloadIcon fontSize={"small"} sx={{ backgroundColor: "transparent!important" }} />}
        size="small"
        sx={{ px: 1 }}
      />

      {/* I2I */}
      <Chip
        label={t("juno.selection.popularModel")}
        onClick={() => navigate("/juno/image-generator?menu=img2img&ai_engine=Flux1Dev")}
        color={ai_engine === "Flux1Dev" && menu == "img2img" ? "primary" : "default"}
        avatar={<ImageIcon fontSize={"small"} sx={{ backgroundColor: "transparent!important" }} />}
        size="small"
        sx={{ px: 1 }}
      />

      <Chip
        label={t("juno.selection.depthToImage")}
        onClick={() => navigate("/juno/image-generator?menu=img2img&ai_engine=Flux1ProDepth")}
        color={ai_engine === "Flux1ProDepth" && menu == "img2img" ? "primary" : "default"}
        avatar={<ImageIcon fontSize={"small"} sx={{ backgroundColor: "transparent!important" }} />}
        size="small"
        sx={{ px: 1 }}
      />

      <Chip
        label={t("juno.selection.outlineToImage")}
        onClick={() => navigate("/juno/image-generator?menu=img2img&ai_engine=Flux1ProCanny")}
        color={ai_engine === "Flux1ProCanny" && menu == "img2img" ? "primary" : "default"}
        avatar={<ImageIcon fontSize={"small"} sx={{ backgroundColor: "transparent!important" }} />}
        size="small"
        sx={{ px: 1 }}
      />

      <Chip
        label={t("juno.selection.fixedPerson")}
        onClick={() => navigate("/juno/image-generator?menu=img2img&ai_engine=FluxPuLID")}
        color={ai_engine === "FluxPuLID" && menu == "img2img" ? "primary" : "default"}
        avatar={<ImageIcon fontSize={"small"} sx={{ backgroundColor: "transparent!important" }} />}
        size="small"
        sx={{ px: 1 }}
      />

      <Chip
        label={t("juno.selection.changeClothes")}
        onClick={() => navigate("/juno/image-generator?menu=img2img&ai_engine=KolorsVTO")}
        color={ai_engine === "KolorsVTO" && menu == "img2img" ? "primary" : "default"}
        avatar={<ImageIcon fontSize={"small"} sx={{ backgroundColor: "transparent!important" }} />}
        size="small"
        sx={{ px: 1 }}
      />

      {/* Editor */}
      <Chip
        label={t("juno.selection.brush")}
        onClick={() => navigate("/juno/image-generator?menu=editor&ai_engine=Flux1ProFill")}
        color={ai_engine === "Flux1ProFill" && menu == "editor" ? "primary" : "default"}
        avatar={<BrushIcon fontSize={"small"} sx={{ backgroundColor: "transparent!important" }} />}
        size="small"
        sx={{ px: 1 }}
      />
    </Stack>
  );
};

export default QuickSelection;
