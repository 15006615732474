const junoPreset = {
  icon: "インスピレーション",
  description: "チップをクリックしてプロンプトに追加して下さい",
  mood: {
    // シネマティック
    epicCinematic: "壮大な映画的シーン",
    dramaticScene: "ドラマチックなシーン",
    actionScene: "アクションシーン",
    romanticScene: "ロマンチックなシーン",
    epicBattle: "壮大な戦闘シーン",
    chaseScene: "追跡シーン",
    slowMotion: "スローモーション",
    aerialShot: "空撮シーン",
    // ホラー
    horror: "ホラー",
    supernatural: "超自然的",
    psychological: "心理的ホラー",
    haunted: "怪談",
    creepy: "不気味",
    nightmarish: "悪夢的",
    occult: "オカルト",
    paranormal: "パラノーマル",
    // SF
    sciFi: "サイエンスフィクション",
    cyberpunkMood: "サイバーパンク",
    postApocalypticMood: "ポストアポカリプス",
    spaceOpera: "スペースオペラ",
    timeTravel: "タイムトラベル",
    alienWorld: "エイリアンワールド",
    roboticFuture: "ロボット未来",
    dystopian: "ディストピア",
    // ドキュメンタリー
    documentary: "ドキュメンタリー",
    natureDoc: "自然ドキュメンタリー",
    wildlifeDoc: "野生動物ドキュメンタリー",
    urbanDoc: "都市ドキュメンタリー",
    scientificDoc: "科学ドキュメンタリー",
    historicalDoc: "歴史ドキュメンタリー",
    culturalDoc: "文化ドキュメンタリー",
    journalistic: "ジャーナリスティック",
  },
  character: {
    // 主要キャラクター
    protagonist: "主人公",
    antagonist: "敵役",
    mentor: "メンター",
    sidekick: "相棒",
    loveInterest: "恋愛対象",
    rival: "ライバル",
    // アーキタイプ
    hero: "英雄",
    antihero: "アンチヒーロー",
    villain: "悪役",
    trickster: "トリックスター",
    sage: "賢者",
    explorer: "探検家",
    // 人物
    youngMan: "若い男性",
    youngWoman: "若い女性",
    elderlyMan: "年配の男性",
    elderlyWoman: "年配の女性",
    child: "子供",
    teenager: "10代",
    // 職業
    detective: "探偵",
    soldier: "兵士",
    scientist: "科学者",
    artist: "アーティスト",
    doctor: "医師",
    teacher: "教師",
    chef: "シェフ",
    astronaut: "宇宙飛行士",
    pilot: "パイロット",
    archaeologist: "考古学者",
    // アクション系
    martialArtist: "武術家",
    assassin: "暗殺者",
    samurai: "侍",
    ninja: "忍者",
    knight: "騎士",
    gunslinger: "ガンスリンガー",
    // ファンタジー
    wizard: "魔法使い",
    warrior: "戦士",
    ranger: "レンジャー",
    paladin: "パラディン",
    druid: "ドルイド",
    necromancer: "ネクロマンサー",
    // 神話と伝説
    angel: "天使",
    demon: "悪魔",
    god: "神",
    goddess: "女神",
    dragon: "ドラゴン",
    phoenix: "フェニックス",
    // SF
    cyborg: "サイボーグ",
    android: "アンドロイド",
    alien: "エイリアン",
    robot: "ロボット",
    mutant: "ミュータント",
    clone: "クローン",
    // クリーチャー
    vampire: "ヴァンパイア",
    werewolf: "狼人間",
    ghost: "幽霊",
    zombie: "ゾンビ",
    demonBeast: "魔獣",
    monster: "モンスター",
    // 動物
    lion: "ライオン",
    wolf: "オオカミ",
    eagle: "イーグル",
    horse: "馬",
    cat: "猫",
    dog: "犬",
  },
  illumination: {
    sunlight: "日光",
    naturalLight: "自然光",
    studioLighting: "スタジオ照明",
    softLight: "柔らかい光",
    brightLight: "明るい光",
    dimLight: "薄暗い光",
    warmLight: "暖かい光",
    coolLight: "冷たい光",
    goldenHour: "ゴールデンアワー",
    blueMagicHour: "ブルーマジックアワー",
    sunsetGlow: "夕暮れの輝き",
    moonlight: "月明かり",
    starlitSky: "星明かりの空",
    candlelight: "キャンドルの光",
    fireLight: "炎の光",
    neonGlow: "ネオンの輝き",
    cityNightGlow: "都市の夜景の輝き",
    rainReflection: "雨の反射光",
    underwaterGlow: "水中の輝き",
    auroraLight: "オーロラの光",
    holographicLight: "ホログラフィックライト",
    projectionMapping: "プロジェクションマッピング",
    ledMatrix: "LEDマトリックス",
    laserBeams: "レーザービーム",
    bioluminescence: "生物発光",
    volumetricLight: "ボリューメトリックライト",
    rimLight: "リムライト",
    crystalRefraction: "クリスタル屈折光",
    caustics: "コースティクス",
    godRays: "ゴッドレイ",
  },
  background: {
    cityStreet: "都市の通り",
    cafe: "カフェ",
    park: "公園",
    beach: "ビーチ",
    forest: "森",
    mountain: "山",
    lake: "湖",
    river: "川",
    garden: "庭園",
    office: "オフィス",
    livingRoom: "リビングルーム",
    kitchen: "キッチン",
    bedroom: "ベッドルーム",
    studio: "スタジオ",
    classroom: "教室",
    library: "図書館",
    museum: "博物館",
    artGallery: "アートギャラリー",
    restaurant: "レストラン",
    bar: "バー",
    nightclub: "ナイトクラブ",
    concert: "コンサート会場",
    theater: "劇場",
    stadium: "スタジアム",
    gym: "ジム",
    spa: "スパ",
    hospital: "病院",
    laboratory: "研究所",
    factory: "工場",
    warehouse: "倉庫",
    market: "市場",
    shoppingMall: "ショッピングモール",
    airport: "空港",
    trainStation: "駅",
    harbor: "港",
    bridge: "橋",
    tunnel: "トンネル",
    rooftop: "屋上",
    basement: "地下室",
    attic: "屋根裏",
    greenhouse: "温室",
    vineyard: "ブドウ園",
    farm: "農場",
    ranch: "牧場",
    desert: "砂漠",
    oasis: "オアシス",
    jungle: "ジャングル",
    rainforest: "熱帯雨林",
    savanna: "サバンナ",
    tundra: "ツンドラ",
    glacier: "氷河",
    volcano: "火山",
    canyon: "渓谷",
    cave: "洞窟",
    underwaterReef: "水中の珊瑚礁",
    tropicalIsland: "熱帯の島",
    metaverse: "メタバース空間",
    augmentedCity: "拡張現実都市",
    virtualWorld: "仮想世界",
    spaceStation: "宇宙ステーション",
    moonBase: "月面基地",
    marsColony: "火星コロニー",
    alienPlanet: "異星の惑星",
    crystalCave: "クリスタルの洞窟",
    enchantedForest: "魔法の森",
    floatingIslands: "浮遊島",
    cloudCity: "空中都市",
    underwaterCity: "水中都市",
    ancientRuins: "古代遺跡",
    medievalCastle: "中世の城",
    futuristicCity: "未来都市",
    steampunkTown: "スチームパンクの街",
    cyberpunkDistrict: "サイバーパンクの街区",
    postApocalyptic: "ポストアポカリプス世界",
    fantasyRealm: "ファンタジー世界",
    parallelUniverse: "パラレルワールド",
  },
  style: {
    photorealistic: "フォトリアリスティック",
    digitalPainting: "デジタルペインティング",
    watercolor: "水彩画スタイル",
    oilPainting: "油絵スタイル",
    pencilSketch: "鉛筆スケッチスタイル",
    animeStyle: "アニメスタイル",
    cartoonStyle: "漫画スタイル",
    pixelArt: "ピクセルアートスタイル",
    minimalist: "ミニマリストスタイル",
    popArt: "ポップアートスタイル",
    abstractArt: "抽象アートスタイル",
    impressionist: "印象派スタイル",
    ukiyoe: "浮世絵スタイル",
    cyberpunk: "サイバーパンクスタイル",
    voxelArt: "ボクセルアートスタイル",
    lowPoly: "ローポリスタイル",
    neuralArt: "ニューラルアートスタイル",
    generativeArt: "ジェネラティブアートスタイル",
    glitchArt: "グリッチアートスタイル",
    fractals: "フラクタルアートスタイル",
    aiGenerated: "AI生成アートスタイル",
    holographic: "ホログラフィックスタイル",
    nftArt: "NFTアートスタイル",
    parametricDesign: "パラメトリックデザイン",
    biomimicry: "バイオミミクリースタイル",
  },
};

export default junoPreset;
