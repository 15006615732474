import FlashOnIcon from "@mui/icons-material/FlashOn";
import {
  Box,
  CircularProgress,
  Container,
  Fade,
  Grid,
  Grow,
  Paper,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Meta from "../../components/common/Meta";
import { DrawerHeader } from "../../components/layout/Drawer/DrawerMain";
import { useAlert } from "../../context/AlertContext";
import { useLoginStatus } from "../../context/LoginStatusContext";
import { useCheckCredit } from "../../hooks/useCreditCheck";
import { useCustomNavigate } from "../../hooks/useCustomNavigate";
import { setCreditTrigger } from "../../redux/slices/triggerSlice";
import { GradientTypography } from "../../utils/gradientTypography";
import { ColumnCenteredBox, RowCenteredBox } from "../../utils/styledBox";
import { getFileDateName } from "../../utils/utils";
import { ContentInput } from "./components/ContentInput";
import DescriptionModal from "./components/DescriptionModal";
import { GenerateButton } from "./components/GenerateButton";
import { ProviderSelector } from "./components/ProviderSelector/ProviderSelector";
import { PitchControl } from "./components/VoiceControls/PitchControl";
import { SpeedControl } from "./components/VoiceControls/SpeedControl";
import { VoiceSelector } from "./components/VoiceControls/VoiceSelector";
import { LanguageOption, Provider, VoiceData, VoiceOption } from "./types";

export default function VoiceGenerator() {
  const { t } = useTranslation();
  const { checkCredit } = useCheckCredit();
  const [processing, setProcessing] = useState(false);
  const [content, setContent] = useState("");
  const [language, setLanguage] = useState("");
  const [voiceName, setVoiceName] = useState("");
  const [speed, setSpeed] = useState(1);
  const [pitch, setPitch] = useState(0);
  const [languageList, setLanguageList] = useState<LanguageOption[]>([]);
  const [googleVoiceList, setGoogleVoiceList] = useState<VoiceOption[]>([]);
  const [openAIVoiceList, setOpenAIVoiceList] = useState<VoiceOption[]>([]);
  const { user } = useLoginStatus();
  const [open, setOpen] = useState(false);
  const [voiceType, setVoiceType] = useState("");
  const [gender, setGender] = useState("");
  const [voiceIndexNumber, setVoiceIndexNumber] = useState(0);
  const [provider, setProvider] = useState<Provider>("openai");
  const { setAlert } = useAlert();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useCustomNavigate();
  const [loading, setLoading] = useState(true);
  const [openaiInstructions, setOpenaiInstructions] = useState("");

  useEffect(() => {
    const text = location.state?.textEditor;
    if (text) {
      setContent(text);
    }
  }, []);

  const csrftoken = Cookies.get("csrftoken");
  const headers = new Headers({
    "Content-Type": "application/json",
    "X-CSRFToken": csrftoken!,
  });

  const fetchJson = async (url: string, method: string, body: unknown = {}) => {
    try {
      const options: RequestInit = {
        method,
        headers,
      };
      if (method !== "GET") {
        options.body = JSON.stringify(body);
      }

      const response = await fetch(url, options);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const fetchVoiceNames = () =>
    fetchJson("/api/v1/voice-generator/list-voices", "POST", {
      provider: provider,
      language_code: language,
    });
  const fetchLanguageList = () => fetchJson("/api/v1/voice-generator/list-languages", "GET");

  // Generate Voice
  // Extracted method for generating downloadable link
  const downloadBlobAsMP3 = (blob: Blob) => {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `voice-${getFileDateName()}.mp3`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const generateVoice = async () => {
    try {
      if (!(await checkCredit())) return;
      setProcessing(true);

      let voiceData: VoiceData = {
        text: content,
        voice_name: voiceName,
        speed: speed,
        provider: provider,
      };

      if (provider === "google") {
        voiceData = {
          ...voiceData,
          language_code: language,
          gender: gender,
          pitch: pitch,
          number: voiceIndexNumber,
          type: voiceType,
        };
      }

      if (voiceName.includes("_A")) {
        voiceData = {
          ...voiceData,
          instructions: openaiInstructions,
        };
      }

      const json = await fetchJson("/api/v1/voice-generator/generate", "POST", voiceData);

      if (!json.success) {
        throw new Error();
      }
      const generated_voice_uuid = await json.data;

      // Download generated voice
      const downloadResponse = await fetch(`/api/v1/voice-generator/download/${generated_voice_uuid}`, {
        method: "GET",
      });
      if (!downloadResponse.ok) {
        throw new Error();
      }
      const blob = await downloadResponse.blob();
      downloadBlobAsMP3(blob);
      dispatch(setCreditTrigger(true));
      setAlert("success", t("voice.message.success"));
    } catch (error) {
      setAlert("error", t("common.error.somethingWentWrong"));
      console.error(error);
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    fetchLanguageList().then((json) => {
      setLanguageList(json.data);
      // languageListが更新された後に言語を設定
      if (user?.ai_language === "ja") {
        setLanguage("ja-JP");
      } else {
        setLanguage("en-US");
      }
    });
  }, []);

  useEffect(() => {
    const updateVoiceData = async () => {
      if (!language) return;

      setLoading(true);
      try {
        const response = await fetchVoiceNames();
        const voiceData = response.data;

        if (provider === "openai") {
          setOpenAIVoiceList(voiceData);
          setVoiceName(voiceData[9].value);
          setVoiceType(voiceData[0].type);
          setVoiceIndexNumber(voiceData[0].voice_number);
        } else {
          setGoogleVoiceList(voiceData);
          setVoiceName(voiceData[0].value);
          setGender(voiceData[0].gender);
          setVoiceType(voiceData[0].type);
          setVoiceIndexNumber(voiceData[0].voice_number);
        }
      } catch (error) {
        console.error("Failed to fetch voice data:", error);
      } finally {
        setLoading(false);
      }
    };

    updateVoiceData();
  }, [provider, language]);

  const handleVoiceNameChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setVoiceName(value);
    const voiceList = provider === "openai" ? openAIVoiceList : googleVoiceList;
    const selectedVoice = voiceList.find((item) => item.value === value);
    if (selectedVoice) {
      setVoiceType(selectedVoice.type);
      setGender(selectedVoice.gender);
      setVoiceIndexNumber(selectedVoice.voice_number);
    }
  };
  const theme = useTheme();

  return (
    <>
      <Meta title={t("voice.title")} meta={[{ name: "robots", content: "noindex, nofollow" }]} />
      <Container maxWidth={"lg"}>
        <Grow in={true}>
          <Box sx={{ height: "100%", mb: 2 }}>
            <DrawerHeader />
            <Paper elevation={theme.palette.mode === "dark" ? 1 : 0} sx={{ my: 4 }}>
              <Box
                sx={{
                  background: (theme) => theme.custom.gradient.main,
                  borderRadius: "4px 4px 0 0",
                  p: { xs: 1 },
                }}
              />

              <ColumnCenteredBox
                sx={{
                  position: "relative",
                  p: { xs: 2, md: 4 },
                  width: "100%",
                }}
              >
                <RowCenteredBox sx={{ justifyContent: "center", mb: 2 }}>
                  <Box display={{ xs: "none", md: "block" }} mr={2}>
                    <img alt={"logo"} src="/images/logo/logo.png" width={"40px"} />
                  </Box>
                  <GradientTypography variant="h5" component={"h2"} gutterBottom>
                    {t("voice.title")}
                  </GradientTypography>
                </RowCenteredBox>
                <RowCenteredBox sx={{ mb: 4 }}>
                  <FlashOnIcon sx={{ mr: 1 }} color={"secondary"} />
                  <Typography variant={"body1"} gutterBottom>
                    {t("voice.description")}
                  </Typography>
                </RowCenteredBox>

                <Grid container spacing={4}>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box mb={4}>
                      <ProviderSelector provider={provider} onProviderChange={(p) => setProvider(p)} />
                    </Box>

                    {loading ? (
                      <ColumnCenteredBox sx={{ height: "100%" }}>
                        <CircularProgress size={24} />
                      </ColumnCenteredBox>
                    ) : (
                      <Fade in={true} timeout={500}>
                        <Stack spacing={2}>
                          <VoiceSelector
                            provider={provider}
                            voiceName={voiceName}
                            voiceList={provider === "openai" ? openAIVoiceList : googleVoiceList}
                            languageList={languageList}
                            language={language}
                            onLanguageChange={(value) => setLanguage(value)}
                            openaiInstructions={openaiInstructions}
                            onVoiceNameChange={handleVoiceNameChange}
                            onInstructionsChange={setOpenaiInstructions}
                            onInfoClick={() => setOpen(true)}
                          />

                          <SpeedControl speed={speed} onSpeedChange={setSpeed} />

                          {provider === "google" && <PitchControl pitch={pitch} onPitchChange={setPitch} />}
                        </Stack>
                      </Fade>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <ContentInput content={content} onContentChange={setContent} />
                  </Grid>
                  <Grid item xs={12} mt={2}>
                    <GenerateButton
                      processing={processing}
                      disabled={content.trim().length === 0}
                      onGenerate={generateVoice}
                      onLibraryClick={() => navigate("/library/voices")}
                    />
                  </Grid>
                </Grid>
              </ColumnCenteredBox>
            </Paper>
            <DescriptionModal open={open} setOpen={setOpen} provider={provider} />
          </Box>
        </Grow>
      </Container>
    </>
  );
}
