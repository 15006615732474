import Crop169Icon from "@mui/icons-material/Crop169";
import Crop32Icon from "@mui/icons-material/Crop32";
import CropDinIcon from "@mui/icons-material/CropDin";
import { TextField, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Slider from "@mui/material/Slider";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AspectRatioIconType, useVideo } from "../../../../context/juno/VideoContext";
import { ColumnBox } from "../../../../utils/styledBox";
import AspectRatioIcons from "./AspectRatioIcons";
import ImageUpload from "./ImageUpload";

const ParamsPixverse = () => {
  const { t } = useTranslation();
  const {
    menu,
    updateParams,
    image1,
    setImage1,
    duration,
    modelVersion,
    negativePrompt,
    setNegativePrompt,
    styles,
    resolutions,
  } = useVideo();

  const aspectRatio: AspectRatioIconType[] = [
    { ratio: "1:1", icon: CropDinIcon },
    { ratio: "16:9", icon: Crop169Icon },
    { ratio: "4:3", icon: Crop32Icon },
    { ratio: "9:16", icon: Crop169Icon, rotate: "90deg" },
    { ratio: "3:4", icon: Crop32Icon, rotate: "90deg" },
  ];

  const durationMarks = [
    {
      value: 5,
      label: "5s",
    },
    {
      value: 8,
      label: "8s",
    },
  ];

  useEffect(() => {
    if (modelVersion.PIXVERSE === "V3_5_FAST" && resolutions.PIXVERSE === "1080p") {
      updateParams("PIXVERSE", "resolution", "720p");
    }
  }, [modelVersion.PIXVERSE]);

  return (
    <ColumnBox sx={{ gap: 4 }}>
      {menu === "i2v" && (
        <ColumnBox sx={{ justifyContent: "center" }}>
          <Typography variant="body2" mb={1}>
            {t("juno.video.params.upload")}
          </Typography>
          <ImageUpload image={image1} setImage={setImage1} />
        </ColumnBox>
      )}
      <ColumnBox>
        <Typography variant="body2" mb={1}>
          {t("juno.video.ai.model")}
        </Typography>
        <Select
          value={modelVersion.PIXVERSE}
          onChange={(e) => updateParams("PIXVERSE", "modelVersion", e.target.value)}
          variant="standard"
          fullWidth
        >
          <MenuItem value={"V3_5"}>{t("juno.video.ai.pixverse.model.V3_5")}</MenuItem>
          <MenuItem value={"V3_5_FAST"}>{t("juno.video.ai.pixverse.model.V3_5_FAST")}</MenuItem>
        </Select>
      </ColumnBox>
      <ColumnBox>
        <Typography variant="body2" mb={1}>
          {t("juno.video.params.duration")}
        </Typography>
        <Slider
          aria-label="duration"
          marks={durationMarks}
          min={5}
          max={8}
          step={3}
          value={duration.PIXVERSE}
          onChange={(_, value) => updateParams("PIXVERSE", "duration", value)}
          disabled
        />
      </ColumnBox>
      <ColumnBox>
        <Typography variant="body2" mb={1}>
          {t("juno.video.params.aspect")}
        </Typography>
        <AspectRatioIcons aspectRatioList={aspectRatio} />
      </ColumnBox>
      <ColumnBox>
        <Typography variant="body2" mb={1}>
          {t("juno.video.params.style")}
        </Typography>
        <Select
          value={styles.PIXVERSE}
          onChange={(e) => updateParams("PIXVERSE", "style", e.target.value)}
          variant="standard"
          fullWidth
        >
          <MenuItem value="default">{t("juno.video.ai.pixverse.style.default")}</MenuItem>
          <MenuItem value="anime">{t("juno.video.ai.pixverse.style.anime")}</MenuItem>
          <MenuItem value="3d_animation">{t("juno.video.ai.pixverse.style.3d_animation")}</MenuItem>
          <MenuItem value="clay">{t("juno.video.ai.pixverse.style.clay")}</MenuItem>
          <MenuItem value="comic">{t("juno.video.ai.pixverse.style.comic")}</MenuItem>
          <MenuItem value="cyberpunk">{t("juno.video.ai.pixverse.style.cyberpunk")}</MenuItem>
        </Select>
      </ColumnBox>
      <ColumnBox>
        <Typography variant="body2" mb={1}>
          {t("juno.video.params.resolution")}
        </Typography>
        <Select
          value={resolutions.PIXVERSE}
          onChange={(e) => updateParams("PIXVERSE", "resolution", e.target.value)}
          variant="standard"
          fullWidth
          disabled={modelVersion.PIXVERSE === "V3_5_FAST"}
        >
          <MenuItem value="720p">720p</MenuItem>
          <MenuItem value="1080p" disabled={modelVersion.PIXVERSE === "V3_5_FAST"}>
            1080p
          </MenuItem>
        </Select>
      </ColumnBox>
      <ColumnBox>
        <Typography variant="body2" mb={1}>
          {t("juno.video.params.negativePrompt")}
        </Typography>
        <TextField
          value={negativePrompt}
          onChange={(e) => setNegativePrompt(e.target.value)}
          variant="standard"
          fullWidth
          multiline
          rows={4}
          inputProps={{ maxLength: 500 }}
        />
      </ColumnBox>
    </ColumnBox>
  );
};

export default ParamsPixverse;
