const junoPreset = {
  icon: "Inspiration",
  description: "Click the chip to add it to the prompt.",
  mood: {
    // Cinematic
    epicCinematic: "Epic Cinematic Scene",
    dramaticScene: "Dramatic Scene",
    actionScene: "Action Scene",
    romanticScene: "Romantic Scene",
    epicBattle: "Epic Battle Scene",
    chaseScene: "Chase Scene",
    slowMotion: "Slow Motion",
    aerialShot: "Aerial Shot",
    // Horror
    horror: "Horror",
    supernatural: "Supernatural",
    psychological: "Psychological Horror",
    haunted: "Haunted",
    creepy: "Creepy",
    nightmarish: "Nightmarish",
    occult: "Occult",
    paranormal: "Paranormal",
    // Sci-Fi
    sciFi: "Science Fiction",
    cyberpunkMood: "Cyberpunk",
    postApocalypticMood: "Post-Apocalyptic",
    spaceOpera: "Space Opera",
    timeTravel: "Time Travel",
    alienWorld: "Alien World",
    roboticFuture: "Robotic Future",
    dystopian: "Dystopian",
    // Documentary
    documentary: "Documentary",
    natureDoc: "Nature Documentary",
    wildlifeDoc: "Wildlife Documentary",
    urbanDoc: "Urban Documentary",
    scientificDoc: "Scientific Documentary",
    historicalDoc: "Historical Documentary",
    culturalDoc: "Cultural Documentary",
    journalistic: "Journalistic",
  },
  character: {
    // Main Characters
    protagonist: "Protagonist",
    antagonist: "Antagonist",
    mentor: "Mentor",
    sidekick: "Sidekick",
    loveInterest: "Love Interest",
    rival: "Rival",
    // Archetypes
    hero: "Hero",
    antihero: "Anti-hero",
    villain: "Villain",
    trickster: "Trickster",
    sage: "Sage",
    explorer: "Explorer",
    // People
    youngMan: "Young Man",
    youngWoman: "Young Woman",
    elderlyMan: "Elderly Man",
    elderlyWoman: "Elderly Woman",
    child: "Child",
    teenager: "Teenager",
    // Professions
    detective: "Detective",
    soldier: "Soldier",
    scientist: "Scientist",
    artist: "Artist",
    doctor: "Doctor",
    teacher: "Teacher",
    chef: "Chef",
    astronaut: "Astronaut",
    pilot: "Pilot",
    archaeologist: "Archaeologist",
    // Action Characters
    martialArtist: "Martial Artist",
    assassin: "Assassin",
    samurai: "Samurai",
    ninja: "Ninja",
    knight: "Knight",
    gunslinger: "Gunslinger",
    // Fantasy
    wizard: "Wizard",
    warrior: "Warrior",
    ranger: "Ranger",
    paladin: "Paladin",
    druid: "Druid",
    necromancer: "Necromancer",
    // Mythology & Legend
    angel: "Angel",
    demon: "Demon",
    god: "God",
    goddess: "Goddess",
    dragon: "Dragon",
    phoenix: "Phoenix",
    // Sci-Fi
    cyborg: "Cyborg",
    android: "Android",
    alien: "Alien",
    robot: "Robot",
    mutant: "Mutant",
    clone: "Clone",
    // Creatures
    vampire: "Vampire",
    werewolf: "Werewolf",
    ghost: "Ghost",
    zombie: "Zombie",
    demonBeast: "Demon Beast",
    monster: "Monster",
    // Animals
    lion: "Lion",
    wolf: "Wolf",
    eagle: "Eagle",
    horse: "Horse",
    cat: "Cat",
    dog: "Dog",
  },
  illumination: {
    sunlight: "Sunlight",
    naturalLight: "Natural Light",
    studioLighting: "Studio Lighting",
    softLight: "Soft Light",
    brightLight: "Bright Light",
    dimLight: "Dim Light",
    warmLight: "Warm Light",
    coolLight: "Cool Light",
    goldenHour: "Golden Hour",
    blueMagicHour: "Blue Magic Hour",
    sunsetGlow: "Sunset Glow",
    moonlight: "Moonlight",
    starlitSky: "Starlit Sky",
    candlelight: "Candlelight",
    fireLight: "Fire Light",
    neonGlow: "Neon Glow",
    cityNightGlow: "City Night Glow",
    rainReflection: "Rain Reflection",
    underwaterGlow: "Underwater Glow",
    auroraLight: "Aurora Light",
    holographicLight: "Holographic Light",
    projectionMapping: "Projection Mapping",
    ledMatrix: "LED Matrix",
    laserBeams: "Laser Beams",
    bioluminescence: "Bioluminescence",
    volumetricLight: "Volumetric Light",
    rimLight: "Rim Light",
    crystalRefraction: "Crystal Refraction",
    caustics: "Caustics",
    godRays: "God Rays",
  },
  background: {
    cityStreet: "City Street",
    cafe: "Cafe",
    park: "Park",
    beach: "Beach",
    forest: "Forest",
    mountain: "Mountain",
    lake: "Lake",
    river: "River",
    garden: "Garden",
    office: "Office",
    livingRoom: "Living Room",
    kitchen: "Kitchen",
    bedroom: "Bedroom",
    studio: "Studio",
    classroom: "Classroom",
    library: "Library",
    museum: "Museum",
    artGallery: "Art Gallery",
    restaurant: "Restaurant",
    bar: "Bar",
    nightclub: "Nightclub",
    concert: "Concert Hall",
    theater: "Theater",
    stadium: "Stadium",
    gym: "Gym",
    spa: "Spa",
    hospital: "Hospital",
    laboratory: "Laboratory",
    factory: "Factory",
    warehouse: "Warehouse",
    market: "Market",
    shoppingMall: "Shopping Mall",
    airport: "Airport",
    trainStation: "Train Station",
    harbor: "Harbor",
    bridge: "Bridge",
    tunnel: "Tunnel",
    rooftop: "Rooftop",
    basement: "Basement",
    attic: "Attic",
    greenhouse: "Greenhouse",
    vineyard: "Vineyard",
    farm: "Farm",
    ranch: "Ranch",
    desert: "Desert",
    oasis: "Oasis",
    jungle: "Jungle",
    rainforest: "Rainforest",
    savanna: "Savanna",
    tundra: "Tundra",
    glacier: "Glacier",
    volcano: "Volcano",
    canyon: "Canyon",
    cave: "Cave",
    underwaterReef: "Underwater Reef",
    tropicalIsland: "Tropical Island",
    metaverse: "Metaverse Space",
    augmentedCity: "Augmented Reality City",
    virtualWorld: "Virtual World",
    spaceStation: "Space Station",
    moonBase: "Moon Base",
    marsColony: "Mars Colony",
    alienPlanet: "Alien Planet",
    crystalCave: "Crystal Cave",
    enchantedForest: "Enchanted Forest",
    floatingIslands: "Floating Islands",
    cloudCity: "Cloud City",
    underwaterCity: "Underwater City",
    ancientRuins: "Ancient Ruins",
    medievalCastle: "Medieval Castle",
    futuristicCity: "Futuristic City",
    steampunkTown: "Steampunk Town",
    cyberpunkDistrict: "Cyberpunk District",
    postApocalyptic: "Post-Apocalyptic World",
    fantasyRealm: "Fantasy Realm",
    parallelUniverse: "Parallel Universe",
  },
  style: {
    photorealistic: "Photorealistic",
    digitalPainting: "Digital Painting",
    watercolor: "Watercolor Style",
    oilPainting: "Oil Painting Style",
    pencilSketch: "Pencil Sketch Style",
    animeStyle: "Anime Style",
    cartoonStyle: "Cartoon Style",
    pixelArt: "Pixel Art Style",
    minimalist: "Minimalist Style",
    popArt: "Pop Art Style",
    abstractArt: "Abstract Art Style",
    impressionist: "Impressionist Style",
    ukiyoe: "Ukiyo-e Style",
    cyberpunk: "Cyberpunk Style",
    voxelArt: "Voxel Art Style",
    lowPoly: "Low Poly Style",
    neuralArt: "Neural Art Style",
    generativeArt: "Generative Art Style",
    glitchArt: "Glitch Art Style",
    fractals: "Fractal Art Style",
    aiGenerated: "AI Generated Art Style",
    holographic: "Holographic Style",
    nftArt: "NFT Art Style",
    parametricDesign: "Parametric Design",
    biomimicry: "Biomimicry Style",
  },
};

export default junoPreset;
