import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useVideoScriptContext } from "../../../../context/VideoScriptContext";
import { StyledTypography } from "../../../../utils/styledTypography";

export default function AdditionalText() {
  const { t } = useTranslation();
  const context = useVideoScriptContext();

  return (
    <Box>
      {/* 追加情報 */}
      <Box mb={2}>
        <StyledTypography variant="body1" gutterBottom>
          {t("videoScript.additional")}
        </StyledTypography>
      </Box>
      <Box>
        <TextField
          id="outlined-multiline-static"
          // label={t('videoScript.additional')}
          placeholder={t("videoScript.additionalPlaceholder")}
          variant="standard"
          maxRows={4}
          value={context.additionalText}
          onChange={(e) => context.setAdditionalText(e.target.value)}
          fullWidth
          multiline
          inputProps={{ maxLength: 1000 }}
        />
        <Box display={"flex"} justifyContent={"flex-end"}>
          <Typography variant={"body2"}>{context.additionalText.length}/1000</Typography>
        </Box>
      </Box>
    </Box>
  );
}
