import axios from "axios";
import dagre from "dagre";
import Cookies from "js-cookie";
import React, { createContext, useCallback, useContext, useMemo, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  Edge,
  Node,
  OnEdgesChange,
  OnNodesChange,
  Position,
  ReactFlowInstance,
  useEdgesState,
  useNodesState,
} from "reactflow";
import CustomNode from "../pages/MindMapGenerator/components/CustomNode";
import { useAlert } from "./AlertContext";

type NodeAction = "delete" | "add" | "";

type ActionTriggerState = {
  id: string;
  node_action: NodeAction;
  triggerFetch: boolean;
};

type MindMapContextType = {
  actionTrigger: ActionTriggerState;
  setActionTrigger: (id: string, node_action: NodeAction) => void;
  selectedNode: string | null;
  setSelectedNode: (id: string) => void;
  reactFlowInstance: ReactFlowInstance | null;
  setReactFlowInstance: (instance: ReactFlowInstance) => void;
  nodes: Node[];
  setNodes: React.Dispatch<React.SetStateAction<Node[]>>;
  onNodesChange: OnNodesChange;
  edges: Edge[];
  setEdges: React.Dispatch<React.SetStateAction<Edge[]>>;
  onEdgesChange: OnEdgesChange;
  autoLayout: () => Node[];
  handleSave: () => void;
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  status: number | undefined;
  setStatus: React.Dispatch<React.SetStateAction<number | undefined>>;
  getMindMap: () => Promise<
    | {
        title: string;
        nodes: Node[];
        edges: Edge[];
        status: number;
      }
    | undefined
  >;
  animation: boolean;
  setAnimation: React.Dispatch<React.SetStateAction<boolean>>;
  edgeType: string;
  setEdgeType: React.Dispatch<React.SetStateAction<string>>;
  nodeDefaults: { sourcePosition: Position; targetPosition: Position };
  isDownloadFlowVisible: boolean;
  setIsDownloadFlowVisible: React.Dispatch<React.SetStateAction<boolean>>;
  nodeTypes: { custom: typeof CustomNode };
  saving: boolean;
  setSaving: React.Dispatch<React.SetStateAction<boolean>>;
};

const MindMapContext = createContext<MindMapContextType | undefined>(undefined);

export const useMindMap = () => {
  const context = useContext(MindMapContext);
  if (!context) {
    throw new Error("useMindMap must be used within a MindMapProvider");
  }
  return context;
};

export const MindMapProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { mindMapUuid } = useParams<{ mindMapUuid: string }>() as { mindMapUuid: string };
  const [actionTrigger, setActionTriggerState] = useState<ActionTriggerState>({
    id: "",
    node_action: "",
    triggerFetch: false,
  });
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const [selectedNode, setSelectedNodeState] = useState<string | null>(null);
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [title, setTitle] = useState("");
  const [animation, setAnimation] = useState(false);
  const [edgeType, setEdgeType] = useState("default");
  const [isDownloadFlowVisible, setIsDownloadFlowVisible] = useState(false);
  const [saving, setSaving] = useState(false);

  const setSelectedNode = useCallback((id: string) => {
    setSelectedNodeState(id);
  }, []);

  const autoLayout = useCallback(() => {
    unstable_batchedUpdates(() => {
      // 選択状態をリセット
      setSelectedNodeState("");

      // nodesの全てのselectedをfalseにする
      setNodes((prevNodes) =>
        prevNodes.map((node) => ({
          ...node,
          selected: false,
        }))
      );
    });

    const g = new dagre.graphlib.Graph();
    g.setGraph({ rankdir: "LR", ranksep: 100, nodesep: 20 });
    g.setDefaultEdgeLabel(() => ({}));

    nodes.forEach((node) => {
      const size = node.data?.size;
      if (!size) return;
      const { width, height } = size;
      g.setNode(node.id, { width, height });
    });

    edges.forEach((edge) => {
      g.setEdge(edge.source, edge.target);
    });

    try {
      dagre.layout(g);

      const updatedNodes = nodes.map((node) => {
        const nodeInfo = g.node(node.id);
        return {
          ...node,
          position: {
            x: nodeInfo.x - nodeInfo.width / 2,
            y: nodeInfo.y - nodeInfo.height / 2,
          },
          selected: false,
        };
      });

      setNodes(updatedNodes);
      setTimeout(() => {
        reactFlowInstance?.fitView();
        setAlert("success", t("mindmap.alert.autoLayout"));
      }, 200);
      return updatedNodes;
    } catch (error) {
      reactFlowInstance?.fitView();
      console.error("Unknown error occurred:", error);
      setAlert("error", t("mindmap.alert.autoLayoutError"));
      return nodes;
    }
  }, [nodes, edges, setNodes, setSelectedNodeState, reactFlowInstance, setAlert, t]);

  // triggerFetchを反転させるsetActionTrigger関数
  const setActionTrigger = (id: string, node_action: NodeAction) => {
    setActionTriggerState((prevState) => ({
      ...prevState,
      id,
      node_action,
      triggerFetch: !prevState.triggerFetch,
    }));
  };

  const [status, setStatus] = useState<number>();

  // MindMapデータを取得する関数
  const getMindMap = useCallback(async () => {
    try {
      const res = await axios.get(`/api/v1/mind-map/${mindMapUuid}`);
      const { data } = res;

      if (data) {
        setStatus(data.status);
        return data;
      }
    } catch (error) {
      console.error("Unknown error occurred:", error);
    }
  }, [mindMapUuid]);

  // MindMapを保存する関数
  const handleSave = useCallback(async () => {
    try {
      setSaving(true);
      const url = `/api/v1/mind-map/${mindMapUuid}`;
      const csrftoken = Cookies.get("csrftoken");
      const headers = { "X-CSRFToken": csrftoken! };
      // nodesのselectedをfalseにする
      const nodesCopy = nodes.map((node) => ({ ...node, selected: false }));
      const data = { title, nodes: nodesCopy, edges };
      const res = await axios.patch(url, data, { headers });

      if (res.data) {
        setAlert("success", t("file.saveFile"));
        setSaving(false);
      }
    } catch (error) {
      setAlert("error", "Unknown error occurred");
      setSaving(false);
      console.error("Unknown error occurred:", error);
    }
  }, [mindMapUuid, title, nodes, edges]);

  const nodeDefaults = useMemo(
    () => ({
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    }),
    []
  );
  const nodeTypes = useMemo(() => ({ custom: CustomNode }), []);

  return (
    <MindMapContext.Provider
      value={{
        nodeTypes,
        actionTrigger,
        setActionTrigger,
        selectedNode,
        setSelectedNode,
        reactFlowInstance,
        setReactFlowInstance,
        nodes,
        setNodes,
        onNodesChange,
        edges,
        setEdges,
        onEdgesChange,
        autoLayout,
        handleSave,
        title,
        setTitle,
        status,
        setStatus,
        getMindMap,
        animation,
        setAnimation,
        edgeType,
        setEdgeType,
        nodeDefaults,
        isDownloadFlowVisible,
        setIsDownloadFlowVisible,
        saving,
        setSaving,
      }}
    >
      {children}
    </MindMapContext.Provider>
  );
};
